<template>
    <div class="toastWrapper" id="toastWrapper">
      <div class="toast" v-if="show">
        <div class="sideBar"></div>
        <div class="content">
          <img
            src="../../assets/success_icon.svg"
            v-if="toastType === toastTypes.SUCCESS"
          />
          <img
            src="../../assets/error_icon.svg"
            v-if="toastType === toastTypes.ERROR"
          />
          <div class="message">{{ toastMessage }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ToastTypes from "../../models/Types/ToastTypes.js";
  export default {
    props: ["show", "toastType", "toastMessage"],
  
    data() {
      return {
        toastTypes: ToastTypes,
      };
    },
  
    watch: {
      show(newValue) {
        if (newValue === true) {
          document
            .getElementById("toastWrapper")
            .classList.add("slide-in-bottom");
          setTimeout(() => {
            this.$store.commit("resetToast");
            document
              .getElementById("toastWrapper")
              .classList.remove("slide-in-bottom");
          }, 5000);
        }
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
@import "scss/colors.scss";
  
  .toastWrapper {
    position: fixed;
    top: 5%;
    right: 10%;
    min-width: 25%;
    max-width: 400px;
    z-index: 1000000000;
   /*  @media (max-width: $mobile) {
      right: 2%;
      left: 2%;
      top: 2%;
    } */
    .toast {
      width: 100%;
      position: relative;
      box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
      display: flex;
      align-items: center;
      padding: 1em 0;
      border-radius: 15px;
      background-color: white;
  
      .sideBar {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2%;
        background-color: #404040;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      .content {
        display: flex;
        align-items: center;
        width: 97%;
        margin-left: 3%;
      }
      img {
        width: 8%;
        margin-left: 1em;
      }
      .message {
        width: auto;
        display: flex;
        justify-content: flex-start;
        margin-left: 2em;
        margin-right: 2em;
        text-align: start;
      }
    }
  }
  
  .slide-in-bottom {
    -webkit-animation: slide-in-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(200px);
      transform: translateY(200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(200px);
      transform: translateY(200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  </style>
  