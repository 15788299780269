<template>
  <div class="generalSettingsWrapper">
    <div class="generalSettings">
      <div class="body">
        <div style="
            margin-top: 20px;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            gap: 150px;
          ">

          <span
            style="margin-right: 20px; font-size: 1.25rem; font-weight: 500">
            Hintergrund
          </span>
          <QBtnDropdown size="11px" dropdown-icon="expand_more" :icon="generalSettings.selectedCategoryBackgroundDropdownElement?.icon
            ? generalSettings.selectedCategoryBackgroundDropdownElement.icon
            : 'palette'
            " :label="generalSettings.selectedCategoryBackgroundDropdownElement?.label
            ? generalSettings.selectedCategoryBackgroundDropdownElement
              .label
            : 'Farbe'
            " :auto-close="true"
            style="border-radius: 10px; margin-right: 5px">
            <QList>
              <QItem clickable v-close-popup @click="
            selectCategoryBackgroundDropdownOption('palette', 'Farbe');
          generalSettings.showOverlayColorProperty = false;
          ">
                <QItemSection>
                  <QItemLabel>
                    <QIcon name="palette"></QIcon>
                    Farbe
                  </QItemLabel>
                </QItemSection>
              </QItem>
              <QItem clickable v-close-popup @click="
            selectCategoryBackgroundDropdownOption('image', 'Bild');
          generalSettings.showOverlayColorProperty = true;
          buildBackgroundOverlay();
          ">
                <QItemSection>
                  <QItemLabel>
                    <QIcon name="image"></QIcon>
                    Bild
                  </QItemLabel>
                </QItemSection>
              </QItem>
              <QItem clickable v-close-popup @click="
            selectCategoryBackgroundDropdownOption(
              'gradient',
              'Gradient'
            );
          generalSettings.showOverlayColorProperty = false;
          ">
                <QItemSection>
                  <QItemLabel>
                    <QIcon name="gradient"></QIcon>
                    Gradient
                  </QItemLabel>
                </QItemSection>
              </QItem>
            </QList>
          </QBtnDropdown>
        </div>
        <div style="display: flex" v-if="generalSettings.selectedCategoryBackgroundDropdownElement?.icon ==
            'palette'
            ">
          <label>Farbe: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.backgroundColor }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale">
              <QColor v-model="generalSettings.backgroundColor" />
            </QPopupProxy>
          </div>
        </div>
        <div style="display: flex; flex-direction: column" v-if="generalSettings.selectedCategoryBackgroundDropdownElement?.icon ==
            'image'
            ">
          <div style="display: flex; align-items: center">
            <label>Bild: </label>
            <div class="imageUploadPlaceholder">
              <label v-if="!generalSettings.currentSelectedImage && !isImageUploadLoading
            " style="font-size: x-small">Kein Bild ausgewählt</label>
              <QSpinner v-else-if="isImageUploadLoading" color="light-green"
                size="1rem">
              </QSpinner>
              <img v-else :src="generalSettings.currentSelectedImage" />
            </div>
            <div v-if="generalSettings.currentSelectedImage"
              class="optionIconDelete" @click="deleteImage()">
              <QIcon name="delete"></QIcon>
            </div>

            <div class="optionIconSearch">
              <QIcon name="search">
                <QPopupProxy cover transition-show="scale"
                  transition-hide="scale" style="border-radius: 20px">
                  <MenuBackgroundPixabaySearch
                    @imageSelected="(img) => setPixabayImage(img)"
                    :isImageUploadLoading="isImageUploadLoading">
                  </MenuBackgroundPixabaySearch>
                </QPopupProxy>
              </QIcon>
            </div>

            <div class="optionIconUpload" @click="triggerBackgroundFileInput">
              <q-icon name="upload"></q-icon>
              <input class="fileInput" type="file" :multiple="false"
                ref="backgroundFileInput" id="imageCategoryUpload"
                accept="image/*"
                style="opacity: 0; height: 24px; width: 29px; position: absolute; cursor: pointer;"
                @change="handleImageUpload" />
            </div>
          </div>
          <div>
            <div style="display: flex; margin-top: 20px" v-if="generalSettings.showOverlayColorProperty &&
            generalSettings.currentSelectedImage
            ">
              <label>Deckfarbe: </label>
              <div class="currentColor"
                :style="{ background: generalSettings.overlayColor }">
                <QPopupProxy cover transition-show="scale"
                  transition-hide="scale" style="margin-left: 10px">
                  <QColor v-model="generalSettings.overlayColor"
                    @update:model-value="buildBackgroundOverlay()" />
                </QPopupProxy>
              </div>
              <label style="margin-left: 10px">Stärke: </label>

              <input style="margin-left: 10px" type="number" min="0" max="100"
                v-model="generalSettings.overlayOpacity"
                @input="buildBackgroundOverlay()" />
            </div>
          </div>
        </div>
        <div style="display: flex" v-if="generalSettings.selectedCategoryBackgroundDropdownElement?.icon ==
            'gradient'
            ">
          <label>Farbe 1: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.gradientColor1 }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale">
              <QColor v-model="generalSettings.gradientColor1"
                @update:model-value="setGradientBackground()" />
            </QPopupProxy>
          </div>
          <label style="margin-left: 20px">Farbe 2: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.gradientColor2 }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale">
              <QColor v-model="generalSettings.gradientColor2"
                @update:model-value="setGradientBackground()" />
            </QPopupProxy>
          </div>
          <label style="margin-left: 20px">Winkel: </label>
          <input type="number" min="0" max="500"
            v-model="generalSettings.gradientAngle"
            @input="setGradientBackground()" />
        </div>
        <div>
          <h6 style="margin-top: 30px">Farben</h6>
        </div>
        <div style="display: flex">
          <label>Primärfarbe: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.primaryColor }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor v-model="generalSettings.primaryColor"
                @update:model-value="setPrimaryColor()" />
            </QPopupProxy>
          </div>
          <label style="margin-left: 20px">Sekundärfarbe: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.secondaryColor }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor v-model="generalSettings.secondaryColor"
                @update:model-value="setSecondaryColor()" />
            </QPopupProxy>
          </div>
        </div>
        <div style="
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            gap: 10px;
          ">
          <span
            style="margin-right: 20px; font-size: 1.25rem; font-weight: 500">
            Schriftart
          </span>
          <QBtnDropdown dropdown-icon="expand_more"
            :label="generalSettings.currentFont" :auto-close="true" size="10px"
            style="width: 100px; margin-left: 5px; border-radius: 10px">
            <QList>
              <QItem v-for="option in generalSettings.fontFamilyOptions"
                :key="option" clickable v-close-popup
                @click="setDefaultFont(option)">
                <QItemSection>
                  <QItemLabel :style="'font-family: ' + option">
                    {{ option }}
                  </QItemLabel>
                </QItemSection>
              </QItem>
            </QList>
          </QBtnDropdown>
        </div>
        <div>
          <h6 style="margin-top: 30px">Warenkorb-Button</h6>
        </div>
        <div style="display: flex">
          <label>Iconfarbe: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.flyingCartButtonIconColor }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor v-model="generalSettings.flyingCartButtonIconColor" />
            </QPopupProxy>
          </div>
          <label style="margin-left: 20px">Hintergrundfarbe: </label>
          <div class="currentColor" :style="{
            background: generalSettings.flyingCartButtonBackgroundColor,
          }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor
                v-model="generalSettings.flyingCartButtonBackgroundColor" />
            </QPopupProxy>
          </div>
          <div>
            <label style="margin-left: 20px"> Icon: </label>
            <QBtnDropdown size="11px" dropdown-icon="expand_more"
              :icon="generalSettings.flyingCartButtonIcon" :auto-close="true"
              style="
                border-radius: 10px;
                margin-right: 5px;
                margin-top: -5px;
                margin-left: 10px;
              ">
              <QList>
                <QItem clickable v-close-popup @click="
            generalSettings.flyingCartButtonIcon = 'shopping_cart'
            ">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="shopping_cart"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup
                  @click="generalSettings.flyingCartButtonIcon = 'shopping_bag'">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="shopping_bag"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup @click="
            generalSettings.flyingCartButtonIcon = 'shopping_basket'
            ">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="shopping_basket"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup
                  @click="generalSettings.flyingCartButtonIcon = 'local_mall'">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="local_mall"></QIcon>
                  </QItemSection>
                </QItem>
              </QList>
            </QBtnDropdown>
          </div>

        </div>
        <div>
          <h6 style="margin-top: 30px">Zum Warenkorb Hinzufügen-Button</h6>
        </div>
        <div style="display: flex">
          <label>Iconfarbe: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.addToCartButtonIconColor }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor v-model="generalSettings.addToCartButtonIconColor" />
            </QPopupProxy>
          </div>

          <div>
            <label style="margin-left: 20px"> Icon: </label>
            <QBtnDropdown size="11px" dropdown-icon="expand_more"
              :icon="generalSettings.addToCartButtonIcon" :auto-close="true"
              style="
                border-radius: 10px;
                margin-right: 5px;
                margin-top: -5px;
                margin-left: 10px;
              ">
              <QList>
                <QItem clickable v-close-popup
                  @click="generalSettings.addToCartButtonIcon = 'add'">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="add"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup @click="
            generalSettings.addToCartButtonIcon = 'add_shopping_cart'
            ">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="add_shopping_cart"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup
                  @click="generalSettings.addToCartButtonIcon = 'add_box'">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="add_box"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup
                  @click="generalSettings.addToCartButtonIcon = 'add_circle'">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="add_circle"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup
                  @click="generalSettings.addToCartButtonIcon = 'shopping_cart'">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="shopping_cart"></QIcon>
                  </QItemSection>
                </QItem>
              </QList>
            </QBtnDropdown>
          </div>
        </div>
        <div>
          <h6 style="margin-top: 30px">Kellnerruf-Button</h6>
        </div>
        <div style="display: flex">
          <label>Iconfarbe: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.callEmployeeButtonIconColor }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor v-model="generalSettings.callEmployeeButtonIconColor" />
            </QPopupProxy>
          </div>
          <label style="margin-left: 20px">Hintergrundfarbe: </label>
          <div class="currentColor" :style="{
            background: generalSettings.callEmployeeButtonBackgroundColor,
          }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor
                v-model="generalSettings.callEmployeeButtonBackgroundColor" />
            </QPopupProxy>
          </div>
          <div>
            <label style="margin-left: 20px"> Icon: </label>
            <QBtnDropdown size="11px" dropdown-icon="expand_more"
              :icon="generalSettings.callEmployeeButtonIcon" :auto-close="true"
              style="
                border-radius: 10px;
                margin-right: 5px;
                margin-top: -5px;
                margin-left: 10px;
              ">
              <QList>
                <QItem clickable v-close-popup @click="
            generalSettings.callEmployeeButtonIcon = 'person_apron'
            ">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="person_apron"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup
                  @click="generalSettings.callEmployeeButtonIcon = 'account_circle'">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="account_circle"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup @click="
            generalSettings.callEmployeeButtonIcon = 'face'
            ">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="face"></QIcon>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup
                  @click="generalSettings.callEmployeeButtonIcon = 'person_search'">
                  <QItemSection
                    style="display: flex; align-items: center; font-size: 20px">
                    <QIcon name="person_search"></QIcon>
                  </QItemSection>
                </QItem>
              </QList>
            </QBtnDropdown>
          </div>

        </div>
        <div>
          <h6 style="margin-top: 30px">Zurück-Button</h6>
        </div>
        <div style="display: flex">
          <label>Textfarbe: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.backButtonTextColor }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor v-model="generalSettings.backButtonTextColor" />
            </QPopupProxy>
          </div>
          <label style="margin-left: 20px">Hintergrundfarbe: </label>
          <div class="currentColor"
            :style="{ background: generalSettings.backButtonBackgroundColor }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor v-model="generalSettings.backButtonBackgroundColor" />
            </QPopupProxy>
          </div>
          <label style="margin-left: 20px">Rundung: </label>
          <input type="number" min="0" max="100"
            v-model="generalSettings.backButtonBorderRadius" />
        </div>
        <div>
          <h6 style="margin-top: 30px">QR Code</h6>
        </div>

        <div>
          <div ref="qrCode" style="width: fit-content;"></div>
          <div
            style="display: flex; align-items: center; gap: 3rem; margin-top: 1rem;">
            <div style="display: flex;">
              <label>Farbe: </label>
              <div class="currentColor"
                :style="{ background: generalSettings.qrCodeOptions.color }">
                <QPopupProxy cover transition-show="scale"
                  transition-hide="scale" style="margin-left: 10px">
                  <QColor v-model="generalSettings.qrCodeOptions.color" />
                </QPopupProxy>
              </div>
            </div>
            <QBtnDropdown dropdown-icon="expand_more"
              :label="generalSettings.qrCodeOptions.dotType.label"
              :auto-close="true" size="10px"
              style="width: fit-content; margin-left: 5px; border-radius: 10px">
              <QList v-for="option in dotTypes" :key="option">
                <QItem clickable v-close-popup @click="setDotType(option)">
                  <QItemSection>
                    <QItemLabel>
                      {{ option.label }}
                    </QItemLabel>
                  </QItemSection>
                </QItem>
              </QList>
            </QBtnDropdown>
          </div>
          <div style="display: flex; flex-direction: column; margin-top: 1rem">
            <div style="display: flex; align-items: center">
              <label>Bild: </label>
              <div class="qrIconUploadPlaceholder">
                <label v-if="!generalSettings.qrCodeOptions.logo && !isQRImageUploadLoading
            " style="font-size: x-small">Kein Bild ausgewählt</label>
                <QSpinner v-else-if="isQRImageUploadLoading" color="light-green"
                  size="1rem">
                </QSpinner>
                <img v-else :src="generalSettings.qrCodeOptions.logo" />
              </div>
              <div v-if="generalSettings.qrCodeOptions.logo"
                class="optionQRIconDelete" @click="deleteQRImage()">
                <QIcon name="delete"></QIcon>
              </div>


              <div class="optionIconUpload" @click="triggerQRFileInput">
                <q-icon name="upload"></q-icon>
                <input class="fileInput" type="file" :multiple="false"
                  ref="qrFileInput" id="imageCategoryUpload" accept="image/*"
                  style="opacity: 0; height: 24px; width: 29px; position: absolute; cursor: pointer;"
                  @change="handleQRImageUpload" />
              </div>
              <label style="margin-left: 20px">Tische: </label>
              <input type="number" min="0" max="100"
                v-model="generalSettings.numberOfTables" />
              <div class="QRButtonWrapper">
                <div class="QRButton" @click="generateQRCodes(menuId)"
                  style="margin-top: 0">
                  <span> QR Codes </span>
                  <QIcon name="qr_code"></QIcon>
                </div>
                <QSpinner color="light-green" v-if="isQRCodesLoading"
                  class="spinner">
                </QSpinner>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
        <div class="legal">
          <h6 style="margin-top: 30px; text-align: start;">Impressum</h6>
          <MyTextEditor v-model="generalSettings.impressum"
            :generalSettings="generalSettings" :editorHeight="500">
          </MyTextEditor>
          <h6 style="margin-top: 30px; text-align: start;">Datenschutz</h6>
          <MyTextEditor v-model="generalSettings.dataPrivacy"
            :generalSettings="generalSettings" :editorHeight="500">
          </MyTextEditor>
          <h6 style="margin-top: 30px; text-align: start;">Inhaltsstoffe</h6>
          <MyTextEditor v-model="generalSettings.ingredients"
            :generalSettings="generalSettings" :editorHeight="500">
          </MyTextEditor>
        </div>
        <div style="margin-top: 20px; text-align: start">
          <h6>Mitarbeiter einladen</h6>
          <div style="display: flex; align-items: center; gap: 10px;">
            <input type="email" v-model="newEmployeeEmail"
              placeholder="Mitarbeiter E-Mail"
              style="padding: 5px; border-radius: 5px; border: 1px solid #ccc; width: 60%; height: max-content; margin-left: 0">
            <QBtnDropdown :label="selectedRole.label" :icon="selectedRole.icon"
              dropdown-icon="expand_more" auto-close style="
                border-radius: 10px;
              ">
              <QList>
                <QItem v-for="role in roles" :key="role.value" clickable
                  v-close-popup @click="selectRole(role)">
                  <QItemSection avatar>
                    <QIcon :name="role.icon" />
                  </QItemSection>
                  <QItemSection>
                    <QItemLabel>{{ role.label }}</QItemLabel>
                  </QItemSection>
                </QItem>
              </QList>
            </QBtnDropdown>
            <button @click="inviteEmployee" class="inviteButton">
              Einladen
              <QIcon name="add" style="font-weight: bold;" />
            </button>
          </div>
        </div>

        <!-- Display Invited Employees -->
        <div style="margin-top: 20px; text-align: start"
          v-if="invited.length > 0">
          <h6>Eingeladene Mitarbeiter</h6>
          <div v-for="(employee, index) in invited" :key="index"
            style="display: flex; align-items: center; gap: 10px; margin-bottom: 10px;">
            <span>{{ employee.email }}</span>
            <QBtnDropdown :label="getRoleLabel(employee.role)"
              :icon="getRoleIcon(employee.role)" dropdown-icon="expand_more"
              auto-close style="
                border-radius: 10px;
              ">
              <QList>
                <QItem v-for="role in roles" :key="role.value" clickable
                  v-close-popup @click="changeRole(employee.email, role.value)">
                  <QItemSection avatar>
                    <QIcon :name="role.icon" />
                  </QItemSection>
                  <QItemSection>
                    <QItemLabel>{{ role.label }}
                    </QItemLabel>
                  </QItemSection>
                </QItem>
              </QList>
            </QBtnDropdown>
            <button @click="removeEmployee(employee.email)"
              class="removeButton">
              Entfernen
              <QIcon name="delete" />
            </button>
          </div>
        </div>
        <div>
          <h6 style="margin-top: 40px">Weitere Optionen</h6>
        </div>
        <div style="display: flex; align-items: center">
          <input type="checkbox" v-model="generalSettings.employeeCallDisabled"
            style="width: fit-content; margin-left: 0; margin-right: 0.5rem;">
          Kellnerruf deaktivieren
        </div>
        <div style="display: flex; align-items: center">
          <input type="checkbox"
            v-model="generalSettings.onlineOrderingDisabled"
            style="width: fit-content; margin-left: 0; margin-right: 0.5rem;">
          Online-Bestellungen deaktivieren
        </div>
        <div style="display: flex; align-items: center">
          <input type="checkbox"
            v-model="generalSettings.pricesToFixedTwoDecimals"
            style="width: fit-content; margin-left: 0; margin-right: 0.5rem;">
          Preise auf zwei Dezimalstellen runden
        </div>

        <div class="deleteWrapper">
          <button class="delete-button" @click="confirmDeletion">SPEISEKARTE
            LÖSCHEN</button>
        </div>
        <q-dialog v-model="isDialogOpen">
          <q-card>
            <q-card-section>
              <div class="text-h6">Speisekarte löschen</div>
              <div>Bitte geben Sie "LÖSCHEN" ein, um die Speisekarte zu löschen
              </div>
              <input type="text" v-model="confirmDeleteText"
                class="confirmDeleteText" />
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="ABBRECHEN" color="light-green" v-close-popup />
              <q-btn flat label="LÖSCHEN" color="negative"
                :disable="confirmDeleteText.trim() !== 'LÖSCHEN'"
                @click="deleteMenu" v-close-popup />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {
  QPopupProxy,
  QColor,
  QBtnDropdown,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QIcon,
  QSpinner,
} from "quasar";
import MenuBackgroundPixabaySearch from "./MenuBackgroundPixabaySearch.vue";
import AssetHandler from "@/util/AssetHandler";
import cloneDeep from "lodash/cloneDeep";
import QRCodeStyling from "qr-code-styling";
import MyTextEditor from "../shared/MyTextEditor.vue";
import MenuHandler from "@/util/MenuHandler";

export default {
  components: {
    QPopupProxy,
    QColor,
    QBtnDropdown,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QIcon,
    QSpinner,
    MenuBackgroundPixabaySearch,
    MyTextEditor,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    generalSettingsParent: {
      type: Object,
      default: null,
    },
    menuId: {
      type: String,
      default: null,
    },
  },
  async mounted() {
    this.qrCode = new QRCodeStyling(this.generalSettings.qrCodeOptions);
    this.qrCode.append(this.$refs.qrCode);
  },
  data() {
    return {
      title: "",
      isImageUploadLoading: false,
      isQRImageUploadLoading: false,
      isDialogOpen: false,
      confirmDeleteText: "",
      isQRCodesLoading: false,
      encryptedZero: null,
      generalSettings: {
        selectedCategoryBackgroundDropdownElement: {
          icon: "palette",
          label: "Farbe",
        },
        backgroundColor: "#FFFFFF",
        currentSelectedImage: "",
        gradientColor1: "#000000",
        gradientColor2: "#FFFFFF",
        gradientAngle: 180,

        gradientBackground:
          "linear-gradient(180deg, rgba(0,0,0,1), rgba(255,255,255,1)",

        showOverlayColorProperty: true,
        overlayOpacity: 20,
        overlayColor: "#000000",
        linearGradientOverlay:
          "linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2)), ",
        primaryColor: "#000000",
        secondaryColor: "#edede9",

        fontFamilyOptions: ["Roboto", "Inter", "Sans-serif", "Arial", "Verdana", "Times New Roman", "Courier New", "Georgia", "Palatino", "Garamond", "Bookman", "Comic Sans MS", "Trebuchet MS", "Arial Black", "Impact"],
        currentFont: "Roboto",

        flyingCartButtonIconColor: "#000000",
        flyingCartButtonBackgroundColor: "#edede9",
        flyingCartButtonIcon: "shopping_cart",
        callEmployeeButtonIconColor: "#000000",
        callEmployeeButtonBackgroundColor: "#edede9",
        callEmployeeButtonIcon: "person_apron",

        addToCartButtonIconColor: "#000000",
        addToCartButtonIcon: "add",

        backButtonTextColor: "#000000",
        backButtonBackgroundColor: "#edede9",
        backButtonHeight: 70,
        backButtonWidth: 160,
        backButtonBorderRadius: 30,
        numberOfTables: 10,

        qrCodeOptions: {
          width: 300,
          height: 300,
          type: "png",
          data: "",
          image: "",
          margin: 0,
          color: "#000000",
          dotType: { label: "Rechteckig", value: "square" },
          logo: ""
        },
        employeeCallDisabled: false,
        onlineOrderingDisabled: false,
        pricesToFixedTwoDecimals: false,
        impressum: "",
        dataPrivacy: "",
        ingredients: "",
      },
      qrCode: null,
      dotTypes: [
        { label: "Rund", value: "rounded" },
        { label: "Punkte", value: "dots" },
        { label: "Classy", value: "classy" },
        { label: "Classy Abgerundet", value: "classy-rounded" },
        { label: "Rechteckig", value: "square" },
        { label: "Extra Rund", value: "extra-rounded" }
      ],
      newEmployeeEmail: '',
      roles: [
        { label: 'Mitarbeiter', value: 'waiter', icon: 'person' },
        { label: 'Editor', value: 'editor', icon: 'edit' }
      ],
      selectedRole: { label: 'Mitarbeiter', value: 'waiter', icon: 'person' },
      invited: []
    };
  },
  watch: {
    show: {
      handler: function () {
        if (this.show) {
          this.assingProperties();
        }
      },
    },
    generalSettings: {
      handler: function () {
        this.$emit("update:generalSettingsParent", this.generalSettings);
      },
      deep: true,
    },
    'generalSettings.qrCodeOptions.color': function () {
      this.updateQRCode();
    },
    'generalSettings.qrCodeOptions.dotType': function () {
      this.updateQRCode();
    },
    'generalSettings.qrCodeOptions.logo': function () {
      this.updateQRCode();
    }
  },
  methods: {


    async fetchInvited() {
      try {
        const response = await MenuHandler.fetchInvited(this.menuId);
        this.invited = response.data || [];
      } catch (error) {
        console.error('Error fetching invited list:', error);
      }
    },
    async changeRole(email, role) {
      try {
        const response = await MenuHandler.changeRole(this.menuId, email, role);
        this.invited = response.data;
      } catch (error) {
        console.error('Error updating role:', error);
      }
    },
    async removeEmployee(email) {
      try {
        const response = await MenuHandler.removeEmployee(this.menuId, email);
        this.invited = response.data;
      } catch (error) {
        console.error('Error removing employee:', error);
      }
    },
    async assingProperties() {
      await this.fetchInvited();

      if (JSON.stringify(this.generalSettingsParent) !== "{}") {
        this.generalSettings = cloneDeep(this.generalSettingsParent);
      } else {
        this.generalSettings.currentFont = "Roboto";
        this.$emit("update:generalSettingsParent", this.generalSettings);
      }
      this.encryptedZero = this.encryptNumber(0);
      if (!this.generalSettings.qrCodeOptions.data) {
        this.generalSettings.qrCodeOptions.data = `${process.env.VUE_APP_ROOT_DOMAIN}/menu/${this.menuId}?id=${this.encryptedZero}`;
      }
    },
    selectCategoryBackgroundDropdownOption(icon, label) {
      this.generalSettings.selectedCategoryBackgroundDropdownElement = {
        icon: icon,
        label: label,
      };

      if (icon == "gradient") {
        this.setGradientBackground();
      }
    },
    setGradientBackground() {
      this.generalSettings.backgroundColor =
        "linear-gradient(" +
        this.generalSettings.gradientAngle +
        "deg," +
        this.hexToRGB(this.generalSettings.gradientColor1, 100) +
        ", " +
        this.hexToRGB(this.generalSettings.gradientColor2, 100) +
        ") ";
    },
    buildBackgroundOverlay() {
      this.generalSettings.linearGradientOverlay =
        "linear-gradient(" +
        this.hexToRGB(
          this.generalSettings.overlayColor,
          this.generalSettings.overlayOpacity
        ) +
        ", " +
        this.hexToRGB(
          this.generalSettings.overlayColor,
          this.generalSettings.overlayOpacity
        ) +
        "), ";
    },
    hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

      if (alpha && alpha == 100) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else if (alpha && alpha < 100 && alpha > 9) {
        return "rgba(" + r + ", " + g + ", " + b + ", 0." + alpha + ")";
      } else if (alpha && alpha < 10 && alpha > 0) {
        return "rgba(" + r + ", " + g + ", " + b + ", 0.0" + alpha + ")";
      } else {
        return "rgb(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      }
    },
    handleImageUpload(event) {
      this.isImageUploadLoading = true;
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        const imageData = {
          image: base64String,
        };

        AssetHandler.uploadImage(imageData, this.menuId)
          .then((response) => {
            this.generalSettings.currentSelectedImage = response.data;
            this.isImageUploadLoading = false;
          })
          .catch((error) => {
            this.isImageUploadLoading = false;
            console.error("Error uploading image:", error);
          });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    },
    handleQRImageUpload(event) {
      this.isQRImageUploadLoading = true;
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        const imageData = {
          image: base64String,
        };

        AssetHandler.uploadImage(imageData, this.menuId)
          .then((response) => {
            this.generalSettings.qrCodeOptions.logo = response.data;
            this.isQRImageUploadLoading = false;
          })
          .catch((error) => {
            this.isQRImageUploadLoading = false;
            console.error("Error uploading image:", error);
          });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    },
    deleteImage() {
      if (!this.generalSettings.currentSelectedImage) {
        return;
      }

      AssetHandler.deleteImage(this.generalSettings.currentSelectedImage).then(
        () => {
          this.generalSettings.currentSelectedImage = "";
        }
      );
    },
    deleteQRImage() {
      if (!this.generalSettings.qrCodeOptions.logo) {
        return;
      }

      AssetHandler.deleteImage(this.generalSettings.qrCodeOptions.logo).then(
        () => {
          this.generalSettings.qrCodeOptions.logo = "";
        }
      );
    },
    setPixabayImage(file) {
      this.isImageUploadLoading = true;
      if (!file) {
        return;
      }
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        const imageData = {
          image: base64String,
        };

        AssetHandler.uploadImage(imageData, this.menuId)
          .then((response) => {
            this.generalSettings.currentSelectedImage = response.data;
            this.isImageUploadLoading = false;
          })
          .catch((error) => {
            this.isImageUploadLoading = false;
            console.error("Error uploading image:", error);
          });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    },
    setPrimaryColor() {
      this.generalSettings.flyingCartButtonIconColor =
        this.generalSettings.primaryColor;
      this.generalSettings.addToCartButtonIconColor =
        this.generalSettings.primaryColor;
      this.generalSettings.callEmployeeButtonIconColor =
        this.generalSettings.primaryColor;
      this.generalSettings.backButtonTextColor =
        this.generalSettings.primaryColor;
    },
    setSecondaryColor() {
      this.generalSettings.flyingCartButtonBackgroundColor =
        this.generalSettings.secondaryColor;
      this.generalSettings.backButtonBackgroundColor =
        this.generalSettings.secondaryColor;
      this.generalSettings.callEmployeeButtonBackgroundColor =
        this.generalSettings.secondaryColor;
    },
    setDefaultFont(option) {
      this.generalSettings.currentFont = option;
    },
    confirmDeletion() {
      this.isDialogOpen = true;
    },
    async deleteMenu() {
      if (this.confirmDeleteText.toUpperCase() === 'LÖSCHEN') {
        try {
          await MenuHandler.deleteMenu(this.menuId);
          this.$router.go(-1);
          this.confirmDeleteText = '';
          this.isDialogOpen = false;
        } catch (error) {
          console.error('Error deleting menu:', error);
        }
      } else {
        alert('Falscher Bestätigungstext.');
      }
    },
    updateQRCode() {
      if (this.qrCode) {
        this.qrCode.update({
          data: this.generalSettings.qrCodeOptions.data,
          dotsOptions: { color: this.generalSettings.qrCodeOptions.color, type: this.generalSettings.qrCodeOptions.dotType.value },
          image: this.generalSettings.qrCodeOptions.logo || undefined,
          backgroundOptions: { color: 'transparent' }
        });
      }
    },
    setDotType(option) {
      this.generalSettings.qrCodeOptions.dotType = option;
    },
    triggerQRFileInput() {
      this.$refs.qrFileInput.click();
    },
    triggerBackgroundFileInput() {
      this.$refs.backgroundFileInput.click();
    },
    async generateQRCodes(menuId) {
      this.isQRCodesLoading = true;
      const baseURL = `${process.env.VUE_APP_ROOT_DOMAIN}/menu/${menuId}`;

      try {
        for (let i = 1; i <= this.generalSettings.numberOfTables; i++) {
          const encryptedId = this.encryptNumber(i);
          const urlWithParam = `${baseURL}?id=${encryptedId}`;
          this.generalSettings.qrCodeOptions.data = urlWithParam;

          // Update the QR code data
          this.qrCode = new QRCodeStyling(this.generalSettings.qrCodeOptions);
          this.updateQRCode();
          // Add a small delay to ensure QR code renders correctly before downloading
          await new Promise(resolve => setTimeout(resolve, 500));

          // Download the QR code
          await this.qrCode.download({ name: `QR-${i}`, extension: 'png' });
        }
      } catch (error) {
        console.error('Error generating QR codes:', error);
      } finally {
        this.isQRCodesLoading = false;
      }
    },

    encryptNumber(number) {
      const encrypted = btoa(number);
      return encrypted;
    },
    selectRole(role) {
      this.selectedRole = role;
    },
    async inviteEmployee() {
      try {
        const response = await MenuHandler.inviteEmployee(this.menuId, this.newEmployeeEmail, this.selectedRole.value);
        this.invited = response.data;
        this.newEmployeeEmail = '';
        this.selectedRole = { label: 'Mitarbeiter', value: 'waiter', icon: 'person' };
      } catch (error) {
        console.error('Error inviting employee:', error);
      }
    },
    getRoleLabel(role) {
      const foundRole = this.roles.find(r => r.value === role);
      return foundRole ? foundRole.label : 'Unbekannt';
    },
    getRoleIcon(role) {
      const foundRole = this.roles.find(r => r.value === role);
      return foundRole ? foundRole.icon : 'help';
    },

  },
};
</script>

<style lang="scss">
@import "scss/colors.scss";

.generalSettingsWrapper {
  width: 560px;
  height: 844px;
  max-height: 90vh;
  border: solid 1px $grey-medium;
  border-radius: 20px;
  overflow-y: scroll;
  padding: 20px;
  padding-right: 0px;

  @media (max-width: $mobile) {
    transform: scale(0.7);
    margin-top: -5rem;

  }

  box-shadow: 0 1px 5px rgb(0 0 0 / 20%),
  0 2px 2px rgb(0 0 0 / 14%),
  0 3px 1px -2px rgb(0 0 0 / 12%);

  background-color: white;

  display: flex;
  justify-content: flex-start;

  label {
    margin: 10px;
    font-weight: bold;
  }

  input {
    height: 22px;
    width: 35px;
  }

  .generalSettings {
    width: 560px;

    .body {
      height: 700px;
      overflow-y: scroll;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h6 {
        margin-top: 0px;
        margin-bottom: 15px;
      }

      label {
        margin: 0px;
      }

      input {
        width: 50px;
        font-family: Roboto;
        border-radius: 7px;
        border: solid 1px rgb(201, 201, 201);
        margin-left: 5px;

        transition: 0.3s;

        &:hover {
          border: solid 1px $grey-medium;
        }
      }

      .currentColor {
        height: 17px;
        width: 23px;
        margin-left: 11px;
        margin-top: 3px;
        border-radius: 5px;
        border: solid 1px black;

        &:hover {
          cursor: pointer;
        }
      }

      .imageUploadPlaceholder {
        border: solid 1px $grey-light;
        height: 151.5px;
        width: 70px;
        border-radius: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;

        img {
          height: 151.5px;
          width: 70px;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .qrIconUploadPlaceholder {
        border: solid 1px $grey-light;
        height: 70px;
        width: 70px;
        border-radius: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;

        img {
          height: 70px;
          width: 70px;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .optionIconSearch {
        height: 25px;
        width: 30px;
        margin-top: 2px;
        margin-left: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;

        transition: 0.3s ease;

        border: solid 1px $grey-light;
        border-radius: 5px;
        padding: 13px;

        &:hover {
          cursor: pointer;
          background-color: $grey-light;
        }
      }

      .optionIconUpload {
        height: 28px;
        width: 30px;

        margin-left: 25px;
        margin-top: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;

        transition: 0.3s ease;

        border: solid 1px $grey-light;
        border-radius: 5px;

        z-index: 100;

        .fileInput {
          &:hover {
            cursor: pointer;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $grey-light;
        }
      }

      .optionIconDelete {
        font-size: large;
        margin-left: 110px;
        margin-top: -3px;

        &:hover {
          cursor: pointer;
        }
      }

      .optionQRIconDelete {
        font-size: large;
        margin-left: 2rem;
        margin-top: -3px;

        &:hover {
          cursor: pointer;
        }
      }

      .inviteButton {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background-color: $green-poison;
        color: white;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s ease;
        height: max-content;

        &:hover {
          background-color: darken($green-poison, 10%);
        }
      }

      .removeButton {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background-color: #f44336;
        color: white;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          background-color: darken(#f44336, 10%);
        }
      }
    }
  }

  .deleteWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 5rem;

    .delete-button {
      padding: 10px 20px;
      background-color: #f44336;
      border: none;
      color: white;
      cursor: pointer;
      border-radius: 5px;
      font-size: 16px;
      margin-bottom: 2rem;
    }

  }
}

.confirmDeleteText {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid $grey-medium;
  border-radius: 5px;
  padding: 5px;
}

.QRButtonWrapper {
  color: $green-poison;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;

  .QRButton {
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 25px;
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    padding: calc(0.25rem + 5px) 1rem;
    gap: 0.5rem;

    &:hover {
      background-color: $grey-lighter;
    }

    .icon {
      font-size: 2rem;
      margin-bottom: 5px;
    }

    .spinner {
      position: absolute;
      right: -10%;
      top: 50%;
      transform: translate(0, -50%) !important;
    }
  }
}
</style>
