<template>
  <div v-if="show">
    <div class="seperationElementBody">
      <div>
        <div><h6 style="margin-top: 20px">Farbe und Form</h6></div>
        <div style="display: flex">
          <label>Farbe: </label>
          <div
            class="currentColor"
            :style="{
              background:
                localEditElement.elementStyleValues.currentPickedColor,
            }"
          >
            <QPopupProxy
              cover
              transition-show="scale"
              transition-hide="scale"
              style="margin-left: 10px"
            >
              <QColor
                v-model="localEditElement.elementStyleValues.currentPickedColor"
                @update:model-value="
                  buildElementStylePropertiesString(
                    'background',
                    localEditElement.elementStyleValues.currentPickedColor
                  )
                "
              />
            </QPopupProxy>
          </div>
          <label style="margin-left: 20px">Rundung: </label>

          <input
            type="number"
            min="0"
            max="100"
            v-model="localEditElement.elementStyleValues.borderRadius"
            @input="
              buildElementStylePropertiesString(
                'border-radius',
                localEditElement.elementStyleValues.borderRadius + 'px'
              )
            "
          />
        </div>
        <div><h6 style="margin-top: 20px">Elementabstand</h6></div>
        <div>
          <label>Oben: </label>
          <input
            type="number"
            min="0"
            max="500"
            v-model="localEditElement.elementStyleValues.marginTop"
            @input="
              buildElementStylePropertiesString(
                'margin-top',
                localEditElement.elementStyleValues.marginTop + 'px'
              )
            "
          />
          <label style="margin-left: 10px">Unten: </label>
          <input
            type="number"
            min="0"
            max="500"
            v-model="localEditElement.elementStyleValues.marginBottom"
            @input="
              buildElementStylePropertiesString(
                'margin-bottom',
                localEditElement.elementStyleValues.marginBottom + 'px'
              )
            "
          /><label style="margin-left: 10px">Links: </label>
          <input
            type="number"
            min="0"
            max="500"
            v-model="localEditElement.elementStyleValues.marginLeft"
            @input="
              buildElementStylePropertiesString(
                'margin-left',
                localEditElement.elementStyleValues.marginLeft + 'px'
              )
            "
          />
          <label style="margin-left: 10px">Rechts: </label>
          <input
            type="number"
            min="0"
            max="500"
            v-model="localEditElement.elementStyleValues.marginRight"
            @input="
              buildElementStylePropertiesString(
                'margin-right',
                localEditElement.elementStyleValues.marginRight + 'px'
              )
            "
          />
        </div>
        <div><h6 style="margin-top: 20px">Elementgröße</h6></div>
        <div>
          <label>Höhe: </label>
          <input
            type="number"
            min="1"
            max="500"
            v-model="localEditElement.elementStyleValues.height"
            @input="
              buildElementStylePropertiesString(
                'height',
                localEditElement.elementStyleValues.height + 'px'
              )
            "
          />
          <label style="margin-left: 20px">Breite: </label>
          <input
            type="number"
            min="1"
            max="350"
            v-model="localEditElement.elementStyleValues.width"
            @input="
              buildElementStylePropertiesString(
                'width',
                localEditElement.elementStyleValues.width + 'px'
              )
            "
          />
        </div>
      </div>
      <div class="saveSeperationElementBtn">
        <MyButton buttonStyle="btn btn-medium" @click="saveSeperationElement()"
          >Speichern</MyButton
        >
      </div>
    </div>
  </div>
</template>
<script>
import { QPopupProxy, QColor } from "quasar";
import MyButton from "../shared/MyButton.vue";
import buildStylePropertiesString from "../../stylePropertyStringBuilder";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentEditElement: {
      type: Object,
      default: null,
    },
    generalSettings: {
      type: Object,
      default: null,
    },
  },
  components: { QPopupProxy, QColor, MyButton },

  data() {
    return {
      /* currentPickedColor: this.$store.state.secondaryColorSettings,

      currentElementStylePropertiesMarginTop: 0,
      currentElementStylePropertiesMarginBottom: 0,
      currentElementStylePropertiesMarginLeft: 0,
      currentElementStylePropertiesMarginRight: 0,
      currentElementStylePropertiesHeight: 0,
      currentElementStylePropertiesWidth: 0,

      currentElementStylePropertiesBorderRadius: 0, */

      localEditElement: {
        initialized: true,
        elementStyleProperties: "",
        elementStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          height: 0,
          width: 0,
          opacity: 0,
          borderRadius: 0,
          currentPickedColor: this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9",
        },
      },
    };
  },

  watch: {
    "currentEditElement.elementId": {
      handler: function (newValue, oldValue) {
        if (
          this.$store.state.currentElementMode == "seperation" &&
          oldValue !== newValue
        ) {
          (this.localEditElement = {
            initialized: true,
            elementStyleProperties: "",
            elementStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              height: 0,
              width: 0,
              opacity: 0,
              borderRadius: 0,
              currentPickedColor: this.generalSettings.secondaryColor
                ? this.generalSettings.secondaryColor
                : "#edede9",
            },
          }),
            this.assignProperties();
        }
      },
      deep: true,
    },
    localEditElement: {
      handler: function () {
        if (this.localEditElement) {
          this.$emit("update:currentEditElement", this.localEditElement);
        }
      },
      deep: true,
    },
  },

  methods: {
    assignProperties() {
      var seperationUserSettings = this.$store.state.seperationElementSettings;
      this.localEditElement.elementId = this.currentEditElement.elementId;
      this.localEditElement.elementType = this.currentEditElement.elementType;
      this.localEditElement.initialized = true;
      if (JSON.stringify(seperationUserSettings) !== "{}") {
        this.localEditElement.elementStyleProperties =
          seperationUserSettings.elementStyle;
        this.localEditElement.elementStyleValues.marginTop =
          seperationUserSettings.elementStyleValues.marginTop;
        this.localEditElement.elementStyleValues.marginBottom =
          seperationUserSettings.elementStyleValues.marginBottom;
        this.localEditElement.elementStyleValues.marginLeft =
          seperationUserSettings.elementStyleValues.marginLeft;
        this.localEditElement.elementStyleValues.marginRight =
          seperationUserSettings.elementStyleValues.marginRight;
        this.localEditElement.elementStyleValues.height =
          seperationUserSettings.elementStyleValues.height;
        this.localEditElement.elementStyleValues.width =
          seperationUserSettings.elementStyleValues.width;
        this.localEditElement.elementStyleValues.opacity =
          seperationUserSettings.elementStyleValues.opacity;
        this.localEditElement.elementStyleValues.borderRadius =
          seperationUserSettings.elementStyleValues.borderRadius;
        this.localEditElement.elementStyleValues.currentPickedColor =
          seperationUserSettings.elementStyleValues.currentPickedColor;
      } else {
        this.localEditElement.elementStyleProperties =
          "background: " +
          (this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9") +
          "; margin-top: 20px; height: 2px; width: 200px; border-radius: 20px;";
        this.localEditElement.elementStyleValues.marginTop = 20;
        this.localEditElement.elementStyleValues.marginBottom = 0;
        this.localEditElement.elementStyleValues.marginLeft = 0;
        this.localEditElement.elementStyleValues.marginRight = 0;
        this.localEditElement.elementStyleValues.height = 2;
        this.localEditElement.elementStyleValues.width = 200;
        this.localEditElement.elementStyleValues.opacity = 0;
        this.localEditElement.elementStyleValues.borderRadius = 20;
        this.localEditElement.elementStyleValues.currentPickedColor = this
          .generalSettings.secondaryColor
          ? this.generalSettings.secondaryColor
          : "#edede9";
      }
    },
    buildElementStylePropertiesString(styleProperty, value) {
      this.localEditElement.elementStyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.elementStyleProperties
      );
      this.$emit(
        "updateElementStyle",
        this.localEditElement.elementStyleProperties
      );
    },
    saveSeperationElement() {
      const seperationElementSettings = {
        elementStyle: this.localEditElement.elementStyleProperties,
        elementStyleValues: {
          marginTop: this.localEditElement.elementStyleValues.marginTop,
          marginBottom: this.localEditElement.elementStyleValues.marginBottom,
          marginLeft: this.localEditElement.elementStyleValues.marginLeft,
          marginRight: this.localEditElement.elementStyleValues.marginRight,
          height: this.localEditElement.elementStyleValues.height,
          width: this.localEditElement.elementStyleValues.width,
          opacity: this.localEditElement.elementStyleValues.opacity,
          borderRadius: this.localEditElement.elementStyleValues.borderRadius,
        },
      };

      /* const seperationElementSettings = {
        elementStyle: this.currentElementStyleProperties,
        elementStyleValues: {
          marginTop: this.currentElementStylePropertiesMarginTop,
          marginBottom: this.currentElementStylePropertiesMarginBottom,
          marginLeft: this.currentElementStylePropertiesMarginLeft,
          marginRight: this.currentElementStylePropertiesMarginRight,
          height: this.currentElementStylePropertiesHeight,
          width: this.currentElementStylePropertiesWidth,
          opacity: this.currentElementStylePropertiesOpacity,
          borderRadius: this.currentElementStylePropertiesBorderRadius,
        },
      }; */

      this.$store.commit(
        "setSeperationElementSettings",
        seperationElementSettings
      );

      this.$emit("hideDialog");
    },
  },
};
</script>
<style lang="scss">
@import "scss/colors.scss";

.seperationElementBody {
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .currentColor {
    height: 17px;
    width: 23px;
    margin-left: 11px;
    margin-top: 3px;
    border-radius: 5px;
    border: solid 1px black;

    &:hover {
      cursor: pointer;
    }
  }
  .saveSeperationElementBtn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
    margin-top: 2rem;
  }
}
</style>
