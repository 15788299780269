<template>
  <div class="main">
    <QIcon name="arrow_back" class="backIcon" @click="$router.go(-1)"></QIcon>
    <div>
      <div class="menuCreation">
        <div>
          <button class="flyingCartButton" id="flyingCartButton"
            v-if="!displayedElements.generalSettings?.onlineOrderingDisabled"
            :style="flyingCartButtonElementStyleProperties" @click="showCart()">
            <div :style="flyingCartButtonTextStyleProperties">
              <QIcon class="icon"
                :name="displayedElements.generalSettings?.flyingCartButtonIcon || 'shopping_cart'">
              </QIcon>
              <label class="numberOfCartItems">{{
      totalCartQuantity
    }}</label>
            </div>
          </button>

          <div class="cartWrapper" id="cartOverlay">
            <CartComponent id="cartComponent" class="cartComponent"
              :generalSettings="displayedElements.generalSettings"
              :menuId="displayedElements.title" :tableNumber="0">
            </CartComponent>
          </div>
          <button class="callEmployeeButton"
            v-if="!displayedElements.generalSettings?.employeeCallDisabled"
            :style="callEmployeeButtonElementStyleProperties"
            @click="showCallEmployeeConfirmComponent()">
            <div :style="callEmployeeButtonTextStyleProperties">
              <QIcon class="icon"
                :name="displayedElements.generalSettings?.callEmployeeButtonIcon || 'person_apron'">
              </QIcon>
            </div>
          </button>
          <div class="callEmployeeConfirmWrapper"
            id="callEmployeeConfirmOverlay">
            <EmployeeCallConfirm id="callEmployeeConfirmComponent"
              class="callEmployeeConfirmComponent"
              :generalSettings="displayedElements.generalSettings"
              :menuId="displayedElements.title" :tableNumber="0">
            </EmployeeCallConfirm>
          </div>
          <button class="backButton"
            v-if="currentScreenLevel > 0 || showGeneralSettings"
            @click="showElementParentContent()"
            :style="backButtonElementStyleProperties">
            <div :style="backButtonTextStyleProperties">
              <span v-if="!showCategoryInput">Zurück</span>
              <QIcon class="icon" name="arrow_back" style="font-size: 25px">
              </QIcon>
            </div>
          </button>
        </div>
        <div style="
            height: 700px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            gap: 20px;
            margin-right: 4%;
          " v-if="currentElements.elements.length === 0 &&
      showElementKit &&
      currentScreenLevel == 0 &&
      !isMenuLoading
      ">
          <div style="font-size: 20px">
            <span>Füge dein erstes Element hinzu! </span>
          </div>
          <div style="font-size: 50px">
            <QIcon name="add_box"></QIcon>
          </div>
        </div>
        <div v-else-if="isMenuLoading">
          <div class="spinnerWrapperMenu">
            <QSpinner color="light-green" size="3rem" />
          </div>
        </div>
        <div v-if="currentElements.elements.length > 0 ||
      showElementDialog ||
      showGeneralSettings ||
      currentScreenLevel > 0
      " id="menuElements" class="menuElements">
          <Draggable v-model="currentElements.elements" handle=".drag-handle"
            v-if="!showGeneralSettings" @change="handleDrag">
            <template #item="{ element, index }">
              <div :key="element.id" @mouseenter="hover = index"
                @mouseleave="hover = null">
                <div class="menuElement">
                  <div v-if="element.elementType == 'text'" class="textElement">
                    <div v-html="element.content?.text"
                      :style="element.textstyleProperties"></div>
                  </div>
                  <div v-else-if="element.elementType == 'category'"
                    class="categoryElement"
                    @click="showElementChildContent(index)">
                    <span></span>
                    <div :style="element.elementStyleProperties"
                      v-if="!element.content.image">
                      <div v-html="element.content.text"
                        :style="element.textstyleProperties"></div>
                    </div>
                    <div v-if="element.content.image"
                      :style="element.elementStyleProperties">
                      <img draggable="false" :src="element.content.image"
                        :style="element.imageStyleProperties" />
                      <div v-html="element.content.text"
                        :style="element.textstyleProperties"></div>
                    </div>
                  </div>
                  <div v-else-if="element.elementType == 'image'"
                    class="imageElement">
                    <div v-if="element.content.image">
                      <img draggable="false" :src="element.content.image"
                        :style="element.imageStyleProperties" />
                    </div>
                    <div v-else :style="element.imageStyleProperties">
                      <QIcon name="hourglass_empty"></QIcon>
                    </div>
                  </div>
                  <div v-if="element.elementType == 'product'"
                    class="productElement">
                    <div :style="element.elementStyleProperties"
                      v-if="element.content.image">
                      <div style="
                          display: flex;
                          justify-content: center;
                          flex-direction: row;
                          align-items: center;
                        ">
                        <div style="height: 40px; width: 60px">
                          <img :src="element.content.image"
                            :style="element.imageStyleProperties" />
                        </div>
                        <div style="
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;
                            gap: 10px;
                            width: 180px;
                          ">
                          <div v-html="element.content.productTitle"
                            :style="element.textstyleProperties">
                          </div>
                          <div v-html="element.content.productSubTitle"
                            :style="element.subTextStyleProperties + 'text-align: start;'">
                          </div>
                        </div>
                        <div v-html="getProductPrice(element.content)"
                          :style="element.priceTextStyleProperties + 'width: 50px'">
                        </div>
                        <div
                          v-if="!displayedElements.generalSettings?.onlineOrderingDisabled"
                          class="addToCartButton" :style="'width: 60px; color: ' +
      displayedElements.generalSettings
        ?.addToCartButtonIconColor
      " @click="addProductToCart(element.content)">
                          <QIcon :name="displayedElements.generalSettings
      ?.addToCartButtonIcon
      ">
                          </QIcon>
                        </div>
                      </div>
                    </div>
                    <div :style="element.elementStyleProperties" v-else>
                      <div style="
                          display: flex;
                          justify-content: center;
                          flex-direction: row;
                          align-items: center;
                        ">
                        <div style="
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;
                            gap: 10px;
                            width: 170px;
                          ">
                          <div v-html="element.content.productTitle"
                            :style="element.textstyleProperties"></div>
                          <div v-html="element.content.productSubTitle"
                            :style="element.subTextStyleProperties + 'text-align: start;'">
                          </div>
                        </div>
                        <div v-html="getProductPrice(element.content)"
                          :style="element.priceTextStyleProperties + 'width: 100px'">
                        </div>
                        <div
                          v-if="!displayedElements.generalSettings?.onlineOrderingDisabled"
                          class="addToCartButton" :style="'width: 60px; color: ' +
      displayedElements.generalSettings
        ?.addToCartButtonIconColor
      " @click="addProductToCart(element.content)">
                          <QIcon :name="displayedElements.generalSettings
      ?.addToCartButtonIcon
      ">
                          </QIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="element.elementType == 'seperation'"
                    class="seperationElement">
                    <div :style="element.elementStyleProperties"></div>
                  </div>

                  <div v-if="element.elementType == 'googleReview'"
                    class="seperationElement"
                    :style="element.elementStyleProperties">
                    <div
                      style="display: flex; flex-direction: column; align-items: center; gap: 1rem;">
                      <div
                        style="display: flex; align-items: center; gap: 1rem;">
                        <div v-html="element.content.firstText"
                          :style="element.firstTextstyleProperties"></div>
                        <button :style="element.buttonStyleProperties"
                          class="googleReviewButton"
                          @click="openUrl(element.content.googleLink)">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 272 92" width="60" height="30">
                            <path :fill="element.buttonStyleValues.primaryColor"
                              d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z" />
                            <path :fill="element.buttonStyleValues.primaryColor"
                              d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z" />
                            <path :fill="element.buttonStyleValues.primaryColor"
                              d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z" />
                            <path :fill="element.buttonStyleValues.primaryColor"
                              d="M225 3v65h-9.5V3h9.5z" />
                            <path :fill="element.buttonStyleValues.primaryColor"
                              d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z" />
                            <path :fill="element.buttonStyleValues.primaryColor"
                              d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z" />
                          </svg>

                        </button>
                      </div>
                      <div v-html="element.content.secondText"
                        :style="element.secondTextstyleProperties"></div>
                    </div>
                  </div>
                  <div v-if="element.elementType == 'socialMedia'"
                    class="seperationElement">
                    <div :style="element.elementStyleProperties">
                      <svg v-if="element.content.showFacebook"
                        @click="openUrl(element.content.facebookLink)"
                        :fill="element.socialIconStyleValues.color"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                        :width="element.socialIconStyleValues.width + 'px'"
                        :height="element.socialIconStyleValues.height + 'px'">
                        <path
                          d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z" />
                      </svg>
                      <svg v-if="element.content.showInstagram"
                        @click="openUrl(element.content.instagramLink)"
                        :fill="element.socialIconStyleValues.color"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                        :width="element.socialIconStyleValues.width + 'px'"
                        :height="element.socialIconStyleValues.height + 'px'">
                        <path
                          d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z" />
                      </svg>
                      <svg v-if="element.content.showTiktok"
                        @click="openUrl(element.content.tiktokLink)"
                        :fill="element.socialIconStyleValues.color"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                        :width="element.socialIconStyleValues.width + 'px'"
                        :height="element.socialIconStyleValues.height + 'px'">
                        <path
                          d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
                      </svg>


                    </div>
                  </div>

                  <ElementOptions class="elementOptions" v-if="hover === index"
                    :element-id="element.elementId"
                    :elements="currentElements.elements"
                    @delete-element="deleteElement" @edit-element="editElement"
                    @copy-element="copyElement">
                  </ElementOptions>
                  <InsertOptions class="insertOptions" :index="index"
                    v-if="hover === index" :elements="currentElements.elements"
                    @element-inserted="insertElement"></InsertOptions>
                  <div v-if="index === currentElements.elements.length - 1"
                    style="margin-bottom: 100px;"></div>
                  <q-icon name="drag_handle" class="drag-handle"></q-icon>
                </div>
              </div>
            </template>
          </Draggable>
          <div>
            <div v-if="currentEditElement?.elementType == 'generalSettingsPreview' &&
      showGeneralSettings
      " class="generalSettingsPreview">
              <div :style="currentElementStyleProperties">
                <div :style="currentTextStyleProperties + 'color: ' +
      displayedElements.generalSettings?.primaryColor +
      ';font-family: ' +
      displayedElements.generalSettings?.currentFont
      ">
                  <span>Primär</span>
                </div>
              </div>
            </div>
            <div class=" generalProductPreview" v-if="showGeneralSettings">
              <div :style="generalProductPreviewElementStyleProperties +
      'background: ' +
      displayedElements.generalSettings?.secondaryColor
      ">
                <div style="
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    gap: 50px;
                  ">
                  <div style="
                      display: flex;
                      align-items: flex-start;
                      flex-direction: column;
                      gap: 10px;
                    ">
                    <div v-html="productTitleValue" :style="generalProductPreviewTextStyleProperties +
      'color: ' +
      displayedElements.generalSettings?.primaryColor +
      ';font-family: ' +
      displayedElements.generalSettings?.currentFont
      "></div>
                    <div v-html="productSubtitleValue" :style="generalProductPreviewSubTextStyleProperties +
      'color: ' +
      displayedElements.generalSettings?.primaryColor +
      ';font-family: ' +
      displayedElements.generalSettings?.currentFont
      "></div>
                  </div>
                  <div v-html="productPriceValue" :style="generalProductPreviewPriceTextStyleProperties +
      'color: ' +
      displayedElements.generalSettings?.primaryColor +
      ';font-family: ' +
      displayedElements.generalSettings?.currentFont
      "></div>
                  <div class="addToCartButton" :style="'color: ' +
      displayedElements.generalSettings.addToCartButtonIconColor
      ">
                    <QIcon :name="displayedElements.generalSettings.addToCartButtonIcon
      ">
                    </QIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="generalSettingsButtonWrapper">
        <div class="generalSettingsButton" @mouseenter="rotatingAnimation()"
          @click="showGeneralSettingsDialog()">
          <QIcon name="settings" class="settingsIcon" id="generalSettingsIcon">
          </QIcon>
          <span>Allgemein</span>
        </div>
      </div>
    </div>

    <div class="dialogWrapper" id="dialogWrapper">
      <div class="dialog">
        <div class="top">
          <QIcon class="icon" name="close" @click="hideDialog()" />
        </div>
        <div class="head">
          <QBtnDropdown class="dropdown" dropdown-icon="expand_more"
            :icon="selectedDropdownElement.icon"
            :label="selectedDropdownElement.label" :auto-close="true"
            style="border-radius: 10px">
            <QList v-for="option in dropdownOptions" :key="option">
              <QItem clickable v-close-popup @click="
      selectDropdownOption(
        option.icon,
        option.label,
        option.elementType
      )
      ">
                <QItemSection>
                  <QItemLabel>
                    <QIcon :name="option.icon"></QIcon>
                    {{ option.label }}
                  </QItemLabel>
                </QItemSection>
              </QItem>
            </QList>
          </QBtnDropdown>
        </div>

        <div class="body">
          <TextElementDialogBody v-if="currentEditElement"
            :show="this.$store.state.currentElementMode == 'text'"
            v-model:currentEditElement="currentEditElement"
            :generalSettings="displayedElements.generalSettings"
            @hideDialog="hideDialog()">
          </TextElementDialogBody>

          <ImageElementDialogBody
            :show="this.$store.state.currentElementMode == 'image'"
            v-model:currentEditElement="currentEditElement"
            :generalSettings="displayedElements.generalSettings"
            :menuId="displayedElements.title" @hideDialog="hideDialog()">
          </ImageElementDialogBody>

          <CategoryElementDialogBody
            :show="this.$store.state.currentElementMode == 'category'"
            v-model:currentEditElement="currentEditElement"
            :generalSettings="displayedElements.generalSettings"
            :menuId="displayedElements.title" @hideDialog="hideDialog()">
          </CategoryElementDialogBody>

          <ProductElementDialogBody
            :show="this.$store.state.currentElementMode == 'product'"
            @hideDialog="hideDialog()"
            :generalSettings="displayedElements.generalSettings"
            v-model:currentEditElement="currentEditElement"
            :menuId="displayedElements.title">
          </ProductElementDialogBody>
          <SeperationElementDialogBody
            :show="this.$store.state.currentElementMode == 'seperation'"
            @hideDialog="hideDialog()"
            :generalSettings="displayedElements.generalSettings"
            v-model:currentEditElement="currentEditElement">
          </SeperationElementDialogBody>
          <GoogleReviewElementDialogBody
            :show="this.$store.state.currentElementMode == 'googleReview'"
            @hideDialog="hideDialog()"
            :generalSettings="displayedElements.generalSettings"
            v-model:currentEditElement="currentEditElement">
          </GoogleReviewElementDialogBody>
          <SocialMediaElementDialogBody
            :show="this.$store.state.currentElementMode == 'socialMedia'"
            @hideDialog="hideDialog()"
            :generalSettings="displayedElements.generalSettings"
            v-model:currentEditElement="currentEditElement">
          </SocialMediaElementDialogBody>
        </div>
      </div>
    </div>
    <div class="generalSettingsDialogWrapper" id="generalSettingsDialogWrapper">
      <div class="closeIconWrapper" v-if="showGeneralSettings">
        <QIcon class="closeIcon" name="close"
          @click="hideGeneralSettingsDialog()" />
      </div>

      <GeneralSettingsMenu :show="showGeneralSettings"
        v-model:generalSettingsParent="displayedElements.generalSettings"
        :menuId="displayedElements.title"></GeneralSettingsMenu>
    </div>
    <div class="versionHistoryWrapper" id="versionHistoryWrapper">
      <VersionHistory :uid="displayedElements.title"
        @close="hideVersionHistoryDialog()" v-if="showVersionHistory"
        @restoreEditMenuVersion="restoreEditMenu" />
    </div>
    <div class="elementKitWrapper">
      <div class="elementKit" id="elementKit">
        <div class="col1">
          <MyButton buttonStyle="btn btn-menuCreation" @click="
      showDialog();
    selectDropdownOption('text_fields', 'Text', 'text');
    ">
            <div class="buttonContent">
              <QIcon name="text_fields"></QIcon>
            </div>
            <div style="font-size: 20px">
              <span>Text</span>
            </div>
          </MyButton>
          <MyButton buttonStyle="btn btn-menuCreation" @click="
      showDialog();
    selectDropdownOption('image', 'Bild', 'image');
    ">
            <div class="buttonContent">
              <QIcon name="image"></QIcon>
            </div>
            <div style="font-size: 20px">
              <span>Logo/Bild</span>
            </div>
          </MyButton>
        </div>
        <div class="col2">
          <MyButton buttonStyle="btn btn-menuCreation" @click="
      showDialog();
    selectDropdownOption('category', 'Kategorie', 'category');
    ">
            <div class="buttonContent">
              <QIcon name="category"></QIcon>
            </div>
            <div style="font-size: 20px">
              <span>Kategorie</span>
            </div>
          </MyButton>
          <MyButton buttonStyle="btn btn-menuCreation" @click="
      showDialog();
    selectDropdownOption('sell', 'Produkt', 'product');
    ">
            <div class="buttonContent">
              <QIcon name="sell"></QIcon>
            </div>
            <div style="font-size: 20px">
              <span>Produkt</span>
            </div>
          </MyButton>
        </div>
        <div class="col3">
          <MyButton buttonStyle="btn btn-menuCreation" @click="
      showDialog();
    selectDropdownOption(
      'horizontal_rule',
      'Abtrennung',
      'seperation'
    );
    ">
            <div class="buttonContent">
              <QIcon name="horizontal_rule"></QIcon>
            </div>
            <div style="font-size: 20px">
              <span>Abtrennung</span>
            </div>
          </MyButton>
          <MyButton buttonStyle="btn btn-menuCreation" @click="
      showDialog();
    selectDropdownOption(
      'hotel_class',
      'Google',
      'googleReview'
    );
    ">
            <div class="buttonContent">
              <QIcon name="hotel_class"></QIcon>
            </div>
            <div style="font-size: 20px">
              <span>Google Bewertung</span>
            </div>
          </MyButton>
        </div>
        <div class="col4">
          <MyButton buttonStyle="btn btn-menuCreation" @click="
      showDialog();
    selectDropdownOption(
      'groups',
      'Socials',
      'socialMedia'
    );
    ">
            <div class="buttonContent">
              <QIcon name="groups"></QIcon>
            </div>
            <div style="font-size: 20px">
              <span>Socials</span>
            </div>
          </MyButton>

        </div>
      </div>
      <div class="publishButtonWrapper" v-if="showElementKit">
        <div class="publishButton" @click="publishMenu">
          Veröffentlichen <QIcon name="publish" class="icon"></QIcon>
          <QSpinner size="1.25rem" class="spinner" v-if="isPublishLoading">
          </QSpinner>
        </div>
        <div class="publishButton" @click="showVersionHistoryDialog">
          Historie <QIcon name="history" class="icon"></QIcon>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import MyButton from "../shared/MyButton.vue";
import GeneralSettingsMenu from "./GeneralSettingsMenu.vue";
import TextElementDialogBody from "../Dialog/TextElementDialogBody.vue";
import ImageElementDialogBody from "../Dialog/ImageElementDialogBody.vue";
import CategoryElementDialogBody from "../Dialog/CategoryElementDialogBody.vue";
import ProductElementDialogBody from "../Dialog/ProductElementDialogBody.vue";
import SeperationElementDialogBody from "../Dialog/SeperationElementDialogBody.vue";
import GoogleReviewElementDialogBody from "../Dialog/GoogleReviewElementDialogBody.vue";
import SocialMediaElementDialogBody from "../Dialog/SocialMediaElementDialogBody.vue";
import buildStylePropertiesString from "../../stylePropertyStringBuilder";
import CartComponent from "../Checkout/CartComponent.vue";
import EmployeeCallConfirm from "../EmployeeCall/EmployeeCallConfirm.vue";
import ElementOptions from "./ElementOptions.vue";
import InsertOptions from "./InsertOptions.vue";
import Draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";
import { getAuth } from "firebase/auth";
import {
  QIcon,
  QBtnDropdown,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QSpinner,
} from "quasar";
import cloneDeep from "lodash/cloneDeep";
import ToastTypes from "../../models/Types/ToastTypes";
import AssetHandler from "@/util/AssetHandler";
import VersionHistory from './VersionHistory.vue';
import UserHandler from "@/util/UserHandler";
import MenuHandler from "@/util/MenuHandler";

export default {
  components: {
    MyButton,
    QSpinner,
    QIcon,
    QBtnDropdown,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    GeneralSettingsMenu,
    TextElementDialogBody,
    ImageElementDialogBody,
    CategoryElementDialogBody,
    ProductElementDialogBody,
    SeperationElementDialogBody,
    GoogleReviewElementDialogBody,
    SocialMediaElementDialogBody,
    CartComponent,
    EmployeeCallConfirm,
    ElementOptions,
    InsertOptions,
    Draggable,
    VersionHistory
  },

  async mounted() {
    this.menuId = this.$route.params.menuId;
    this.fetchMenu(this.menuId);
    await this.checkIfPremiumUser();

  },

  data() {
    return {
      menuId: null,
      displayedElements: { editMenu: [], generalSettings: {} },
      currentElements: { elements: [] },
      currentMenuDocRef: null,
      isMenuLoading: true,
      hover: null, // Index of the currently hovered element

      initialLoad: true,

      currentScreenLevel: 0,

      // main control
      showElementDialog: false,
      showGeneralSettings: false,
      showElementKit: true,
      showVersionHistory: false,

      // dropdown control
      selectedDropdownElement: {
        icon: "text_fields",
        label: "Text",
        elementType: "text",
      },
      dropdownOptions: [
        { icon: "text_fields", label: "Text", elementType: "text" },
        { icon: "image", label: "Logo/Bild", elementType: "image" },
        { icon: "category", label: "Kategorie", elementType: "category" },
        { icon: "sell", label: "Produkt", elementType: "product" },
        {
          icon: "horizontal_rule",
          label: "Abtrennung",
          elementType: "seperation",
        },
        {
          icon: "hotel_class",
          label: "Google Bewertung",
          elementType: "googleReview",
        },
        {
          icon: "groups",
          label: "Socials",
          elementType: "socialMedia",
        },
      ],

      // preview element control
      /*  elementPreview: {
         elementType: "text",
       }, */

      //utility control
      textEditorValue: "Text",
      productTitleValue: "Produktname",
      productSubtitleValue: "Produktbeschreibung",
      productPriceValue: "Preis",

      //style property control
      currentTextStyleProperties:
        "font-weight: bold; font-size: 12pt; font-family: " +
        this.$store.state.currentFont +
        "; ",
      currentSubTextStyleProperties: "",
      currentPriceTextStyleProperties: "",
      currentElementStyleProperties: "",
      currentImageStyleProperties: "",
      currentPreviewImageStyleProperties:
        "max-height: 150px; max-width: 340px;",

      /*  currentGoogleReviewButtonStyleProperties:
         "background-color: #edede9; color: #000000; border-radius: 15px; padding: 1rem; border: none",
  */
      backButtonTextStyleProperties:
        "color: " +
        this.$store.state.backButtonTextColor +
        "; font-family: " +
        this.$store.state.currentFont +
        "; font-size: 15pt; display: flex; align-items: center; justify-content: center; gap: 20px;",

      backButtonElementStyleProperties:
        "background: " +
        this.$store.state.backButtonBackgroundColor +
        "; height: " +
        this.$store.state.backButtonHeight +
        "px; width: " +
        this.$store.state.backButtonWidth +
        "px; border-radius: " +
        this.$store.state.backButtonBorderRadius +
        "px; ",

      flyingCartButtonTextStyleProperties:
        "color: " +
        this.$store.state.backButtonTextColor +
        "; font-size: 25px; display: flex; align-items: center; justify-content: center; ",

      flyingCartButtonElementStyleProperties:
        "background: " +
        this.$store.state.secondaryColorSettings +
        "; height: 60px; width: 60px; border-radius: 360px; ",

      callEmployeeButtonTextStyleProperties:
        "color: " +
        this.$store.state.backButtonTextColor +
        "; font-size: 25px; display: flex; align-items: center; justify-content: center; ",

      callEmployeeButtonElementStyleProperties:
        "background: " +
        this.$store.state.secondaryColorSettings +
        "; height: 60px; width: 60px; border-radius: 360px; ",

      generalProductPreviewElementStyleProperties:
        "display: flex; justify-content: center; background: " +
        this.$store.state.secondaryColorSettings +
        "; margin-top: 20px; height: 80px; width: 340px; border-radius: 20px;",

      generalProductPreviewTextStyleProperties:
        "font-weight: bold; font-size: 12pt; font-family: " +
        this.$store.state.currentFont +
        "; ",

      generalProductPreviewSubTextStyleProperties:
        "font-size: 8pt; font-family: " + this.$store.state.currentFont + "; ",

      generalProductPreviewPriceTextStyleProperties:
        "font-size: 10pt; font-family: " + this.$store.state.currentFont + "; ",

      currentEditElement: undefined,
      isPublishLoading: false,
      isQRCodesLoading: false,
      isPremiumUser: false,  // New state to track if user is premium

      lastSyncTime: null, // Add a variable to keep track of the last sync time

    };
  },
  computed: {
    totalCartQuantity() {
      return this.$store.state.cartItems.reduce((acc, item) => {
        return acc + item.quantity;
      }, 0);
    },
  },
  watch: {
    currentEditElement: {
      handler: function (newValue) {
        if (!newValue || !newValue.elementId || !newValue.initialized) return; // Exit if no value or elementId

        // Find and update the element in currentElements.elements
        const index = this.currentElements.elements.findIndex(
          (el) => el.elementId === newValue.elementId
        );
        if (index !== -1) {
          this.currentElements.elements[index] = newValue;
        }

        //timeout to prevent sync before the element is fully updated
        // Update the displayedElements.editMenu recursively
        this.debounceSyncEditMenuWithCurrentElements();
      },
      deep: true,
    },
    "displayedElements.generalSettings": {
      handler: function () {
        if (this.initialLoad) {
          return;
        }
        this.syncMenu();
      },
      deep: true,
    },
    "displayedElements.generalSettings.primaryColor": function () {
      this.currentTextStyleProperties = buildStylePropertiesString(
        "color",
        this.displayedElements.generalSettings.primaryColor,
        this.currentTextStyleProperties
      );

    },
    "displayedElements.generalSettings.secondaryColor": function () {
      this.currentPickedColor =
        this.displayedElements.generalSettings.secondaryColor;
      if (this.showGeneralSettings) {
        this.currentElementStyleProperties = buildStylePropertiesString(
          "background",
          this.currentPickedColor,
          this.currentElementStyleProperties
        );

      }
    },
    "displayedElements.generalSettings.currentFont": function () {
      if (this.showGeneralSettings) {
        this.currentTextStyleProperties = buildStylePropertiesString(
          "font-family",
          this.displayedElements.generalSettings.currentFont,
          this.currentTextStyleProperties
        );
        this.backButtonTextStyleProperties = buildStylePropertiesString(
          "font-family",
          this.displayedElements.generalSettings.currentFont,
          this.backButtonTextStyleProperties
        );
      }
    },
    "displayedElements.generalSettings.backgroundColor": function () {
      var menu = document.querySelector(".menuCreation");
      if (!this.displayedElements.generalSettings.currentSelectedImage) {
        menu.style.background =
          this.displayedElements.generalSettings.backgroundColor;
        this.$store.commit("setMenuBackground", menu.style);
      }
    },
    "displayedElements.generalSettings.currentSelectedImage": function () {
      var menu = document.querySelector(".menuCreation");
      /*  if (this.displayedElements.generalSettings.currentSelectedImage) {
        menu.style.background = undefined;
      } */
      menu.style.backgroundImage =
        this.displayedElements.generalSettings.linearGradientOverlay +
        "url(" +
        this.displayedElements.generalSettings.currentSelectedImage +
        ")";

      menu.style.backgroundSize = "cover";
      menu.style.backgroundPosition = "center";

      this.$store.commit("setMenuBackground", menu.style);
    },
    "displayedElements.generalSettings.overlayColor": function () {
      var menu = document.querySelector(".menuCreation");
      menu.style.backgroundImage =
        this.displayedElements.generalSettings.overlayColor +
        "url(" +
        this.displayedElements.generalSettings.currentSelectedImage +
        ")";

      this.$store.commit("setMenuBackground", menu.style);
    },
    "displayedElements.generalSettings.flyingCartButtonIconColor": function () {
      this.flyingCartButtonTextStyleProperties = buildStylePropertiesString(
        "color",
        this.displayedElements.generalSettings.flyingCartButtonIconColor,
        this.flyingCartButtonTextStyleProperties
      );
    },
    "displayedElements.generalSettings.flyingCartButtonBackgroundColor":
      function () {
        this.flyingCartButtonElementStyleProperties =
          buildStylePropertiesString(
            "background",
            this.displayedElements.generalSettings
              .flyingCartButtonBackgroundColor,
            this.flyingCartButtonElementStyleProperties
          );
      },

    "displayedElements.generalSettings.callEmployeeButtonIconColor": function () {
      this.callEmployeeButtonTextStyleProperties = buildStylePropertiesString(
        "color",
        this.displayedElements.generalSettings.callEmployeeButtonIconColor,
        this.callEmployeeButtonTextStyleProperties
      );
    },
    "displayedElements.generalSettings.callEmployeeButtonBackgroundColor":
      function () {
        this.callEmployeeButtonElementStyleProperties =
          buildStylePropertiesString(
            "background",
            this.displayedElements.generalSettings
              .callEmployeeButtonBackgroundColor,
            this.callEmployeeButtonElementStyleProperties
          );
      },

    "displayedElements.generalSettings.backButtonTextColor": function () {
      this.backButtonTextStyleProperties = buildStylePropertiesString(
        "color",
        this.displayedElements.generalSettings.backButtonTextColor,
        this.backButtonTextStyleProperties
      );
    },
    "displayedElements.generalSettings.backButtonBackgroundColor": function () {
      this.backButtonElementStyleProperties = buildStylePropertiesString(
        "background",
        this.displayedElements.generalSettings.backButtonBackgroundColor,
        this.backButtonElementStyleProperties
      );
    },
    // "$store.state.backButtonHeight": function () {
    //   this.backButtonElementStyleProperties = buildStylePropertiesString(
    //     "height",
    //     this.$store.state.backButtonHeight + "px",
    //     this.backButtonElementStyleProperties
    //   );
    // },
    // "$store.state.backButtonWidth": function () {
    //   this.backButtonElementStyleProperties = buildStylePropertiesString(
    //     "width",
    //     this.$store.state.backButtonWidth + "px",
    //     this.backButtonElementStyleProperties
    //   );
    // },
    "displayedElements.generalSettings.backButtonBorderRadius": function () {
      this.backButtonElementStyleProperties = buildStylePropertiesString(
        "border-radius",
        this.displayedElements.generalSettings.backButtonBorderRadius + "px",
        this.backButtonElementStyleProperties
      );
    },
    "$store.state.showCartComponent": function () {
      if (!this.$store.state.showCartComponent) {
        this.hideCart();
      }
    },
    "$store.state.showCallEmployeeConfirm": function () {
      if (!this.$store.state.showCallEmployeeConfirm) {
        this.hideCallEmployeeConfirm();
      }
    },
    "$store.state.textElementSettings": function () {

      this.displayedElements.textElementSettings =
        this.$store.state.textElementSettings;

    },

    "$store.state.imageElementSettings": function () {

      this.displayedElements.imageElementSettings =
        this.$store.state.imageElementSettings;


    },
    "$store.state.categoryElementSettings": function () {

      this.displayedElements.categoryElementSettings =
        this.$store.state.categoryElementSettings;


    },
    "$store.state.productElementSettings": function () {

      this.displayedElements.productElementSettings =
        this.$store.state.productElementSettings;


    },
    "$store.state.seperationElementSettings": function () {

      this.displayedElements.seperationElementSettings =
        this.$store.state.seperationElementSettings;


    },
    "$store.state.googleReviewElementSettings": function () {

      this.displayedElements.googleReviewElementSettings =
        this.$store.state.googleReviewElementSettings;


    },
    "$store.state.socialMediaElementSettings": function () {

      this.displayedElements.socialMediaElementSettings =
        this.$store.state.socialMediaElementSettings;


    },
  },

  methods: {
    async checkIfPremiumUser() {
      const userId = getAuth().currentUser.uid;

      try {
        const response = await UserHandler.checkIfPremiumUser(userId);
        this.isPremiumUser = response.data.isPremiumUser;
      } catch (error) {
        this.isPremiumUser = false;
        console.error("Error checking if user is premium: ", error);
      }
    },

    async fetchMenu(id) {
      try {
        const response = await MenuHandler.fetchMenu(id);
        this.displayedElements = response.data;
        this.currentElements.elements = cloneDeep(this.displayedElements.editMenu);
        this.currentMenuDocRef = { id: response.data.id }; // Save the reference for later use

        this.$store.commit(
          "setTextElementSettings",
          this.displayedElements.textElementSettings
        );
        this.$store.commit(
          "setImageElementSettings",
          this.displayedElements.imageElementSettings
        );
        this.$store.commit(
          "setCategoryElementSettings",
          this.displayedElements.categoryElementSettings
        );
        this.$store.commit(
          "setProductElementSettings",
          this.displayedElements.productElementSettings
        );
        this.$store.commit(
          "setSeperationElementSettings",
          this.displayedElements.seperationElementSettings
        );
        this.$store.commit(
          "setGoogleReviewElementSettings",
          this.displayedElements.googleReviewElementSettings
        );
        this.$store.commit(
          "setSocialMediaElementSettings",
          this.displayedElements.socialMediaElementSettings
        );
      } catch (error) {
        console.error("Error fetching menus: ", error);
        this.showToastMessage(ToastTypes.ERROR, "Fehler beim Laden des Menüs.");
      } finally {
        this.isMenuLoading = false;
        setTimeout(() => {
          this.initialLoad = false;
        }, 500);
      }
    },

    debounceSyncEditMenuWithCurrentElements() {
      const now = Date.now();
      if (!this.lastSyncTime || now - this.lastSyncTime > 5000) {
        this.syncEditMenuWithCurrentElements();
        this.lastSyncTime = now;
      }
    },

    syncEditMenuWithCurrentElements() {
      const parentId = this.currentElements.parentId;
      if (!parentId) {
        this.displayedElements.editMenu = cloneDeep(this.currentElements.elements);
      } else {
        const setChildElements = (menuElements) => {
          for (let i = 0; i < menuElements?.length; i++) {
            if (menuElements[i].elementId === parentId) {
              if (!menuElements[i].content) {
                menuElements[i].content = {};
              }
              menuElements[i].content.child = this.currentElements.elements;
              return true;
            }

            if (menuElements[i].content?.child && menuElements[i].content?.child?.length > 0) {
              const found = setChildElements(menuElements[i].content.child);
              if (found) return true;
            }
          }
          return false;
        };

        if (!setChildElements(this.displayedElements.editMenu)) {
          console.error("Parent element not found in the current menu structure.");
        }
      }

      this.syncMenu();
    },



    async syncMenu() {
      if (!this.currentMenuDocRef) {
        return;
      }
      try {
        await MenuHandler.syncMenu(this.menuId, this.displayedElements);
        await this.setupEditMenuBackup();
      } catch (error) {
        console.error("Error updating document: ", error);
        this.showToastMessage(ToastTypes.ERROR, "Fehler beim Speichern des Menüs, bitte lade die Seite neu.");
      }
    },

    async setupEditMenuBackup() {
      const userId = getAuth().currentUser.uid;
      try {
        await MenuHandler.setupEditMenuBackup(this.displayedElements, userId, this.displayedElements.title);
      } catch (error) {
        console.error('Error creating editMenu backup: ', error);
      }
    },

    async backupPublicMenu() {
      const userId = getAuth().currentUser.uid;
      try {
        await MenuHandler.backupPublicMenu(this.displayedElements, userId, this.displayedElements.title);
      } catch (error) {
        console.error('Error creating publicMenu backup: ', error);
      }
    },

    async publishMenu() {
      this.isPublishLoading = true;
      const userId = getAuth().currentUser.uid;
      if (!this.isPremiumUser) {
        this.showToastMessage("Nur Premium-Benutzer können Menüs veröffentlichen.", ToastTypes.ERROR);
        this.isPublishLoading = false;
        return;
      }
      if (!this.currentMenuDocRef) {
        console.error("No document reference stored. Fetch the menu first.");
        return;
      }

      try {
        await MenuHandler.publishMenu(this.menuId, this.displayedElements, userId);
        this.displayedElements.publicMenu = cloneDeep(this.displayedElements.editMenu);
        this.showToastMessage("Die Speisekarte wurde veröffentlicht.", ToastTypes.SUCCESS);
      } catch (error) {
        console.error("Error updating public menu: ", error);
        this.showToastMessage("Fehler beim Veröffentlichen der Speisekarte, bitte versuche es erneut.", ToastTypes.ERROR);
      } finally {
        this.isPublishLoading = false;
      }
    },
    showVersionHistoryDialog() {
      this.hideDialog();
      this.hideElementKit();
      this.showVersionHistory = true;

      const dialog = document.getElementById('versionHistoryWrapper');
      dialog.classList.toggle('slide-in-right');
      dialog.classList.remove('slide-out');
    },
    hideVersionHistoryDialog() {
      this.hideHistoryDialog()
      this.toggleElementKit();
    },
    hideHistoryDialog() {
      this.showVersionHistory = false;

      const dialog = document.getElementById('versionHistoryWrapper');
      dialog.classList.toggle('slide-out');
      dialog.classList.remove('slide-in-right');
    },
    handleDrag() {
      this.syncEditMenuWithCurrentElements();
    },
    showDialog() {
      this.hideGeneralSettingsDialog();
      this.hideElementKit();

      this.showElementDialog = true;

      const dialog = document.getElementById("dialogWrapper");

      if (!dialog.classList.contains("slide-in-right")) {
        dialog.classList.toggle("slide-in-right");
        dialog.classList.remove("slide-out");
      }
    },
    hideDialog() {
      this.toggleElementKit();

      this.showElementDialog = false;

      //this.syncMenu();

      const dialog = document.getElementById("dialogWrapper");
      dialog.classList.toggle("slide-out");
      dialog.classList.remove("slide-in-right");
    },
    hideElementKit() {
      this.showElementKit = false;
      const dialog = document.getElementById("elementKit");

      dialog.classList.toggle("slide-out");
      dialog.classList.remove("slide-in-right");
    },

    toggleElementKit() {
      this.showElementKit = true;
      const dialog = document.getElementById("elementKit");
      dialog.classList.toggle("slide-in-right");
      dialog.classList.remove("slide-out");
    },
    selectDropdownOption(icon, label, type, insertedfrom = undefined) {
      this.hover = undefined;
      this.currentEditElement = undefined;
      this.$store.commit("setCurrentElementMode", type);

      this.selectedDropdownElement.icon = icon;
      this.selectedDropdownElement.label = label;
      this.selectedDropdownElement.elementType = type;

      this.currentEditElement = { elementType: type };
      this.currentEditElement.elementId = uuidv4();
      if (insertedfrom === undefined) {
        this.currentElements.elements.push(this.currentEditElement);
      } else {
        this.currentElements.elements.splice(insertedfrom + 1, 0, this.currentEditElement)
      }
    },

    rotatingAnimation() {
      var icon = document.getElementById("generalSettingsIcon");

      icon.classList.toggle("rotate-center");
      setTimeout(() => icon.classList.remove("rotate-center"), 500);
    },
    showGeneralSettingsDialog() {
      this.hideDialog();
      this.hideElementKit();
      this.hideHistoryDialog()
      this.showGeneralSettings = true;

      const dialog = document.getElementById("generalSettingsDialogWrapper");

      if (!dialog.classList.contains("slide-in-right")) {
        dialog.classList.toggle("slide-in-right");
        dialog.classList.remove("slide-out");
      }

      this.currentElementStyleProperties =
        "background: " +
        this.displayedElements.generalSettings.secondaryColor +
        "; margin-top: 10px; height: 150px; width: 340px; border-radius: 30px; display: flex; align-items: center; justify-content: center;";

      this.currentTextStyleProperties =
        "color: " +
        this.displayedElements.generalSettings.primaryColor +
        "; font-family: " +
        this.displayedElements.generalSettings.currentFont +
        "; font-size: 30pt;";

      this.currentEditElement = { elementType: "generalSettingsPreview" };
    },
    hideGeneralSettingsDialog() {
      this.showGeneralSettings = false;

      this.toggleElementKit();

      const dialog = document.getElementById("generalSettingsDialogWrapper");
      dialog.classList.toggle("slide-out");
      dialog.classList.remove("slide-in-right");
    },
    showElementChildContent(index) {
      this.$store.commit("pushToScreenHistory", {
        elements: this.currentElements.elements,
        parentId: this.currentElements.parentId,
      });
      this.currentScreenLevel++;
      this.currentElements.parentId =
        this.currentElements.elements[index].elementId;
      this.currentElements.elements =
        this.currentElements.elements[index].content.child;

      var menuElements = document.getElementById("menuElements");

      menuElements.classList.toggle("slide-in-right");

      setTimeout(() => menuElements.classList.remove("slide-in-right"), 300);
    },
    showElementParentContent() {
      if (!this.showGeneralSettings) {
        var menuElements = document.getElementById("menuElements");

        menuElements.classList.toggle("slide-in-left");

        setTimeout(() => menuElements.classList.remove("slide-in-left"), 300);

        const lastScreen = this.$store.state.screenHistory[this.$store.state.screenHistory.length - 1];
        this.currentElements.elements = lastScreen.elements;
        this.currentElements.parentId = lastScreen.parentId;
        this.$store.commit("popScreenHistory");
        this.currentScreenLevel--;
      } else {
        this.hideGeneralSettingsDialog();
      }
    },
    addProductToCart(product) {
      this.$store.commit("pushToCart", product);

      const flyingCartButton = document.getElementById("flyingCartButton");
      if (!flyingCartButton.classList.contains("scaleUpAndDown")) {
        flyingCartButton.classList.toggle("scaleUpAndDown");
      }
      setTimeout(() => flyingCartButton.classList.remove("scaleUpAndDown"), 500);
    },
    showCart() {
      this.$store.commit("toggleCart");
      var cartOverlay = document.getElementById("cartOverlay");

      cartOverlay.style.zIndex = 500;

      cartOverlay.classList.toggle("fade-in");
      cartOverlay.classList.remove("fade-out");
    },
    hideCart() {
      var cartOverlay = document.getElementById("cartOverlay");

      cartOverlay.style.zIndex = -100;
      cartOverlay.classList.toggle("fade-out");
      cartOverlay.classList.remove("fade-in");
    },
    showCallEmployeeConfirmComponent() {
      this.$store.commit("toggleCallEmployeeConfirm");
      var callEmployeeConfirmOverlay = document.getElementById("callEmployeeConfirmOverlay");

      callEmployeeConfirmOverlay.style.zIndex = 500;

      callEmployeeConfirmOverlay.classList.toggle("fade-in");
      callEmployeeConfirmOverlay.classList.remove("fade-out");
    },
    hideCallEmployeeConfirm() {
      var callEmployeeConfirmOverlay = document.getElementById("callEmployeeConfirmOverlay");

      callEmployeeConfirmOverlay.style.zIndex = -100;
      callEmployeeConfirmOverlay.classList.toggle("fade-out");
      callEmployeeConfirmOverlay.classList.remove("fade-in");
    },
    deleteElement(elementId) {
      const index = this.currentElements.elements.findIndex(
        (element) => element.elementId === elementId
      );
      if (index !== -1) {
        if (this.currentElements.elements[index]?.content?.image) {
          this.deleteImage(this.currentElements.elements[index].content.image);
        }
        this.currentElements.elements.splice(index, 1);
        this.syncEditMenuWithCurrentElements();
        this.hideDialog();
        //this.syncMenu();
      } else {
        console.error("Element not found");
      }
    },

    editElement(elementId) {
      // Find and edit elements as necessary, considering they might be nested
      const findElementAndEdit = (elements, id) => {
        const element = elements.find((el) => el.elementId === id);
        if (element) {
          this.currentEditElement = element;
          this.showDialog();
          this.$store.commit("setCurrentElementMode", element.elementType);
        } else {
          elements.forEach((el) => {
            if (el.child) {
              findElementAndEdit(el.child, id);
            }
          });
        }
      };

      findElementAndEdit(this.currentElements.elements, elementId);
    },
    copyElement(elementId) {
      const index = this.currentElements.elements.findIndex(
        (element) => element.elementId === elementId
      );
      if (index !== -1) {
        const copiedElement = cloneDeep(this.currentElements.elements[index]);
        copiedElement.elementId = uuidv4();
        this.currentElements.elements.splice(index + 1, 0, copiedElement);
        this.syncEditMenuWithCurrentElements();
        this.hideDialog();
        //this.syncMenu();
      } else {
        console.error("Element not found");
      }
    },

    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === ToastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    deleteImage(image) {
      AssetHandler.deleteImage(image);
    },
    openUrl(url) {
      window.open(url, "_blank");
    },

    insertElement(payload) {
      this.selectDropdownOption(
        payload.icon,
        payload.label,
        payload.elementType,
        payload.index
      );
    },
    restoreEditMenu(version) {
      this.displayedElements.editMenu = version;
      this.currentElements.elements = cloneDeep(this.displayedElements.editMenu);
      this.syncEditMenuWithCurrentElements();
      this.hideVersionHistoryDialog();
    },
    getProductPrice(content) {
      const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
      const now = new Date();
      const currentDay = daysOfWeek[now.getDay()];
      const currentTime = now.toTimeString().slice(0, 5); // Format: HH:MM

      if (!content.dynamicPricingRules) {
        return content.productPrice;
      }

      const dynamicPricingRule = content.dynamicPricingRules[currentDay];
      let dynamicPrice = null;

      if (dynamicPricingRule) {
        const { startTime, endTime, price } = dynamicPricingRule.range;
        if (currentTime >= startTime && currentTime <= endTime) {
          dynamicPrice = price;
        }
      }
      if (!dynamicPrice) {
        return content.productPrice;
      }

      let productPrice = content.productPrice;
      let defaultPrice, currencySign;

      // Check if productPrice is in HTML format or plain text
      const htmlPriceMatch = productPrice.match(/<p><span style="[^"]+">([^<]+)<\/span><\/p>/);
      if (htmlPriceMatch) {
        defaultPrice = htmlPriceMatch[1].match(/[\d.]+/)[0]; // Extract numeric part
        currencySign = htmlPriceMatch[1].replace(defaultPrice, ''); // Extract currency sign
        const newPrice = dynamicPrice !== null ? `${this.displayedElements.generalSettings.pricesToFixedTwoDecimals ? dynamicPrice.toFixed(2) : dynamicPrice}${currencySign}` : htmlPriceMatch[1];
        productPrice = productPrice.replace(htmlPriceMatch[1], newPrice);
      } else {
        const plainTextPriceMatch = productPrice.match(/([\d.]+)(\D+)/);
        if (plainTextPriceMatch) {
          defaultPrice = plainTextPriceMatch[1];
          currencySign = plainTextPriceMatch[2];
          const newPrice = dynamicPrice !== null ? `${this.displayedElements.generalSettings.pricesToFixedTwoDecimals ? dynamicPrice.toFixed(2) : dynamicPrice}${currencySign}` : productPrice;
          productPrice = newPrice;
        }

      }

      return productPrice;
    }
  },
  beforeUnmount() {
    this.syncMenu();
  },
};
</script>

<style lang="scss">
@import "scss/colors.scss";

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;
  position: relative;

  @media (max-width: $tablet) {
    flex-direction: column;
    justify-content: unset;
    padding-top: 2rem;
    height: auto;
  }

  @media (max-width: $mobile) {
    padding: 0 1rem;
    height: auto;
  }

  h1 {
    font-weight: bold;
  }

  .backIcon {
    position: absolute;
    left: 2vw;
    top: 5vh;
    font-size: 2rem;
    color: $green-poison;
    cursor: pointer;
  }

  .menuCreation {
    width: 420px;
    height: 844px;
    max-height: 90vh;
    border: solid 1px $grey-medium !important;
    border-radius: 50px;
    padding: 20px;
    padding-right: 0px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
      0 3px 1px -2px rgb(0 0 0 / 12%) !important;
    position: relative;

    @media (max-width: $mobile) {
      transform: scale(0.8)
    }

    .menuElements {
      height: 800px;
      max-height: 85vh;
      overflow-y: scroll;

      .menuElement {
        position: relative;



        .elementOptions {
          position: absolute;
          bottom: -3rem;
          left: 48%;
          transform: translate(-50%, 0);
        }

        .insertOptions {
          position: absolute;
          bottom: -9rem;
          left: 48%;
          transform: translate(-50%, 0);
        }

        .drag-handle {
          position: absolute;
          top: 50%;
          left: 0;
          cursor: move;
        }
      }
    }

    .cartWrapper {
      width: 420px;
      height: 844px;
      max-height: 90vh;
      border-radius: 50px;
      background: rgb(0 0 0 / 40%);
      right: -1px;
      bottom: 0px;
      position: absolute;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -100;
    }

    .callEmployeeConfirmWrapper {
      width: 420px;
      height: 844px;
      max-height: 90vh;
      border-radius: 50px;
      background: rgb(0 0 0 / 40%);
      right: -1px;
      bottom: 0px;
      position: absolute;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -100;
    }

    .backButton {
      transition: 0.3s;
      border: none;
      position: absolute;
      top: 75vh !important;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%);
      font-weight: bold;
      z-index: 200;

      &:hover {
        cursor: pointer;
        //transform: translate(0px, 3px);
      }

      &:active {
        transform: scale(0.9);
      }
    }

    .flyingCartButton {
      transition: 0.3s;
      border: none;
      position: absolute;
      top: 30%;
      right: 5%;
      // box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
      //   0 3px 1px -2px rgb(0 0 0 / 12%);
      font-weight: bold;
      z-index: 200;
      display: flex;
      align-items: center;
      justify-content: center;

      .numberOfCartItems {
        position: absolute;
        transition: 0.3s;
        top: 20%;
        right: 12%;
        z-index: 300;
        font-weight: bold;
        font-size: 15px;
      }

      &:hover {
        cursor: pointer;
        transform: translate(0px, 3px);
      }

      &:active {
        // transition: all 0.3s ease-in-out;
        transform: scale(0.7);
      }
    }

    .callEmployeeButton {
      transition: 0.3s;
      border: none;
      position: absolute;
      bottom: 150px;
      left: 5%;
      // box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
      //   0 3px 1px -2px rgb(0 0 0 / 12%);
      font-weight: bold;
      z-index: 200;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      margin: 0px;
    }

    .textElementPreview {
      font-size: 30pt;


      p {
        margin: 0px;
      }
    }

    .imageElementPreview {
      display: flex;
      justify-content: center;

      img {
        object-fit: scale-down;
      }
    }

    .categoryElementPreview {
      display: flex;
      justify-content: center;
      font-size: 30pt;

      p {
        margin: 0px;
      }

      img {
        object-fit: cover;
      }
    }

    .productElementPreview {
      display: flex;
      justify-content: center;

      p {
        margin: 0px;
      }

      img {
        object-fit: contain;
      }

      .addToCartButton {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
      }
    }

    .seperationElementPreview {
      display: flex;
      justify-content: center;
    }

    .generalSettingsPreview {
      display: flex;
      justify-content: center;
    }

    .generalProductPreview {
      display: flex;
      justify-content: center;
      margin-top: 150px;

      .addToCartButton {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        border-radius: 360px;
        transition: all 0.1s ease-in-out;
      }
    }

    .textElement {
      font-size: 30pt;

      &:hover {
        border: $green-poison 2px solid;
      }

      p {
        margin: 0px;
      }
    }

    .categoryElement {
      display: flex;
      justify-content: center;
      font-size: 30pt;
      transition: 0.3s;

      &:hover {
        border: $green-poison 2px solid;
      }

      p {
        margin: 0px;
      }

      &:hover {
        cursor: pointer;
      }

      img {
        object-fit: cover;
      }

      &:active {
        transform: scale(0.9);
      }
    }

    .imageElement {
      display: flex;
      justify-content: center;

      &:hover {
        border: $green-poison 2px solid;
      }

      img {
        object-fit: scale-down;
      }
    }

    .productElement {
      display: flex;
      justify-content: center;

      &:hover {
        border: $green-poison 2px solid;
      }

      p {
        margin: 0px;
      }

      img {
        object-fit: contain;
      }

      .addToCartButton {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        border-radius: 360px;
        transition: all 0.1s ease-in-out;

        &:hover {
          cursor: pointer;
        }

        &:active {
          transform: scale(0.8);
        }
      }
    }

    .seperationElement {
      display: flex;
      justify-content: center;

      &:hover {
        border: $green-poison 2px solid;
      }
    }

    p {
      max-height: 30pt !important;
      margin: 0 !important;
    }
  }
}

.dialogWrapper {
  position: fixed;
  margin-right: -5000px;

  margin-bottom: 36px;
  margin-left: 200px;

  @media (max-width: $tablet) {
    margin-left: 0;
    margin-top: 2rem;
  }

  @media (max-width: $mobile) {
    transform: scale(0.8);
    width: 100vw;
    margin-top: 2rem;
  }

  width: 490px;
  height: 844px;
  max-height: 90vh;
  border: solid 1px $grey-medium;
  border-radius: 20px;
  overflow-y: scroll;
  padding: 20px;
  padding-right: 0px;

  box-shadow: 0 1px 5px rgb(0 0 0 / 20%),
  0 2px 2px rgb(0 0 0 / 14%),
  0 3px 1px -2px rgb(0 0 0 / 12%);

  background-color: white;

  display: flex;
  justify-content: flex-start;

  label {
    margin: 10px;
    font-weight: bold;
  }

  input {
    height: 22px;
    width: 35px;
  }

  .dialog {
    width: 490px;

    @media (max-width: $mobile) {
      width: 88vw;
    }

    .top {
      display: flex;
      justify-content: right;

      .icon {
        font-size: large;
        font-weight: bold;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .head {
      display: flex;
      justify-content: flex-start;
      padding-right: 20px;

      .dropdown {
        width: 200px;

        .block {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }

    .body {
      padding: 2rem 0;
      display: flex;
      flex-direction: column;

      h6 {
        margin-top: 0px;
        margin-bottom: 15px;
      }

      label {
        margin: 0px;
      }

      input {
        width: 50px;
        font-family: Roboto;
        border-radius: 7px;
        border: solid 1px rgb(201, 201, 201);
        margin-left: 5px;

        transition: 0.3s;

        &:hover {
          border: solid 1px $grey-medium;
        }
      }
    }
  }
}

.generalSettingsDialogWrapper {
  position: fixed;
  margin-right: -5000px;

  margin-bottom: 36px;
  margin-left: 200px;

  @media (max-width: $tablet) {
    margin-left: 0;
    margin-top: 2rem;
  }

  @media (max-width: $mobile) {
    transform: scale(0.8);

  }

  .closeIconWrapper {
    width: 490px;


    @media (max-width: $mobile) {
      width: 100vw;
      right: auto;
      left: 50px;
      top: 50px;
      z-index: 1000;
    }

    font-size: large;
    font-weight: bold;
    position: fixed;

    display: flex;
    justify-content: flex-end;

    right: 20px;
    top: 20px;

    .closeIcon {
      font-weight: bold;
      font-size: large;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.versionHistoryWrapper {
  position: fixed;
  margin-right: -5000px;

  margin-bottom: 36px;
  margin-left: 200px;

  @media (max-width: $tablet) {
    margin-left: 0;
    margin-top: 2rem;
  }

  @media (max-width: $mobile) {
    transform: scale(0.8);
  }

  .closeIconWrapper {
    width: 490px;

    @media (max-width: $mobile) {
      width: 100vw;
      right: auto;
      left: 50px;
      top: 40px;
    }

    font-size: large;
    font-weight: bold;
    position: fixed;

    display: flex;
    justify-content: flex-end;

    right: 20px;
    top: 20px;

    .closeIcon {
      font-weight: bold;
      font-size: large;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.generalSettingsButtonWrapper {
  margin-top: 10px;

  @media (max-width: $mobile) {
    margin-top: -50px;
  }

  display: flex;
  justify-content: center;

  .generalSettingsButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .settingsIcon {
      font-size: 20pt;
      color: $grey-medium;
    }

    span {
      font-family: Roboto;
      font-size: 12pt;
      font-weight: 500;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.elementKitWrapper {
  height: 844px;
  max-height: 90vh;

  @media (max-width: $mobile) {
    transform: scale(0.8);
    margin-top: -3rem;
  }

  .elementKit {
    margin-left: 200px;

    @media (max-width: $tablet) {
      margin-left: 0;
      margin-top: 2rem;

    }



    width: 420px;
    padding: 2rem 0;
    border: solid 1px $grey-medium;
    border-radius: 50px;
    // overflow-y: scroll;
    // padding: 0;

    box-shadow: 0 1px 5px rgb(0 0 0 / 20%),
    0 2px 2px rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%);

    background-color: white;

    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    .col1 {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    .col2 {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    .col3 {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    .buttonContent {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .publishButtonWrapper {
    margin-left: 200px;

    @media (max-width: $tablet) {
      margin-left: 0;
      margin-bottom: 2rem;
    }

    @media (max-width: $mobile) {}

    margin-top: 1rem;
    color: $green-poison;
    display: flex;
    justify-content: center;

    .publishButton {
      cursor: pointer;
      transition: 0.3s ease;
      border-radius: 25px;
      width: fit-content;
      padding: calc(0.25rem + 5px) 1rem;
      position: relative;

      &:hover {
        background-color: $grey-lighter;
      }

      font-size: 1.25rem;
      font-weight: bold;

      .icon {
        font-size: 2rem;
        margin-bottom: 5px;
      }

      .spinner {
        position: absolute;
        right: -10%;
        top: 50%;
        transform: translate(0, -50%) !important;
      }
    }
  }


}

.slide-in-left {
  -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  position: relative;
  margin-right: 0px;
  animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;


}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-out {
  transition: 0.3s;
  position: fixed;
  margin-right: -5000px;
  animation: slide-out-right 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  @media (max-width: $tablet) {
    margin-left: 5000px !important;
    margin-top: 2rem;
  }

}

@keyframes slide-out {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}

.rotate-center {
  animation: rotate-center 0.6s ease-in-out both;
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fade-out 0.3s ease-out both;
}

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.q-icon {
  margin-left: 0 !important;
}

.spinnerWrapperMenu {
  width: 420px;
  height: 700px;
  margin-left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.googleReviewButton {
  display: flex;
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%);
}

.scaleUpAndDown {
  animation: scale-up-and-down 0.3s infinite;
}

@keyframes scale-up-and-down {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
