import CommonAPIProvider from "@/common/CommonAPIProvider";

const baseUrl = process.env.VUE_APP_API_BASE;
class UserHandler {
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }

  async checkOrCreate(userId, email) {
    return CommonAPIProvider.sendPostRequest(
      `${baseUrl}/users/checkOrCreate`,
      { userId: userId, email: email },
      {}
    );
  }
  async register(userId, email) {
    return CommonAPIProvider.sendPostRequest(
      `${baseUrl}/users/register`,
      { userId: userId, email: email },
      {}
    )
  }
  async checkIfPremiumUser(userId) {
    const idToken = await CommonAPIProvider.getIdToken();

    return CommonAPIProvider.sendGetRequest(
      `${baseUrl}/users/${userId}/premium`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      },
    )
  }

}

export default new UserHandler();
