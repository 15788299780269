<template>
  <input
    :class="passedClass"
    :type="type"
    :placeholder="placeholder"
    :value="modelValue"
    @input="updateValue"
  />
</template>

<script>
export default {
  props: {
    passedClass: String,
    placeholder: String,
    type: String,
    modelValue: String
  },
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.inputValue = newValue;
    },
  },
  methods: {
    updateValue(event) {
      this.inputValue = event.target.value;
      this.$emit('update:modelValue', this.inputValue);
    },
  },
};
</script>

<style lang="scss">
@import "scss/colors.scss";

.inp {
  border-radius: 10px;
  border: 1px solid $grey-medium;
  padding-left: 10px;
  transition: 0.3s ease;

  &-search {
    width: 350px;
    height: 40px;
  }

  &:focus {
    border: 1px solid $grey-dark;
    outline: none;
  }
  &:hover {
    border: 1px solid $grey-dark;
  }
}

.login {
  font-size: 1rem;
  padding: 0.5rem;
  min-width: 22vw;
}
</style>
