import axios from "axios";
import { getAuth } from "firebase/auth";

class CommonAPIProvider {
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }

  async sendGetRequest(endpoint, headers = {}) {
    try {
      const response = await axios.get(endpoint, headers);
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(e.message);
    }
  }
  async sendPostRequest(endpoint, data, config = {}) {
    try {
      const response = await axios.post(endpoint, data, {
        ...config,
        headers: { ...config.headers },
      });
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(e.message);
    }
  }
  async sendDeleteRequest(endpoint, headers = {}) {
    try {
      const response = await axios.delete(endpoint, { headers });
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async getIdToken() {
    return await getAuth().currentUser.getIdToken();
  }
}
export default new CommonAPIProvider();
