<template>
  <div class="pixabaySearch">
    <div class="searchBar">
      <MyInput
        class="inp inp-search"
        placeholder="Nach Bildern suchen..."
        id="searchbarInput"
        @keyup.enter="getPixabayImages()"
      />
      <QIcon
        name="search"
        class="searchIcon"
        @click="getPixabayImages()"
      ></QIcon>
    </div>
    <div class="resultContainer">
      <div v-if="this.searchResults.length > 0">
        <div
          class="backgroundResults"
          v-for="(result, index) in searchResults"
          :key="index"
        >
          <img
            draggable="false"
            :src="result.webformatURL"
            @click="urlToObject(result.webformatURL)"
          />
          <img
            style="margin-left: 20px"
            v-if="searchResults[index + 1]"
            draggable="false"
            :src="searchResults[index + 1].webformatURL"
            @click="urlToObject(searchResults[index + 1].webformatURL)"
          />
        </div>
      </div>
      <div v-else-if="isLoading || isImageUploadLoading" class="loading">
        <QSpinner color="light-green" size="3em" />
      </div>
      <div v-else-if="noResultsFound" class="noResultsFound">
        <QIcon name="sentiment_dissatisfied" class="searchIcon"></QIcon>
        <span>Keine Bilder gefunden</span>
      </div>
      <div v-else class="pending">
        <QIcon name="hourglass_empty" class="searchIcon"></QIcon>
        <span>Warte auf suche...</span>
      </div>
    </div>
    <div class="footer">
      <span>PixaBay Free Images</span>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import MyInput from "../shared/MyInput.vue";
import { QIcon, QSpinner } from "quasar";
const translate = require("deepl");

export default {
  components: { MyInput, QIcon, QSpinner },
  props: {
    isImageUploadLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pixabayAPI:
        "https://pixabay.com/api/?key=" + process.env.VUE_APP_PIXABAY_API_KEY,

      searchResults: [],
      image: null,
      isLoading: false,
      noResultsFound: false,
    };
  },

  methods: {
    async getPixabayImages() {
      this.searchResults = [];

      let searchText = document.getElementById("searchbarInput").value;

      this.isLoading = true;

      translate({
        free_api: true,
        text: searchText,
        target_lang: "EN",
        auth_key: process.env.VUE_APP_DEEPL_API_KEY,
        // All optional parameters available in the official documentation can be defined here as well.
      })
        .then((result) => {
          const query = result.data.translations[0].text
            ? `&q=${result.data.translations[0].text}`
            : ``;
          axios
            .get(
              this.pixabayAPI +
                query +
                "&per_page=200" +
                "&orientation=vertical"
            )
            .then((response) => {
              // Assigning server response data to images
              this.searchResults = response.data.hits;
              if (this.searchResults.length == 0) {
                this.noResultsFound = true;
              } else {
                this.noResultsFound = false;
              }
              this.isLoading = false;
            })
            .catch((error) => {
              console.log(error);
              this.isLoading = false;
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async urlToObject(url) {
      const response = await fetch(url);
      // here image is url/location of image
      const blob = await response.blob();
      const file = new File([blob], "image.jpg", { type: blob.type });

      this.image = file;
      this.$emit("imageSelected", this.image);
    },
  },
};
</script>
<style lang="scss">
@import "scss/colors.scss";

.pixabaySearch {
  height: 497px;
  width: 465px;

  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%);
  border: solid 1px $grey-medium;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .searchBar {
    width: 400px;
    margin-top: 20px;

    .searchIcon {
      font-size: x-large;
      margin-left: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .resultContainer {
    border: solid 1px rgb(201, 201, 201);
    border-radius: 10px;
    margin-top: 20px;
    height: 370px;
    width: 400px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .noResultsFound {
      height: 370px;
      width: 370px;
      font-size: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;

      span {
        font-size: 20px;
      }
    }

    .pending {
      height: 370px;
      width: 370px;
      font-size: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;

      span {
        font-size: 20px;
      }
    }
    .loading {
      height: 370px;
      width: 370px;
      // font-size: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;

      span {
        font-size: 20px;
      }
    }

    .backgroundResults {
      img {
        margin-top: 10px;
        width: 150px;
        height: 324px;
        object-fit: cover;
        border-radius: 30px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    overflow-y: scroll;
  }
  .footer {
    width: 400px;
    display: flex;
    justify-content: flex-end;

    span {
      margin-top: 10px;
    }
  }
}
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $grey-medium;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $grey-medium;
}
</style>
