<template>
    <div v-if="show">
        <div class="googleElementBody">
            <div>
                <div style="text-align: center">
                    <MyTextEditor v-model="localEditElement.content.firstText"
                        :defaultFontSize="12" :editorHeight="80"
                        :generalSettings="generalSettings">
                    </MyTextEditor>
                </div>
                <div style="margin-top: 20px">
                    <h6>Textabstand</h6>
                </div>
                <div>
                    <label>Oben: </label>
                    <input type="number" min="0" max="500"
                        v-model="localEditElement.firstTextStyleValues.marginTop"
                        @input="
        buildFirstTextStylePropertiesString(
            'margin-top',
            localEditElement.firstTextStyleValues.marginTop + 'px'
        )
        " />
                    <label style="margin-left: 10px">Unten: </label>
                    <input type="number" min="0" max="500"
                        v-model="localEditElement.firstTextStyleValues.marginBottom"
                        @input="
        buildFirstTextStylePropertiesString(
            'margin-bottom',
            localEditElement.firstTextStyleValues.marginBottom + 'px'
        )
        " />
                    <label style="margin-left: 10px">Links: </label>
                    <input type="number" min="0" max="500"
                        v-model="localEditElement.firstTextStyleValues.marginLeft"
                        @input="
        buildFirstTextStylePropertiesString(
            'margin-left',
            localEditElement.firstTextStyleValues.marginLeft + 'px'
        )
        " />
                    <label style="margin-left: 10px">Rechts: </label>
                    <input type="number" min="0" max="500"
                        v-model="localEditElement.firstTextStyleValues.marginRight"
                        @input="
        buildFirstTextStylePropertiesString(
            'margin-right',
            localEditElement.firstTextStyleValues.marginRight + 'px'
        )
        " />
                </div>
            </div>
            <div>
                <div style="text-align: center">
                    <MyTextEditor v-model="localEditElement.content.secondText"
                        :defaultFontSize="12" :editorHeight="80"
                        :generalSettings="generalSettings">
                    </MyTextEditor>
                </div>
                <div style="margin-top: 20px">
                    <h6>Textabstand</h6>
                </div>
                <div>
                    <label>Oben: </label>
                    <input type="number" min="0" max="500"
                        v-model="localEditElement.secondTextStyleValues.marginTop"
                        @input="
        buildSecondTextStylePropertiesString(
            'margin-top',
            localEditElement.secondTextStyleValues.marginTop + 'px'
        )
        " />
                    <label style="margin-left: 10px">Unten: </label>
                    <input type="number" min="0" max="500"
                        v-model="localEditElement.secondTextStyleValues.marginBottom"
                        @input="
        buildSecondTextStylePropertiesString(
            'margin-bottom',
            localEditElement.secondTextStyleValues.marginBottom + 'px'
        )
        " />
                    <label style="margin-left: 10px">Links: </label>
                    <input type="number" min="0" max="500"
                        v-model="localEditElement.secondTextStyleValues.marginLeft"
                        @input="
        buildSecondTextStylePropertiesString(
            'margin-left',
            localEditElement.secondTextStyleValues.marginLeft + 'px'
        )
        " />
                    <label style="margin-left: 10px">Rechts: </label>
                    <input type="number" min="0" max="500"
                        v-model="localEditElement.secondTextStyleValues.marginRight"
                        @input="
        buildSecondTextStylePropertiesString(
            'margin-right',
            localEditElement.secondTextStyleValues.marginRight + 'px'
        )
        " />
                </div>
            </div>
            <div style="margin-top: 20px">
                <h6>Elementabstand</h6>
            </div>

            <div>
                <label>Oben: </label>
                <input type="number" min="0" max="500"
                    v-model="localEditElement.elementStyleValues.marginTop"
                    @input="
        buildElementStylePropertiesString(
            'margin-top',
            localEditElement.elementStyleValues.marginTop + 'px'
        )
        " />
                <label style="margin-left: 10px">Unten: </label>
                <input type="number" min="0" max="500"
                    v-model="localEditElement.elementStyleValues.marginBottom"
                    @input="
        buildElementStylePropertiesString(
            'margin-bottom',
            localEditElement.elementStyleValues.marginBottom + 'px'
        )
        " />
                <label style="margin-left: 10px">Links: </label>
                <input type="number" min="0" max="500"
                    v-model="localEditElement.elementStyleValues.marginLeft"
                    @input="
        buildElementStylePropertiesString(
            'margin-left',
            localEditElement.elementStyleValues.marginLeft + 'px'
        )
        " />
                <label style="margin-left: 10px">Rechts: </label>
                <input type="number" min="0" max="500"
                    v-model="localEditElement.elementStyleValues.marginRight"
                    @input="
        buildElementStylePropertiesString(
            'margin-right',
            localEditElement.elementStyleValues.marginRight + 'px'
        )
        " />
            </div>
            <div style="margin-top: 20px">
                <h6>Google Button</h6>
            </div>
            <div style="display: flex; ">
                <label>Primärfarbe: </label>
                <div class="currentColor"
                    :style="{ background: localEditElement.buttonStyleValues.primaryColor }">
                    <QPopupProxy cover transition-show="scale"
                        transition-hide="scale" style="margin-left: 10px">
                        <QColor
                            v-model="localEditElement.buttonStyleValues.primaryColor" />
                    </QPopupProxy>
                </div>
                <label style="margin-left: 20px">Sekundärfarbe: </label>
                <div class="currentColor"
                    :style="{ background: localEditElement.buttonStyleValues.secondaryColor }">
                    <QPopupProxy cover transition-show="scale"
                        transition-hide="scale" style="margin-left: 10px">
                        <QColor
                            v-model="localEditElement.buttonStyleValues.secondaryColor"
                            @update:model-value="buildButtonStylePropertiesString(
        'background-color',
        localEditElement.buttonStyleValues.secondaryColor

    )" />
                    </QPopupProxy>
                </div>
            </div>
            <div style="margin-top: 1rem; ">
                <label>Google link: </label>
                <input type="url" v-model="localEditElement.content.googleLink"
                    style="width: 70%;" />
            </div>
            <div class="saveTextElementBtn">
                <MyButton buttonStyle="btn btn-medium"
                    @click="saveTextElement()">
                    Speichern</MyButton>
            </div>
        </div>
    </div>
</template>
<script>
import MyButton from "../shared/MyButton.vue";
import MyTextEditor from "../shared/MyTextEditor.vue";
import buildStylePropertiesString from "../../stylePropertyStringBuilder";
import { QColor, QPopupProxy } from "quasar";
//import { v4 as uuidv4 } from "uuid";
//import cloneDeep from "lodash/cloneDeep";
export default {
    components: {
        MyButton,
        MyTextEditor,
        QColor,
        QPopupProxy
    },

    props: {
        show: {
            type: Boolean,
            default: false,
        },

        currentEditElement: {
            type: Object,
            default: null,
        },
        generalSettings: {
            type: Object,
            default: null,
        },
    },

    created() {
        if (this.$store.state.currentElementMode == "googleReview") {
            this.assignProperties();
        }
    },

    data() {
        return {
            localEditElement: {
                initialized: true,
                content: {
                    firstText: "Gib uns 5 Sterne auf ",
                    secondText: "und erhalte 1x gratis [PRODUKT]",
                    googleLink: ""
                },
                firstTextStyleValues: {
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                },
                secondTextStyleValues: {
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                },
                elementStyleProperties: "",
                elementStyleValues: {
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                },
                buttonStyleValues: {
                    primaryColor: this.generalSettings.primaryColor
                        ? this.generalSettings.primaryColor
                        : "#000000",
                    secondaryColor: this.generalSettings.secondaryColor
                        ? this.generalSettings.secondaryColor
                        : "#edede9",
                },
                firstTextstyleProperties: "",
                secondTextstyleProperties: "",
                buttonStyleProperties: "",
            },
        };
    },

    watch: {
        "currentEditElement.elementId": {
            handler: function (newValue, oldValue) {
                if (
                    this.$store.state.currentElementMode == "googleReview" &&
                    oldValue !== newValue
                ) {
                    this.localEditElement = {
                        initialized: true,

                        content: {
                            firstText: "Gib uns 5 Sterne auf ",
                            secondText: "und erhalte 1x gratis [PRODUKT]",
                            googleLink: ""

                        },

                        firstTextStyleValues: {
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                        },
                        secondTextStyleValues: {
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                        }, elementStyleProperties: "",
                        elementStyleValues: {
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                        }, buttonStyleValues: {
                            primaryColor: this.generalSettings.primaryColor
                                ? this.generalSettings.primaryColor
                                : "#000000",
                            secondaryColor: this.generalSettings.secondaryColor
                                ? this.generalSettings.secondaryColor
                                : "#edede9",
                        },
                        firstTextstyleProperties: "",
                        secondTextstyleProperties: "",
                        buttonStyleProperties: "",
                    };
                    this.assignProperties();
                }
            },
            deep: true,
        },
        localEditElement: {
            handler: function () {
                if (this.localEditElement) {
                    this.$emit("update:currentEditElement", this.localEditElement);
                }
            },
            deep: true,
        },

        "generalSettings.primaryColor": function () {
            this.buildFirstTextStylePropertiesString(
                "color",
                this.generalSettings.primaryColor
            );
            this.buildSecondTextStylePropertiesString(
                "color",
                this.generalSettings.primaryColor
            );
            this.localEditElement.buttonStyleValues.primaryColor = this.generalSettings.primaryColor;
        },
        "generalSettings.secondaryColor": function () {
            this.buildButtonStylePropertiesString(
                "background-color",
                this.generalSettings.secondaryColor
            );
        },
        "generalSettings.currentFont": function () {
            this.buildFirstTextStylePropertiesString(
                "font-family",
                this.generalSettings.currentFont
            );
            this.buildSecondTextStylePropertiesString(
                "font-family",
                this.generalSettings.currentFont
            );
        },
    },

    methods: {
        assignProperties() {
            var googleReviewUserSettings = this.$store.state.googleReviewElementSettings;
            this.localEditElement.elementId = this.currentEditElement.elementId;
            this.localEditElement.elementType = this.currentEditElement.elementType;
            this.localEditElement.initialized = true;
            this.localEditElement.content.firstText = this.currentEditElement.content?.firstText
                ? this.currentEditElement.content.firstText
                : "Gib uns 5 Sterne auf ";
            this.localEditElement.content.secondText = this.currentEditElement.content?.secondText
                ? this.currentEditElement.content.secondText
                : "und erhalte 1x gratis [PRODUKT]";

            this.localEditElement.content.googleLink = this.currentEditElement.content?.googleLink
                ? this.currentEditElement.content.googleLink
                : "";
            if (JSON.stringify(googleReviewUserSettings) !== "{}") {
                this.localEditElement.firstTextstyleProperties = googleReviewUserSettings.firstTextStyle;
                this.localEditElement.firstTextStyleValues.marginTop =
                    googleReviewUserSettings.firstTextStyleValues.marginTop;
                this.localEditElement.firstTextStyleValues.marginBottom =
                    googleReviewUserSettings.firstTextStyleValues.marginBottom;
                this.localEditElement.firstTextStyleValues.marginLeft =
                    googleReviewUserSettings.firstTextStyleValues.marginLeft;
                this.localEditElement.firstTextStyleValues.marginRight =
                    googleReviewUserSettings.firstTextStyleValues.marginRight;

                this.localEditElement.secondTextstyleProperties = googleReviewUserSettings.secondTextStyle;
                this.localEditElement.secondTextStyleValues.marginTop =
                    googleReviewUserSettings.secondTextStyleValues.marginTop;
                this.localEditElement.secondTextStyleValues.marginBottom =
                    googleReviewUserSettings.secondTextStyleValues.marginBottom;
                this.localEditElement.secondTextStyleValues.marginLeft =
                    googleReviewUserSettings.secondTextStyleValues.marginLeft;
                this.localEditElement.secondTextStyleValues.marginRight =
                    googleReviewUserSettings.secondTextStyleValues.marginRight;

                this.localEditElement.elementStyleProperties = googleReviewUserSettings.elementStyle;
                this.localEditElement.elementStyleValues.marginTop =
                    googleReviewUserSettings.elementStyleValues.marginTop;
                this.localEditElement.elementStyleValues.marginBottom =
                    googleReviewUserSettings.elementStyleValues.marginBottom;
                this.localEditElement.elementStyleValues.marginLeft =
                    googleReviewUserSettings.elementStyleValues.marginLeft;
                this.localEditElement.elementStyleValues.marginRight =
                    googleReviewUserSettings.elementStyleValues.marginRight;

                this.localEditElement.buttonStyleProperties = googleReviewUserSettings.buttonStyle;
                this.localEditElement.buttonStyleValues.primaryColor =
                    googleReviewUserSettings.buttonStyleValues.primaryColor;
                this.localEditElement.buttonStyleValues.secondaryColor =
                    googleReviewUserSettings.buttonStyleValues.secondaryColor;

            } else {
                this.localEditElement.firstTextstyleProperties =
                    "color: " +
                    (this.generalSettings.primaryColor
                        ? this.generalSettings.primaryColor
                        : "#000000") +
                    "; font-family: " +
                    (this.generalSettings.currentFont
                        ? this.generalSettings.currentFont
                        : "Roboto") +
                    "; font-size: 12pt; font-weight: bold;";
                this.localEditElement.secondTextstyleProperties =
                    "color: " +
                    (this.generalSettings.primaryColor
                        ? this.generalSettings.primaryColor
                        : "#000000") +
                    "; font-family: " +
                    (this.generalSettings.currentFont
                        ? this.generalSettings.currentFont
                        : "Roboto") +
                    "; font-size: 12pt; font-weight: bold;";

                this.localEditElement.buttonStyleProperties =
                    "background-color: " +
                    (this.generalSettings.secondaryColor
                        ? this.generalSettings.secondaryColor
                        : "#edede0") +
                    "; color: " +
                    (this.generalSettings.primaryColor
                        ? this.generalSettings.primaryColor
                        : "#000000") +
                    "; border-radius: 15px; padding: 0.5rem 1rem; border: none;";

                this.$nextTick(() => {
                    this.buildFirstTextStylePropertiesString("margin-top", 0 + "px");
                    this.buildFirstTextStylePropertiesString("margin-bottom", 0 + "px");
                    this.buildFirstTextStylePropertiesString("margin-right", 0 + "px");
                    this.buildFirstTextStylePropertiesString("margin-left", 0 + "px");
                    this.localEditElement.firstTextStyleValues.marginTop = 0;
                    this.localEditElement.firstTextStyleValues.marginBottom = 0;
                    this.localEditElement.firstTextStyleValues.marginLeft = 0;
                    this.localEditElement.firstTextStyleValues.marginRight = 0;

                    this.buildSecondTextStylePropertiesString("margin-top", 0 + "px");
                    this.buildSecondTextStylePropertiesString("margin-bottom", 0 + "px");
                    this.buildSecondTextStylePropertiesString("margin-right", 0 + "px");
                    this.buildSecondTextStylePropertiesString("margin-left", 0 + "px");
                    this.localEditElement.secondTextStyleValues.marginTop = 0;
                    this.localEditElement.secondTextStyleValues.marginBottom = 0;
                    this.localEditElement.secondTextStyleValues.marginLeft = 0;
                    this.localEditElement.secondTextStyleValues.marginRight = 0;


                    this.buildElementStylePropertiesString("margin-top", 30 + "px");
                    this.buildElementStylePropertiesString("margin-bottom", 0 + "px");
                    this.buildElementStylePropertiesString("margin-right", 0 + "px");
                    this.buildElementStylePropertiesString("margin-left", 0 + "px");
                    this.localEditElement.elementStyleValues.marginTop = 30;
                    this.localEditElement.elementStyleValues.marginBottom = 0;
                    this.localEditElement.elementStyleValues.marginLeft = 0;
                    this.localEditElement.elementStyleValues.marginRight = 0;
                    //this.$emit("updateTextStyle", this.currentTextStyleProperties);
                });
            }
        },
        buildFirstTextStylePropertiesString(styleProperty, value) {
            this.localEditElement.firstTextstyleProperties = buildStylePropertiesString(
                styleProperty,
                value,
                this.localEditElement.firstTextstyleProperties
            );

            this.$emit("update:currentEditElement", this.localEditElement);
        },
        buildSecondTextStylePropertiesString(styleProperty, value) {
            this.localEditElement.secondTextstyleProperties = buildStylePropertiesString(
                styleProperty,
                value,
                this.localEditElement.secondTextstyleProperties
            );

            this.$emit("update:currentEditElement", this.localEditElement);
        },
        buildElementStylePropertiesString(styleProperty, value) {
            this.localEditElement.elementStyleProperties = buildStylePropertiesString(
                styleProperty,
                value,
                this.localEditElement.elementStyleProperties
            );

            this.$emit("update:currentEditElement", this.localEditElement);
        },
        buildButtonStylePropertiesString(styleProperty, value) {
            this.localEditElement.buttonStyleProperties = buildStylePropertiesString(
                styleProperty,
                value,
                this.localEditElement.buttonStyleProperties
            );

            this.$emit("update:currentEditElement", this.localEditElement);
        },
        saveTextElement() {
            const googleReviewElementSettings = {
                firstTextStyle: this.localEditElement.firstTextstyleProperties,
                firstTextStyleValues: {
                    marginTop: this.localEditElement.firstTextStyleValues.marginTop,
                    marginBottom: this.localEditElement.firstTextStyleValues.marginBottom,
                    marginLeft: this.localEditElement.firstTextStyleValues.marginLeft,
                    marginRight: this.localEditElement.firstTextStyleValues.marginRight,
                },
                secondTextStyle: this.localEditElement.secondTextstyleProperties,
                secondTextStyleValues: {
                    marginTop: this.localEditElement.secondTextStyleValues.marginTop,
                    marginBottom: this.localEditElement.secondTextStyleValues.marginBottom,
                    marginLeft: this.localEditElement.secondTextStyleValues.marginLeft,
                    marginRight: this.localEditElement.secondTextStyleValues.marginRight,
                },
                elementStyle: this.localEditElement.elementStyleProperties,
                elementStyleValues: {
                    marginTop: this.localEditElement.elementStyleValues.marginTop,
                    marginBottom: this.localEditElement.elementStyleValues.marginBottom,
                    marginLeft: this.localEditElement.elementStyleValues.marginLeft,
                    marginRight: this.localEditElement.elementStyleValues.marginRight,
                },
                buttonStyle: this.localEditElement.buttonStyleProperties,
                buttonStyleValues: {
                    primaryColor: this.localEditElement.buttonStyleValues.primaryColor,
                    secondaryColor: this.localEditElement.buttonStyleValues.secondaryColor,
                },
            };

            this.$store.commit("setGoogleReviewElementSettings", googleReviewElementSettings);
            this.$emit("hideDialog");
        },
    },
};
</script>
<style lang="scss">
.googleElementBody {
    text-align: left;

    //   padding-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .currentColor {
        height: 17px;
        width: 23px;
        margin-left: 11px;
        margin-top: 3px;
        border-radius: 5px;
        border: solid 1px black;

        &:hover {
            cursor: pointer;
        }
    }

    .saveTextElementBtn {
        display: flex;
        justify-content: flex-end;
    }
}
</style>