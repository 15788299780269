import CommonAPIProvider from "@/common/CommonAPIProvider";

const baseUrl = process.env.VUE_APP_ASSET_HANDLER_BASE;
class AssetHandler {
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }

  async uploadImage(image, menuId) {
    return CommonAPIProvider.sendPostRequest(
      `${baseUrl}/assets/image/upload/${menuId}`,
      image,
      {}
    );
  }
  async deleteImage(imageUrl) {
    return imageUrl/* CommonAPIProvider.sendPostRequest(
      `${baseUrl}/assets/image/delete`,
      { imageUrl: imageUrl },
      {}
    ); */
  }
  async generateQRCodes(menuUrl) {
    return CommonAPIProvider.sendPostRequest(
      `${baseUrl}/assets/qr/generate`,
      { baseURL: menuUrl },
      { responseType: 'blob' } // Set responseType to 'blob'
    );
  }
}

export default new AssetHandler();
