<template>
    <div class="report-details-wrapper">
        <q-icon name="arrow_back" class="backIcon"
            @click="$router.go(-1)"></q-icon>
        <div class="report-details">
            <h3 class="reportDetailsHeadline">{{
                convertDateToEuropeanFormat(reportId) }}</h3>
            <div v-if="report">
                <table class="product-table">
                    <thead>
                        <tr>
                            <th>Produkt</th>
                            <th>Menge</th>
                            <th>Preis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product, title) in aggregatedProducts"
                            :key="title">
                            <td>{{ title }}</td>
                            <td>{{ product.quantity }}</td>
                            <td>{{ product.totalPrice.toFixed(2) }}€</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="2">Gesamt</th>
                            <th>{{ totalSum.toFixed(2) }}€</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="spinnerWrapper" v-else>
                <QSpinner color="light-green" size="3rem" />
            </div>

        </div>
        <div class="deleteWrapper">
            <button class="delete-button" @click="confirmDeletion">Report
                Löschen</button>
        </div>
        <q-dialog v-model="isDialogOpen">
            <q-card>
                <q-card-section>
                    <div class="text-h6">Report löschen</div>
                    <div>Diesen Report entgültig löschen?</div>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="ABBRECHEN" color="light-green"
                        v-close-popup />
                    <q-btn flat label="LÖSCHEN" color="negative"
                        @click="deleteReport" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>


<script>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { QSpinner, QDialog } from "quasar";
import router from "@/router";
import ReportsHandler from "@/util/ReportsHandler";

export default {
    components: {
        QSpinner,
        QDialog
    },
    setup() {
        const route = useRoute();
        const report = ref(null);
        const reportId = ref("");
        const isDialogOpen = ref(false);
        const menuId = ref("");

        const confirmDeletion = () => {
            isDialogOpen.value = true;
        };

        const fetchReportDetails = async () => {
            const uid = route.params.menuId;
            menuId.value = uid;
            const docName = route.params.reportId;
            reportId.value = docName;

            try {
                const response = await ReportsHandler.fetchReportDetails(uid, docName);
                report.value = response.data;
            } catch (error) {
                console.error("Failed to fetch report details:", error);
            }
        };

        onMounted(async () => {
            await fetchReportDetails();
        });

        const deleteReport = async () => {
            try {
                await ReportsHandler.deleteReport(menuId.value, reportId.value);
                router.go(-1); // Navigate back
            } catch (error) {
                console.error("Failed to delete report:", error);
            }
        };

        const aggregatedProducts = computed(() => {
            const products = {};
            if (report.value) {
                report.value.orders.forEach(item => {
                    const productKey = item.productTitle;
                    const cleanPrice = parseFloat(item.productPrice.replace(/[^0-9.]/g, ''));

                    if (products[productKey]) {
                        products[productKey].quantity += item.quantity;
                        products[productKey].totalPrice += item.quantity * cleanPrice;
                    } else {
                        products[productKey] = {
                            quantity: item.quantity,
                            totalPrice: item.quantity * cleanPrice
                        };
                    }
                });
            }
            return products;
        });

        const totalSum = computed(() => {
            return Object.values(aggregatedProducts.value).reduce((acc, { totalPrice }) => acc + totalPrice, 0);
        });

        const convertDateToEuropeanFormat = (date) => {
            const [year, month, day] = date.split("-");
            const paddedDay = day.padStart(2, '0');
            const paddedMonth = month.padStart(2, '0');
            return `${paddedDay}.${paddedMonth}.${year}`;
        };

        return {
            report,
            reportId,
            aggregatedProducts,
            totalSum,
            isDialogOpen,
            confirmDeletion,
            deleteReport,
            convertDateToEuropeanFormat
        };
    }
};
</script>

<style lang="scss" scoped>
@import "scss/colors.scss";

.report-details-wrapper {
    position: relative;
    padding: 0 5rem;


    @media (max-width: $tablet) {
        padding: 0 3rem;
    }

    @media (max-width: $mobile) {
        padding: 0 1rem;
    }

    @media (min-width: $default-desktop) {
        padding: 0 7rem;
    }

    @media (min-width: $big-desktop) {
        padding: 0 10rem;
    }

    .reportDetailsHeadline {
        text-align: start;

        @media (max-width: $tablet) {
            font-size: 2rem;
            text-align: center;
        }

        @media (max-width: $mobile) {
            font-size: 1.5rem;
            text-align: center;
        }
    }

    .report-details {
        min-height: 80vh;
    }

    .spinnerWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .product-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
    }

    .product-table th,
    .product-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .product-table th {
        background-color: #f4f4f4;
    }

    .deleteWrapper {
        display: flex;
        justify-content: flex-end;

        @media (max-width: $mobile) {
            justify-content: center;
        }

        .delete-button {
            padding: 10px 20px;
            background-color: #f44336;
            border: none;
            color: white;
            cursor: pointer;
            border-radius: 5px;
            font-size: 16px;
            margin-bottom: 2rem;
        }
    }

    .backIcon {
        position: absolute;
        left: 2vw;
        top: 0.8rem;
        font-size: 2rem;
        color: $green-poison;
        cursor: pointer;

        @media (max-width: $mobile) {
            top: 0.6rem;
        }
    }
}
</style>
