<template>
    <div class="menuCreationImpressum">
        <div>
            <button class="flyingCartButton"
                v-if="!displayedElements.generalSettings?.onlineOrderingDisabled"
                :style="flyingCartButtonElementStyleProperties"
                @click="showCart()">
                <div :style="flyingCartButtonTextStyleProperties">
                    <QIcon class="icon"
                        :name="displayedElements.generalSettings?.flyingCartButtonIcon">
                    </QIcon>
                    <label class="numberOfCartItems">{{
                    this.$store.state.cartItems.length
                }}</label>
                </div>
            </button>

            <div class="cartWrapper" id="cartOverlay">
                <CartComponent id="cartComponent" class="cartComponent"
                    :generalSettings="displayedElements.generalSettings"
                    :menuId="displayedElements.title" :tableNumber="tableId">
                </CartComponent>
            </div>
            <button class="callEmployeeButton"
                v-if="!displayedElements.generalSettings?.employeeCallDisabled"
                :style="callEmployeeButtonElementStyleProperties"
                @click="showCallEmployeeConfirmComponent()">
                <div :style="callEmployeeButtonTextStyleProperties">
                    <QIcon class="icon"
                        :name="displayedElements.generalSettings?.callEmployeeButtonIcon || 'person_apron'">
                    </QIcon>
                </div>
            </button>
            <div class="callEmployeeConfirmWrapper"
                id="callEmployeeConfirmOverlay">
                <EmployeeCallConfirm id="callEmployeeConfirmComponent"
                    class="callEmployeeConfirmComponent"
                    :generalSettings="displayedElements.generalSettings"
                    :menuId="displayedElements.title" :tableNumber="tableId">
                </EmployeeCallConfirm>
            </div>
            <button class="backButton" @click="goBack()"
                :style="backButtonElementStyleProperties">
                <div :style="backButtonTextStyleProperties">
                    <span v-if="!showCategoryInput">Zurück</span>
                    <QIcon class="icon" name="arrow_back"
                        style="font-size: 25px">
                    </QIcon>
                </div>
            </button>
        </div>
        <div style="
              height: 700px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-flow: column;
              gap: 20px;
            " v-if="displayedElements.generalSettings?.impressum?.length === 0 &&
                    !isImpressumLoading
                    ">
            <div style="font-size: 12px">
                <span>Keine Inhaltsstoffe / Alergene vorhanden </span>
            </div>
        </div>
        <div v-else-if="isImpressumLoading">
            <div class="spinnerWrapperMenu">
                <QSpinner :style="'color: ' +
                    displayedElements.generalSettings.secondaryColor +
                    ';'
                    " size="3rem" />
            </div>
        </div>
        <div v-if="displayedElements.generalSettings?.impressum && !isImpressumLoading"
            id="menuElements" class="menuElements">
            <div v-html="displayedElements.generalSettings?.impressum">
            </div>
            <div style="height: 30vh;"></div>
        </div>
    </div>
</template>

<script>
import buildStylePropertiesString from "../../../stylePropertyStringBuilder";
import CartComponent from "../../Checkout/CartComponent.vue";

import { QIcon /* QSpinner */ } from "quasar";
import EmployeeCallConfirm from "../../EmployeeCall/EmployeeCallConfirm.vue";
import MenuHandler from "@/util/MenuHandler";

export default {
    components: {
        /* QSpinner */
        QIcon,
        CartComponent,
        EmployeeCallConfirm,
    },

    mounted() {
        const id = this.$route.params.menuId;
        this.fetchImpressum(id);
    },

    data() {
        return {
            tableId: null,
            displayedElements: { editMenu: [], generalSettings: {} },
            isImpressumLoading: true,

            currentScreenLevel: 0,

            // main control
            showGeneralSettings: false,
            showElementKit: true,
            // dropdown control


            backButtonTextStyleProperties:
                "color: " +
                this.$store.state.backButtonTextColor +
                "; font-family: " +
                this.$store.state.currentFont +
                "; font-size: 15pt; display: flex; align-items: center; justify-content: center; gap: 20px;",

            backButtonElementStyleProperties:
                "background: " +
                this.$store.state.backButtonBackgroundColor +
                "; height: " +
                this.$store.state.backButtonHeight +
                "px; width: " +
                this.$store.state.backButtonWidth +
                "px; border-radius: " +
                this.$store.state.backButtonBorderRadius +
                "px; ",

            flyingCartButtonTextStyleProperties:
                "color: " +
                this.$store.state.backButtonTextColor +
                "; font-size: 25px; display: flex; align-items: center; justify-content: center; ",

            flyingCartButtonElementStyleProperties:
                "background: " +
                this.$store.state.secondaryColorSettings +
                "; height: 60px; width: 60px; border-radius: 360px; ",

            callEmployeeButtonTextStyleProperties:
                "color: " +
                this.$store.state.backButtonTextColor +
                "; font-size: 25px; display: flex; align-items: center; justify-content: center; ",

            callEmployeeButtonElementStyleProperties:
                "background: " +
                this.$store.state.secondaryColorSettings +
                "; height: 60px; width: 60px; border-radius: 360px; ",

            generalProductPreviewElementStyleProperties:
                "display: flex; justify-content: center; background: " +
                this.$store.state.secondaryColorSettings +
                "; margin-top: 20px; height: 80px; width: 340px; border-radius: 20px;",

        };
    },

    watch: {

        "displayedElements.generalSettings.backgroundColor": function () {
            var menu = document.querySelector(".menuCreationImpressum");
            if (!this.displayedElements.generalSettings.currentSelectedImage) {
                menu.style.background =
                    this.displayedElements.generalSettings.backgroundColor;
                this.$store.commit("setMenuBackground", menu.style);
                this.setBodyBackgroundColor(this.displayedElements.generalSettings.backgroundColor)

            }
        },
        "displayedElements.generalSettings.currentSelectedImage": function () {
            if (!this.displayedElements.generalSettings.currentSelectedImage) {
                return;
            }
            var menu = document.querySelector(".menuCreationImpressum");
            menu.style.backgroundImage =
                this.displayedElements.generalSettings.linearGradientOverlay +
                "url(" +
                this.displayedElements.generalSettings.currentSelectedImage +
                ")";

            menu.style.backgroundSize = "cover";
            menu.style.backgroundPosition = "center";

            this.$store.commit("setMenuBackground", menu.style);
        },
        "displayedElements.generalSettings.overlayColor": function () {
            var menu = document.querySelector(".menuCreationImpressum");
            menu.style.backgroundImage =
                this.displayedElements.generalSettings.overlayColor +
                "url(" +
                this.displayedElements.generalSettings.currentSelectedImage +
                ")";

            this.$store.commit("setMenuBackground", menu.style);
        },
        "displayedElements.generalSettings.flyingCartButtonIconColor": function () {
            this.flyingCartButtonTextStyleProperties = buildStylePropertiesString(
                "color",
                this.displayedElements.generalSettings.flyingCartButtonIconColor,
                this.flyingCartButtonTextStyleProperties
            );
        },
        "displayedElements.generalSettings.flyingCartButtonBackgroundColor":
            function () {
                this.flyingCartButtonElementStyleProperties =
                    buildStylePropertiesString(
                        "background",
                        this.displayedElements.generalSettings
                            .flyingCartButtonBackgroundColor,
                        this.flyingCartButtonElementStyleProperties
                    );
            },

        "displayedElements.generalSettings.callEmployeeButtonIconColor": function () {
            this.callEmployeeButtonTextStyleProperties = buildStylePropertiesString(
                "color",
                this.displayedElements.generalSettings.callEmployeeButtonIconColor,
                this.callEmployeeButtonTextStyleProperties
            );
        },
        "displayedElements.generalSettings.callEmployeeButtonBackgroundColor":
            function () {
                this.callEmployeeButtonElementStyleProperties =
                    buildStylePropertiesString(
                        "background",
                        this.displayedElements.generalSettings
                            .callEmployeeButtonBackgroundColor,
                        this.callEmployeeButtonElementStyleProperties
                    );
            },

        "displayedElements.generalSettings.backButtonTextColor": function () {
            this.backButtonTextStyleProperties = buildStylePropertiesString(
                "color",
                this.displayedElements.generalSettings.backButtonTextColor,
                this.backButtonTextStyleProperties
            );
        },
        "displayedElements.generalSettings.backButtonBackgroundColor": function () {
            this.backButtonElementStyleProperties = buildStylePropertiesString(
                "background",
                this.displayedElements.generalSettings.backButtonBackgroundColor,
                this.backButtonElementStyleProperties
            );
        },

        "displayedElements.generalSettings.backButtonBorderRadius": function () {
            this.backButtonElementStyleProperties = buildStylePropertiesString(
                "border-radius",
                this.displayedElements.generalSettings.backButtonBorderRadius + "px",
                this.backButtonElementStyleProperties
            );
        },
        "$store.state.showCartComponent": function () {
            if (!this.$store.state.showCartComponent) {
                this.hideCart();
            }
        },
        "$store.state.showCallEmployeeConfirm": function () {
            if (!this.$store.state.showCallEmployeeConfirm) {
                this.hideCallEmployeeConfirm();
            }
        },
    },

    methods: {

        decryptNumber(base64) {
            return atob(base64);
        },

        async fetchImpressum(id) {
            const encryptedId = this.$route.query.id; // Get encrypted ID from the URL
            this.tableId = parseInt(this.decryptNumber(encryptedId)); // Decrypt the ID
            this.$router.replace({ query: {} }); // Remove the ID from the URL
            try {
                const response = await MenuHandler.fetchPublicMenu(id);
                this.displayedElements = response.data;
                this.setBodyBackgroundColor(this.displayedElements.backgroundColor);
                if ('caches' in window) {
                    caches.keys().then((names) => {
                        for (let name of names) {
                            caches.delete(name);
                        }
                    });
                }
            } catch (error) {
                console.error("Error fetching impressum: ", error);
            } finally {
                this.isImpressumLoading = false;
            }
        },


        showCart() {
            this.$store.commit("toggleCart");
            var cartOverlay = document.getElementById("cartOverlay");

            cartOverlay.style.zIndex = 500;

            cartOverlay.classList.toggle("fade-in");
            cartOverlay.classList.remove("fade-out");
        },
        hideCart() {
            var cartOverlay = document.getElementById("cartOverlay");

            cartOverlay.style.zIndex = -100;
            cartOverlay.classList.toggle("fade-out");
            cartOverlay.classList.remove("fade-in");
        },
        showCallEmployeeConfirmComponent() {
            this.$store.commit("toggleCallEmployeeConfirm");
            var callEmployeeConfirmOverlay = document.getElementById("callEmployeeConfirmOverlay");

            callEmployeeConfirmOverlay.style.zIndex = 500;

            callEmployeeConfirmOverlay.classList.toggle("fade-in");
            callEmployeeConfirmOverlay.classList.remove("fade-out");
        },
        hideCallEmployeeConfirm() {
            var callEmployeeConfirmOverlay = document.getElementById("callEmployeeConfirmOverlay");

            callEmployeeConfirmOverlay.style.zIndex = -100;
            callEmployeeConfirmOverlay.classList.toggle("fade-out");
            callEmployeeConfirmOverlay.classList.remove("fade-in");
        },
        openUrl(url) {
            window.open(url, "_blank");
        },
        setBodyBackgroundColor(color) {
            document.documentElement.style.backgroundColor = color;
            document.body.style.backgroundColor = color;
        },
        goBack() {
            const menuId = this.displayedElements.title;
            const tableIdBase64 = btoa(this.tableId);
            this.$router.replace('/menu/' + menuId + '?id=' + tableIdBase64,)  // This will set the query params
        }

    },
};
</script>

<style lang="scss" scoped>
@import "scss/colors.scss";

.menuCreationImpressum {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 3rem 0.5rem;
    max-height: unset;
    position: relative;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0;

    .contentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    .menuElements {
        //height: 800px;
        max-height: unset;
        //overflow-y: scroll;
        padding-right: 0 !important;

        .menuElement {
            position: relative;

            .elementOptions {
                position: absolute;
                bottom: -4rem;
                left: 50%;
                transform: translate(-50%, 0);
            }

            .drag-handle {
                position: absolute;
                top: 50%;
                left: 0;
                cursor: move;
            }
        }
    }

    .cartWrapper {
        height: 100vh;
        width: 100vw;
        max-height: unset;
        border-radius: 0;
        background: rgb(0 0 0 / 40%);
        right: 0;
        bottom: 0px;
        position: absolute;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: -100;
    }

    .callEmployeeConfirmWrapper {
        height: 100vh;
        width: 100vw;
        max-height: unset;
        border-radius: 0;
        background: rgb(0 0 0 / 40%);
        right: 0;
        bottom: 0px;
        position: absolute;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: -100;
    }

    .backButton {
        transition: 0.3s;
        border: none;
        position: fixed;
        top: 75vh !important;
        left: 50%;
        transform: translate(-50%, 0);
        box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
            0 3px 1px -2px rgb(0 0 0 / 12%);
        font-weight: bold;
        z-index: 200;

        &:hover {
            cursor: pointer;
            transform: translate(0px, 3px);
        }

        &:active {
            transform: scale(0.8);
        }
    }

    .flyingCartButton {
        transition: 0.3s;
        border: none;
        position: fixed;
        top: 30%;
        right: 5%;
        // box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
        //   0 3px 1px -2px rgb(0 0 0 / 12%);
        font-weight: bold;
        z-index: 200;
        display: flex;
        align-items: center;
        justify-content: center;

        .numberOfCartItems {
            position: absolute;
            transition: 0.3s;
            top: 20%;
            right: 12%;
            z-index: 300;
            font-weight: bold;
            font-size: 15px;
        }

        &:hover {
            cursor: pointer;
            transform: translate(0px, 3px);
        }

        &:active {
            // transition: all 0.3s ease-in-out;
            transform: scale(0.7);
        }
    }

    .callEmployeeButton {
        transition: 0.3s;
        border: none;
        position: fixed;
        bottom: 150px;
        left: 5%;
        // box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
        //   0 3px 1px -2px rgb(0 0 0 / 12%);
        font-weight: bold;
        z-index: 200;
        display: flex;
        align-items: center;
        justify-content: center;
    }


}

.slide-in-left {
    -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-right {
    position: relative;
    margin-right: 0px;
    animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-out {
    transition: 0.3s;
    position: fixed;
    margin-right: -5000px;
    animation: slide-out-right 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes slide-out {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
}

.rotate-center {
    animation: rotate-center 0.6s ease-in-out both;
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-in {
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-out {
    animation: fade-out 0.3s ease-out both;
}

input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
}

.q-icon {
    margin-left: 0 !important;
}

.spinnerWrapperMenu {
    width: 100vw;
    height: 700px;
    margin-left: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
p {
    max-height: none !important;
}
</style>