import { createRouter, createWebHistory } from "vue-router";
import MenuCreation from "@/components/Menu/MenuCreation.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginScreen from "@/components/Auth/LoginScreen.vue";
import DashboardScreen from "@/components/Dashboard/DashboardScreen.vue";
import RegisterScreen from "@/components/Auth/RegisterScreen.vue";
import PublishedMenu from "@/components/Menu/PublishedMenu.vue";
import ImpressumScreen from "@/components/Menu/AdditionalScreens/ImpressumScreen.vue"
import DataPrivacyScreen from "@/components/Menu/AdditionalScreens/DataPrivacyScreen.vue"
import IngredientsScreen from "@/components/Menu/AdditionalScreens/IngredientsScreen.vue"
import OrderDashboard from "@/components/Orders/OrderDashboard.vue";
import ReportsDashboard from "@/components/Reports/ReportsDashboard.vue";
import ReportDetails from "@/components/Reports/ReportDetails.vue";
const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardScreen,
    meta: { requiresAuth: true }, // Meta field to indicate the route requires authentication
  },
  {
    path: "/configure/:menuId",
    name: "Configure",
    component: MenuCreation,
    props: true,
    meta: { requiresAuth: true }, // Meta field to indicate the route requires authentication
  },
  {
    path: "/login",
    name: "Login",
    component: LoginScreen,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterScreen,
  },
  {
    path: "/menu/:menuId",
    name: "Menu",
    component: PublishedMenu,
    props: true,
  },
  {
    path: "/menu/:menuId/impressum",
    name: "MenuImpressum",
    component: ImpressumScreen,
    props: true,
  },
  {
    path: "/menu/:menuId/data_privacy",
    name: "MenuDataPrivacy",
    component: DataPrivacyScreen,
    props: true,
  },
  {
    path: "/menu/:menuId/ingredients",
    name: "MenuIngredients",
    component: IngredientsScreen,
    props: true,
  },
  {
    path: "/orders/:menuId",
    name: "OrderDashboard",
    component: OrderDashboard,
    props: true,
    meta: { requiresAuth: true }, // Meta field to indicate the route requires authentication
  },
  {
    path: "/reports/:menuId",
    name: "ReportsDashboard",
    component: ReportsDashboard,
    props: true,
    meta: { requiresAuth: true }, // Meta field to indicate the route requires authentication
  },
  {
    path: "/reports/:menuId/:reportId",
    name: "ReportDetails",
    component: ReportDetails,
    props: true,
    meta: { requiresAuth: true }, // Meta field to indicate the route requires authentication
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (requiresAuth && !user) {
      next("/login");
    } else {
      if (to.path === "/login" && user) {
        next("/");
      }
      next();
    }
  });
});

export default router;
