<template>
    <div class="report-list-wrapper">
        <q-icon name="arrow_back" class="backIcon" @click="$router.go(-1)"></q-icon>

        <div class="daily-reports-list">
            <h3 class="reportsHeadline">Reports</h3>
            <div v-if="reports.length > 0">
                <div v-for="report in reports" :key="report.id" class="linkList">
                    <router-link :to="`/reports/${menuId}/${report.id}`" class="reportLink">
                        {{ convertDateToEuropeanFormat(report.date) }}
                    </router-link>
                </div>
            </div>
            <div v-else class="noReports">
                keine Reports verfügbar
            </div>
            <div v-if="isReportsLoading" class="spinnerWrapper">
                <QSpinner color="light-green" size="3rem" />
            </div>
        </div>
    </div>
</template>


<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { QSpinner } from "quasar";
import ReportsHandler from "@/util/ReportsHandler";

export default {
    components: {
        QSpinner
    },
    setup() {
        const route = useRoute();
        const reports = ref([]);
        const menuId = ref("");

        onMounted(async () => {
            const uid = route.params.menuId; // Assuming you have the uid from the route
            menuId.value = uid;
            try {
                // Fetch reports from the backend
                const response = await ReportsHandler.fetchReports(uid);
                reports.value = response.data;
            } catch (error) {
                console.error("Failed to fetch daily reports:", error);
            }
        });

        const convertDateToEuropeanFormat = (date) => {
            const [year, month, day] = date.split("-");
            const paddedDay = day.padStart(2, '0');
            const paddedMonth = month.padStart(2, '0');
            return `${paddedDay}.${paddedMonth}.${year}`;
        };

        return {
            reports,
            menuId,
            convertDateToEuropeanFormat
        };
    }
};

</script>

<style lang="scss" scoped>
@import "scss/colors.scss";

.report-list-wrapper {
    position: relative;
    padding: 0 5rem;

    @media (max-width: $tablet) {
        padding: 0 3rem;
    }

    @media (max-width: $mobile) {
        padding: 0 1rem;
    }

    @media (min-width: $default-desktop) {
        padding: 0 7rem;
    }

    @media (min-width: $big-desktop) {
        padding: 0 10rem;
    }

    .reportsHeadline {
        text-align: start;
        @media (max-width: $tablet) {
            font-size: 2rem;
            text-align: center;
        }

        @media (max-width: $mobile) {
            font-size: 1.5rem;
            text-align: center;
        }

    }

    .linkList {
        display: flex;
        flex-direction: column;
        padding: 0;

        .reportLink {
            color: $green-poison;
            font-size: 2rem;
            font-weight: bold;
            text-decoration: none;
            text-align: start;
        }
    }

    .noReports {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
    }

    .spinnerWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
    }

    .backIcon {
        position: absolute;
        left: 2vw;
        top: 0.8rem;
        font-size: 2rem;
        color: $green-poison;
        cursor: pointer;

        @media (max-width: $mobile) {
            top: 0.6rem;
        }
    }
}
</style>