<template>
    <div class="call-employee"
        :style="'background: ' + generalSettings.secondaryColor">
        <div v-if="!requestSent">
            <div class="top">
                <QIcon name="close" class="icon"
                    :style="'color: ' + generalSettings.primaryColor + ';'"
                    @click="this.$store.commit('toggleCallEmployeeConfirm')">
                </QIcon>
            </div>
            <h6 :style="'color: ' + generalSettings.primaryColor">
                Hilfe benötigt? </h6>
            <div style="display: flex; margin-top: 1rem;">
                <button class="cancel-button"
                    :style="'background: ' + generalSettings.secondaryColor + '; color: ' + generalSettings.primaryColor + '; border: 1px solid ' + generalSettings.primaryColor"
                    @click="cancelCall">
                    Abbrechen
                </button>
                <button class="call-button"
                    :style="'background: ' + generalSettings.primaryColor + '; color: ' + generalSettings.secondaryColor"
                    @click="callEmployee">
                    Mitarbeiter <!-- an Tisch {{ tableNumber }} --> rufen
                </button>
            </div>
        </div>
        <div v-else class="confirmation-message"
            :style="'background: ' + generalSettings.secondaryColor">
            <span :style="'color: ' + generalSettings.primaryColor + ';'">Ein
                Mitarbeiter kommt gleich zu Ihnen.</span>
        </div>
    </div>
</template>

<script>
import MenuHandler from "@/util/MenuHandler";
import { QIcon } from "quasar";

export default {
    props: {
        generalSettings: {
            type: Object,
            required: true
        },
        menuId: {
            type: String,
            required: true
        },
        tableNumber: {
            type: Number,
            required: true
        }
    },
    components: { QIcon },
    data() {
        return {
            requestSent: false
        };
    },
    methods: {
        async callEmployee() {
            try {
                await MenuHandler.callEmployee(this.menuId, this.tableNumber);
                this.requestSent = true;
                setTimeout(() => {
                    this.requestSent = false;  // Optionally reset state after a set time or on another action
                    this.$store.commit("toggleCallEmployeeConfirm"); // Assumes this action will hide the cart
                }, 5000);
            } catch (error) {
                console.error('Error calling employee: ', error);
            }
        },
        cancelCall() {
            this.$store.commit("toggleCallEmployeeConfirm");
        }
    }
};
</script>

<style scoped>
.call-employee {
    position: absolute;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%);
    width: 350px;
    border-radius: 30px;
    background: white;
    top: 20%;
    box-sizing: border-box;

    .top {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon {
            margin-top: 20px;
            margin-right: 20px;
            font-size: large;
            font-weight: bold;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .call-button {
        padding: 0.5rem;
        border-radius: 25px;
        border: none;
        font-weight: bold;
        margin: 1rem;
        width: calc(100% - 2rem);
    }

    .cancel-button {
        padding: 0.5rem;
        border-radius: 25px;
        border: none;
        font-weight: bold;
        margin: 1rem;
        width: calc(100% - 2rem);
    }

    .confirmation-message {
        border-radius: 25px;
        height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        font-weight: bold;
        flex-direction: column;
        gap: 2rem;
    }
}
</style>