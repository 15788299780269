<template>
  <div v-if="show">
    <div class="imageElementBody">
      <div class="dropZoneWrapper">
        <div class="dropZone">
          <div v-if="!localEditElement.content.image && !isImageUploadLoading"
            class="dropZoneContentNoImage">
            <QIcon name="add_photo_alternate" class="uploadIcon"></QIcon>
            <div>
              <span>Bild hochladen oder hineinziehen</span>
            </div>
            <input type="file" :multiple="false" id="imageCategoryUpload"
              accept="image/*"
              style="opacity: 0; position: fixed; height: 150px; width: 340px"
              @change="handleImageUpload" />
          </div>
          <QSpinner v-else-if="isImageUploadLoading" color="light-green"
            size="1rem">
          </QSpinner>
          <div v-else class="dropZoneContentImage">
            <img draggable="false" :src="localEditElement.content.image"
              :style="currentPreviewImageStyleProperties" />
          </div>
        </div>
        <div v-if="localEditElement.content.image" class="deleteIcon">
          <QIcon name="delete" @click="deleteImage()"></QIcon>
        </div>
      </div>
      <div class="imageOptions" v-if="localEditElement.content.image">
        <div>
          <h6>Bildoptionen</h6>
        </div>
        <div>
          <label>Höhe: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.imageStyleValues.height" @input="
    buildImageStylePropertiesString(
      'height',
      localEditElement.imageStyleValues.height + 'px'
    )
    " />
          <label style="margin-left: 20px">Breite: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.imageStyleValues.width" @input="
    buildImageStylePropertiesString(
      'width',
      localEditElement.imageStyleValues.width + 'px'
    )
    " />
          <label style="margin-left: 20px">Rundung: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.imageStyleValues.borderRadius" @input="
    buildImageStylePropertiesString(
      'border-radius',
      localEditElement.imageStyleValues.borderRadius + 'px'
    )
    " />
        </div>

        <div style="margin-top: 20px">
          <h6>Elementabstand</h6>
        </div>

        <div>
          <label>Oben: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.imageStyleValues.marginTop" @input="
    buildImageStylePropertiesString(
      'margin-top',
      localEditElement.imageStyleValues.marginTop + 'px'
    )
    " />
          <label style="margin-left: 10px">Unten: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.imageStyleValues.marginBottom" @input="
    buildImageStylePropertiesString(
      'margin-bottom',
      localEditElement.imageStyleValues.marginBottom + 'px'
    )
    " />
          <label style="margin-left: 10px">Links: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.imageStyleValues.marginLeft" @input="
    buildImageStylePropertiesString(
      'margin-left',
      localEditElement.imageStyleValues.marginLeft + 'px'
    )
    " />
          <label style="margin-left: 10px">Rechts: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.imageStyleValues.marginRight" @input="
    buildImageStylePropertiesString(
      'margin-right',
      localEditElement.imageStyleValues.marginRight + 'px'
    )
    " />
        </div>
      </div>
      <div class="saveImageElementBtn">
        <MyButton buttonStyle="btn btn-medium" @click="saveImageElement()">
          Speichern</MyButton>
      </div>
    </div>
  </div>
</template>
<script>
import { QIcon, QSpinner } from "quasar";
import MyButton from "../shared/MyButton.vue";
import buildStylePropertiesString from "../../stylePropertyStringBuilder";
import AssetHandler from "@/util/AssetHandler";
export default {
  components: {
    QIcon,
    QSpinner,
    MyButton,
  },
  inject: ["$firebase"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    currentEditElement: {
      type: Object,
      default: null,
    },
    menuId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isImageUploadLoading: false,
      localEditElement: {
        initialized: true,
        content: {
          image: "",
        },
        imageStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          height: 0,
          width: 0,
          borderRadius: 0,
        },
        imageStyleProperties: "",
      },
    };
  },

  watch: {
    "currentEditElement.elementId": {
      handler: function (newValue, oldValue) {
        if (
          this.$store.state.currentElementMode == "image" &&
          oldValue !== newValue
        ) {
          (this.localEditElement = {
            initialized: true,
            content: {
              image: "",
            },
            imageStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              height: 0,
              width: 0,
              borderRadius: 0,
            },
            imageStyleProperties: "",
          }),
            this.assignProperties();
        }
      },
      deep: true,
    },
    localEditElement: {
      handler: function () {
        if (this.localEditElement) {
          this.$emit("update:currentEditElement", this.localEditElement);
        }
      },
      deep: true,
    },
  },

  methods: {
    assignProperties() {
      var imageUserSettings = this.$store.state.imageElementSettings;
      this.localEditElement.elementId = this.currentEditElement.elementId;
      this.localEditElement.elementType = this.currentEditElement.elementType;
      this.localEditElement.initialized = true;
      this.localEditElement.content.image = this.currentEditElement.content
        ?.image
        ? this.currentEditElement.content.image
        : "";
      if (JSON.stringify(imageUserSettings) !== "{}") {
        this.localEditElement.imageStyleProperties =
          imageUserSettings.imageStyle;
        this.localEditElement.imageStyleValues.marginTop =
          imageUserSettings.elementStyleValues.marginTop;
        this.localEditElement.imageStyleValues.marginBottom =
          imageUserSettings.elementStyleValues.marginBottom;
        this.localEditElement.imageStyleValues.marginLeft =
          imageUserSettings.elementStyleValues.marginLeft;
        this.localEditElement.imageStyleValues.marginRight =
          imageUserSettings.elementStyleValues.marginRight;
        this.localEditElement.imageStyleValues.height =
          imageUserSettings.elementStyleValues.height;
        this.localEditElement.imageStyleValues.width =
          imageUserSettings.elementStyleValues.width;
        this.localEditElement.imageStyleValues.borderRadius =
          imageUserSettings.elementStyleValues.borderRadius;
      } else {
        this.localEditElement.imageStyleValues.marginTop = 20;
        this.localEditElement.imageStyleValues.height = 150;
        this.localEditElement.imageStyleValues.width = 340;
        this.localEditElement.imageStyleValues.borderRadius = 30;

        this.localEditElement.imageStyleProperties =
          "display: flex; justify-content: center; align-items: center; font-size: 40px; margin-top: 20px; height: 150px; width: 340px; border-radius: 30px; max-height: 150px; max-width: 340px; margin-top: 20px;";
      }
    },
    handleImageUpload(event) {
      this.isImageUploadLoading = true;
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        // Create a JSON object with the base64 encoded string
        const imageData = {
          image: base64String,
        };

        // Assuming AssetHandler.uploadImage() can handle an object with base64 string
        AssetHandler.uploadImage(imageData, this.menuId)
          .then((response) => {
            this.localEditElement.content.image = response.data;
            this.isImageUploadLoading = false;
          })
          .catch((error) => {
            this.isImageUploadLoading = false;
            console.error("Error uploading image:", error);
          });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    },
    deleteImage() {
      if (!this.localEditElement.content.image) {
        return;
      }

      AssetHandler.deleteImage(this.localEditElement.content.image).then(() => {
        this.localEditElement.content.image = "";
      });
    },
    buildImageStylePropertiesString(styleProperty, value) {
      this.localEditElement.imageStyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.imageStyleProperties
      );
    },
    saveImageElement() {
      if (this.localEditElement.content.image) {
        const imageElementSettings = {
          imageStyle: this.localEditElement.imageStyleProperties,
          elementStyleValues: {
            marginTop: this.localEditElement.imageStyleValues.marginTop,
            marginBottom: this.localEditElement.imageStyleValues.marginBottom,
            marginLeft: this.localEditElement.imageStyleValues.marginLeft,
            marginRight: this.localEditElement.imageStyleValues.marginRight,
            height: this.localEditElement.imageStyleValues.height,
            width: this.localEditElement.imageStyleValues.width,
            borderRadius: this.localEditElement.imageStyleValues.borderRadius,
          },
        };

        this.$store.commit("setImageElementSettings", imageElementSettings);

        this.$emit("hideDialog");
      }
    },
  },
};
</script>
<style lang="scss">
@import "scss/colors.scss";

.imageElementBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dropZoneWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .dropZone {
      // height: 150px;
      // border: solid 1px $grey-medium;
      border-radius: 10px;
      display: flex;
      justify-content: center;

      .optionIconSearch {
        height: 25px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        margin-right: 75%;

        transition: 0.3s ease;

        border: solid 1px $grey-medium;
        border-radius: 5px;
        padding: 13px;

        position: fixed;

        &:hover {
          cursor: pointer;
          background-color: $grey-light;
        }
      }

      .dropZoneContentNoImage {
        height: 150px;
        width: 340px;
        border: solid 1px $grey-medium;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        .uploadIcon {
          font-size: 40px;
        }

        input {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .dropZoneContentImage {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          object-fit: scale-down;
          width: 200px;
        }
      }
    }

    .deleteIcon {
      font-size: 22px;
      margin-left: 75%;
      position: fixed;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .imageOptions {
    margin-top: 30px;
    height: 100%;

    text-align: left;

    padding-top: 20px;
  }

  .saveImageElementBtn {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }

  .currentColor {
    height: 17px;
    width: 23px;
    margin-left: 11px;
    margin-top: 3px;
    border-radius: 5px;
    border: solid 1px black;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
