<template>
  <div v-if="show">
    <div class="socialMediaElementBody">
      <div>
        <div>
          <h6 style="margin-top: 20px">Leistenfarbe und Form</h6>
        </div>
        <div style="display: flex">
          <label>Farbe: </label>
          <div class="currentColor" :style="{
    background:
      localEditElement.elementStyleValues.currentPickedColor,
  }">
            <QPopupProxy cover transition-show="scale" transition-hide="scale"
              style="margin-left: 10px">
              <QColor
                v-model="localEditElement.elementStyleValues.currentPickedColor"
                @update:model-value="
    buildElementStylePropertiesString(
      'background',
      localEditElement.elementStyleValues.currentPickedColor
    )
    " />
            </QPopupProxy>
          </div>
          <label style="margin-left: 20px">Rundung: </label>

          <input type="number" min="0" max="100"
            v-model="localEditElement.elementStyleValues.borderRadius" @input="
    buildElementStylePropertiesString(
      'border-radius',
      localEditElement.elementStyleValues.borderRadius + 'px'
    )
    " />
        </div>
        <div>
          <h6 style="margin-top: 20px">Elementabstand</h6>
        </div>
        <div>
          <label>Oben: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.elementStyleValues.marginTop" @input="
    buildElementStylePropertiesString(
      'margin-top',
      localEditElement.elementStyleValues.marginTop + 'px'
    )
    " />
          <label style="margin-left: 10px">Unten: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.elementStyleValues.marginBottom" @input="
    buildElementStylePropertiesString(
      'margin-bottom',
      localEditElement.elementStyleValues.marginBottom + 'px'
    )
    " /><label style="margin-left: 10px">Links: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.elementStyleValues.marginLeft" @input="
    buildElementStylePropertiesString(
      'margin-left',
      localEditElement.elementStyleValues.marginLeft + 'px'
    )
    " />
          <label style="margin-left: 10px">Rechts: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.elementStyleValues.marginRight" @input="
    buildElementStylePropertiesString(
      'margin-right',
      localEditElement.elementStyleValues.marginRight + 'px'
    )
    " />
        </div>
        <div>
          <h6 style="margin-top: 20px">Elementgröße</h6>
        </div>
        <div>
          <label>Höhe: </label>
          <input type="number" min="1" max="500"
            v-model="localEditElement.elementStyleValues.height" @input="
    buildElementStylePropertiesString(
      'height',
      localEditElement.elementStyleValues.height + 'px'
    )
    " />
          <label style="margin-left: 20px">Breite: </label>
          <input type="number" min="1" max="350"
            v-model="localEditElement.elementStyleValues.width" @input="
    buildElementStylePropertiesString(
      'width',
      localEditElement.elementStyleValues.width + 'px'
    )
    " />
        </div>
        <div>
          <h6 style="margin-top: 20px">Icongröße und Farbe</h6>
        </div>
        <label>Höhe & Breite: </label>
        <input type="number" min="1" max="500"
          :value="localEditElement.socialIconStyleValues.height"
          @input="setIconSize()" />
      </div>
      <div style="display: flex; margin-top: 1rem;">
        <label>Farbe: </label>
        <div class="currentColor" :style="{
    background:
      localEditElement.socialIconStyleValues.color,
  }">
          <QPopupProxy cover transition-show="scale" transition-hide="scale"
            style="margin-left: 10px">
            <QColor v-model="localEditElement.socialIconStyleValues.color" />
          </QPopupProxy>
        </div>
      </div>
      <div>
        <h6 style="margin-top: 20px">Links</h6>
      </div>
      <div style="display: flex; align-items: center;">
        <input type="checkbox" v-model="localEditElement.content.showFacebook"
          style="height: 12pt; width: 12pt; margin-right: 0.5rem; margin-left: 0; " />
        <label>Facebook link: </label>
        <input type="url" v-model="localEditElement.content.facebookLink"
          style="width: 50%;" />
      </div>
      <div style="display: flex; align-items: center;">
        <input type="checkbox" v-model="localEditElement.content.showInstagram"
          style="height: 12pt; width: 12pt; margin-right: 0.5rem; margin-left: 0; " />
        <label>Instagram link: </label>
        <input type="url" v-model="localEditElement.content.instagramLink"
          style="width: 50%;" />
      </div>
      <div style="display: flex; align-items: center;">
        <input type="checkbox" v-model="localEditElement.content.showTiktok"
          style="height: 12pt; width: 12pt; margin-right: 0.5rem; margin-left: 0; " />
        <label>TikTok link: </label>
        <input type="url" v-model="localEditElement.content.tiktokLink"
          style="width: 50%;" />
      </div>
      <div class="saveSeperationElementBtn">
        <MyButton buttonStyle="btn btn-medium" @click="saveSeperationElement()">
          Speichern</MyButton>
      </div>
    </div>
  </div>
</template>
<script>
import { QPopupProxy, QColor } from "quasar";
import MyButton from "../shared/MyButton.vue";
import buildStylePropertiesString from "../../stylePropertyStringBuilder";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentEditElement: {
      type: Object,
      default: null,
    },
    generalSettings: {
      type: Object,
      default: null,
    },
  },
  components: { QPopupProxy, QColor, MyButton },

  data() {
    return {
      localEditElement: {
        initialized: true,
        content: {
          instagramLink: "",
          facebookLink: "",
          tiktokLink: "",
          showInstagram: true,
          showFacebook: true,
          showTiktok: true,
        },
        elementStyleProperties: "",
        elementStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          height: 0,
          width: 0,
          opacity: 0,
          borderRadius: 0,
          currentPickedColor: this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9",
        },
        socialIconStyleProperties: "",
        socialIconStyleValues: {
          height: 0,
          width: 0,
          color: this.generalSettings.primaryColor
            ? this.generalSettings.primaryColor
            : "#000000",
        }
      },
    };
  },

  watch: {
    "currentEditElement.elementId": {
      handler: function (newValue, oldValue) {
        if (
          this.$store.state.currentElementMode == "socialMedia" &&
          oldValue !== newValue
        ) {
          (this.localEditElement = {
            initialized: true,
            content: {
              instagramLink: "",
              facebookLink: "",
              tiktokLink: "",
              showInstagram: true,
              showFacebook: true,
              showTiktok: true,
            },
            elementStyleProperties: "",
            elementStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              height: 0,
              width: 0,
              opacity: 0,
              borderRadius: 0,
              currentPickedColor: this.generalSettings.secondaryColor
                ? this.generalSettings.secondaryColor
                : "#edede9",
            },
            socialIconStyleProperties: "",
            socialIconStyleValues: {
              height: 0,
              width: 0,
              color: this.generalSettings.primaryColor
                ? this.generalSettings.primaryColor
                : "#000000",
            }
          }),
            this.assignProperties();
        }
      },
      deep: true,
    },
    localEditElement: {
      handler: function () {
        if (this.localEditElement) {
          this.$emit("update:currentEditElement", this.localEditElement);
        }
      },
      deep: true,
    },
  },

  methods: {
    assignProperties() {
      var socialMediaUserSettings = this.$store.state.socialMediaElementSettings;
      this.localEditElement.elementId = this.currentEditElement.elementId;
      this.localEditElement.elementType = this.currentEditElement.elementType;
      this.localEditElement.initialized = true;
      this.localEditElement.content.instagramLink = this.currentEditElement.content?.instagramLink ? this.currentEditElement.content.instagramLink : "";
      this.localEditElement.content.facebookLink = this.currentEditElement.content?.facebookLink ? this.currentEditElement.content.facebookLink : "";
      this.localEditElement.content.tiktokLink = this.currentEditElement.content?.tiktokLink ? this.currentEditElement.content.tiktokLink : "";

      if (JSON.stringify(socialMediaUserSettings) !== "{}") {
        this.localEditElement.elementStyleProperties =
          socialMediaUserSettings.elementStyle;
        this.localEditElement.elementStyleValues.marginTop =
          socialMediaUserSettings.elementStyleValues.marginTop;
        this.localEditElement.elementStyleValues.marginBottom =
          socialMediaUserSettings.elementStyleValues.marginBottom;
        this.localEditElement.elementStyleValues.marginLeft =
          socialMediaUserSettings.elementStyleValues.marginLeft;
        this.localEditElement.elementStyleValues.marginRight =
          socialMediaUserSettings.elementStyleValues.marginRight;
        this.localEditElement.elementStyleValues.height =
          socialMediaUserSettings.elementStyleValues.height;
        this.localEditElement.elementStyleValues.width =
          socialMediaUserSettings.elementStyleValues.width;
        this.localEditElement.elementStyleValues.opacity =
          socialMediaUserSettings.elementStyleValues.opacity;
        this.localEditElement.elementStyleValues.borderRadius =
          socialMediaUserSettings.elementStyleValues.borderRadius;
        this.localEditElement.elementStyleValues.currentPickedColor =
          socialMediaUserSettings.elementStyleValues.currentPickedColor;

        this.localEditElement.socialIconStyleProperties =
          socialMediaUserSettings.socialIconStyleProperties;
        this.localEditElement.socialIconStyleValues.height =
          socialMediaUserSettings.socialIconStyleValues.height;
        this.localEditElement.socialIconStyleValues.width =
          socialMediaUserSettings.socialIconStyleValues.width;
        this.localEditElement.socialIconStyleValues.color =
          socialMediaUserSettings.socialIconStyleValues.color;

      } else {
        this.localEditElement.elementStyleProperties =
          "background: " +
          (this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9") +
          "; margin-top: 20px; height: 70px; width: 340px; border-radius: 20px; display: flex; align-items: center; justify-content: space-around;";
        this.localEditElement.elementStyleValues.marginTop = 20;
        this.localEditElement.elementStyleValues.marginBottom = 0;
        this.localEditElement.elementStyleValues.marginLeft = 0;
        this.localEditElement.elementStyleValues.marginRight = 0;
        this.localEditElement.elementStyleValues.height = 70;
        this.localEditElement.elementStyleValues.width = 340;
        this.localEditElement.elementStyleValues.opacity = 0;
        this.localEditElement.elementStyleValues.borderRadius = 20;
        this.localEditElement.elementStyleValues.currentPickedColor = this
          .generalSettings.secondaryColor
          ? this.generalSettings.secondaryColor
          : "#edede9";

        this.localEditElement.socialIconStyleProperties =
          "height: 40px; width: 40px; color: " +
          (this.generalSettings.primaryColor
            ? this.generalSettings.primaryColor
            : "#000000") +
          ";";
        this.localEditElement.socialIconStyleValues.height = 40;
        this.localEditElement.socialIconStyleValues.width = 40;
        this.localEditElement.socialIconStyleValues.color = this.generalSettings.primaryColor
          ? this.generalSettings.primaryColor
          : "#000000";

      }
    },
    buildElementStylePropertiesString(styleProperty, value) {
      this.localEditElement.elementStyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.elementStyleProperties
      );
      this.$emit(
        "updateElementStyle",
        this.localEditElement.elementStyleProperties
      );
    },
    setIconSize() {
      this.localEditElement.socialIconStyleValues.height = parseFloat(event.target.value);
      this.localEditElement.socialIconStyleValues.width = parseFloat(event.target.value);
    },
    saveSeperationElement() {
      const socialMediaElementSettings = {
        elementStyle: this.localEditElement.elementStyleProperties,
        elementStyleValues: {
          marginTop: this.localEditElement.elementStyleValues.marginTop,
          marginBottom: this.localEditElement.elementStyleValues.marginBottom,
          marginLeft: this.localEditElement.elementStyleValues.marginLeft,
          marginRight: this.localEditElement.elementStyleValues.marginRight,
          height: this.localEditElement.elementStyleValues.height,
          width: this.localEditElement.elementStyleValues.width,
          opacity: this.localEditElement.elementStyleValues.opacity,
          borderRadius: this.localEditElement.elementStyleValues.borderRadius,
          currentPickedColor: this.localEditElement.elementStyleValues.currentPickedColor,
        },
        socialIconStyleProperties: this.localEditElement.socialIconStyleProperties,
        socialIconStyleValues: {
          height: this.localEditElement.socialIconStyleValues.height,
          width: this.localEditElement.socialIconStyleValues.width,
          color: this.localEditElement.socialIconStyleValues.color,
        },
      };


      this.$store.commit(
        "setSocialMediaElementSettings",
        socialMediaElementSettings
      );

      this.$emit("hideDialog");
    },
  },
};
</script>
<style lang="scss">
@import "scss/colors.scss";

.socialMediaElementBody {
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .currentColor {
    height: 17px;
    width: 23px;
    margin-left: 11px;
    margin-top: 3px;
    border-radius: 5px;
    border: solid 1px black;

    &:hover {
      cursor: pointer;
    }
  }

  .saveSeperationElementBtn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
    margin-top: 2rem;
  }
}
</style>