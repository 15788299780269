<template>
  <div class="dashboardWrapper">
    <q-icon name="arrow_back" class="backIcon" @click="$router.go(-1)"></q-icon>
    <h3 class="ordersHeadline">Bestellungen</h3>
    <div class="dashboard" v-if="tables.length > 0">
      <div v-for="(row, index) in tables" :key="index"
        :class="determineColor(getOldestOpenOrder(row)).value + (row.needsAssistance ? ' pulsateAnimation' : '')">
        <div class="tableDetails">
          <div class="topSection">
            <span>Tisch {{ row.tableNumber }}</span>
          </div>
          <div class="orders">
            <span style="text-align: start; font-weight: bold">Offen</span>
            <div v-for="(order, rowIndex) in getNestedOrderObjects(row)"
              :key="rowIndex" class="orderWrapper">
              <div class="row"
                v-if="order?.status && order?.status?.status !== 'completed'">
                <div class="items">
                  <div v-for="(item, index) in  groupedOrderItems(order.items)"
                    :key="index">
                    <div class="item">
                      <span>{{ item.quantity }}x {{ item.productTitle }}</span>
                    </div>
                  </div>
                  <!-- Display Notes if they exist -->
                  <div v-if="order.notes" class="order-notes">
                    <strong>Anmerkungen: </strong>
                    <span>{{ order.notes }}</span>
                  </div>
                </div>
                <div>
                  <q-btn flat round icon="check" size="11px"
                    @click="completeOrder(row, rowIndex)" />
                  <q-btn flat round icon="delete" size="11px"
                    @click="openDeleteDialog(row, rowIndex)" />
                </div>
              </div>
            </div>
            <span
              style="text-align: start; font-weight: bold; margin-top: 0.5rem; margin-bottom: 0.5rem;"
              v-if="hasCompletedNotCashedOutOrders(getNestedOrderObjects(row))">Abgeschlossen</span>
            <div class="completedOrders">
              <div v-for="(order, index) in getNestedOrderObjects(row)"
                :key="index">
                <div v-if="order?.status?.status === 'completed'">
                  <div v-for="(item, itemIndex) in order.items" :key="itemIndex"
                    class="item">
                    <div
                      style="display: flex; align-items: center; gap: 0.5rem; margin-bottom: 0.5rem;">
                      <input type="checkbox" v-model="item.selected"
                        style="height: 1.25rem; width: 1.25rem;" />
                      <span>{{ item.quantity }}x {{ item.productTitle }}</span>
                    </div>
                  </div>
                  <!-- Display Notes if they exist in completed orders -->
                  <div v-if="order.notes" class="order-notes">
                    <strong>Anmerkungen: </strong>
                    <span>{{ order.notes }}</span>
                  </div>
                </div>
              </div>
            </div>
            <q-btn flat icon="checklist" label="Alle auswählen"
              @click="selectAll(row)"
              style="border-radius: 25px; margin: 0.5rem 0; border: 1px solid #000"
              v-if="getNestedOrderObjects(row).length > 0 && completeOrderExists(row)" />
            <span style="font-weight: bold; text-align: start"
              v-if="getTotalPrice(getSelectedItems(row)) > 0">Gesamt: {{
      getTotalPrice(getSelectedItems(row)).toFixed(2) }} €</span>
            <q-btn flat icon="done_all" label="Abkassiert"
              style="border-radius: 25px; border: 1px solid #000; margin-top: 1rem;"
              @click="openCashOutDialog(row)"
              :disabled="!hasSelectedItems(getNestedOrderObjects(row))" />
            <q-btn style="border-radius: 25px; margin-top: 1rem" flat
              v-if="row.needsAssistance" icon="task_alt" label="Tisch besucht"
              @click="resetNeedsAssistance(row)" />
          </div>
        </div>
      </div>
    </div>
    <div class="noOrders" v-else>
      <h5>Keine Bestellungen verfügbar</h5>
    </div>
    <q-dialog v-model="isDeleteDialogOpen">
      <q-card>
        <q-card-section>
          <div class="text-h6">Bestellung löschen?</div>
          <div>Die Teilbestellung wird endgültig gelöscht.
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="ABBRECHEN" color="light-green" v-close-popup />
          <q-btn flat label="LÖSCHEN" color="negative"
            @click="confirmDeleteOrder" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="isCashOutDialogOpen">
      <q-card>
        <q-card-section>
          <div class="text-h6">Positionen abkassieren?</div>
          <div>Die ausgewählten Positionen werden als abkassiert markiert.</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="ABBRECHEN" color="negative" v-close-popup />
          <q-btn flat label="BESTÄTIGEN" color="light-green"
            @click="confirmCashOutSelected" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, watchEffect } from "vue";
import { useRoute } from "vue-router";
import {
  getDatabase,
  ref as firebaseRef,
  onValue,
  update,
  remove,
} from "firebase/database";

import MenuHandler from "@/util/MenuHandler";
export default {
  setup() {
    const route = useRoute();
    const database = getDatabase();
    const menuId = route.params.menuId;
    const tables = ref([]);
    const currentTime = ref(Date.now());
    const isDeleteDialogOpen = ref(false);
    const isCashOutDialogOpen = ref(false);
    const deleteTarget = ref({ row: null, rowIndex: null });
    const cashOutTarget = ref(null);

    // Reactive Timer
    onMounted(() => {
      const ordersRef = firebaseRef(database, `orders/${menuId}`);
      onValue(ordersRef, (snapshot) => {
        let data = snapshot.val();
        delete data.userId;
        if (data.invitedEmails) {
          delete data.invitedEmails;
        }

        if (data) {
          const mappedTables = Object.entries(data).map(([key, details]) => {
            // Remove userId field from details

            return {
              tableNumber: key,
              needsAssistance: details.needsAssistance || false,
              ...details,
              oldestOpenOrder: getOldestOpenOrderTable(details),
            };
          }).filter(t => {
            return t.oldestOpenOrder || hasCompletedNotCashedOutOrders(getNestedOrderObjects(t)) || tableNeedsAssistance(t);
          });

          mappedTables.sort((a, b) => {
            if (a.oldestOpenOrder && b.oldestOpenOrder) {
              return new Date(a.oldestOpenOrder?.createdAt) - new Date(b.oldestOpenOrder?.createdAt);
            } else if (a.oldestOpenOrder) {
              return -1;
            } else if (b.oldestOpenOrder) {
              return 1;
            }
            return 0;
          });

          tables.value = mappedTables;
        } else {
          tables.value = [];
        }
      });

      watchEffect((onInvalidate) => {
        const interval = setInterval(() => {
          currentTime.value = Date.now();
        }, 5000);

        onInvalidate(() => {
          clearInterval(interval);
        });
      });
    });


    function hasCompletedNotCashedOutOrders(tableDetails) {
      if (tableDetails.needsAssistance) return true;
      return tableDetails.some(order => order.status?.status === "completed");
    }
    function tableNeedsAssistance(tableDetails) {
      return tableDetails.needsAssistance;
    }

    async function cashOutSelected(row) {
      const selectedItems = getSelectedItems(row);
      const tableNumber = row.tableNumber;
      const menuId = route.params.menuId;

      // Update the status of selected items to "cashedOut" in the database
      for (let item of selectedItems) {
        const itemRef = firebaseRef(
          database,
          `orders/${menuId}/${tableNumber}/${item.orderId}/items/${item.itemId}`
        );
        await update(itemRef, { status: "cashedOut" });
      }

      // Reflect the status update in the local state
      for (let order of getNestedOrderObjects(row)) {
        for (let item of order.items) {
          if (item.selected) {
            item.status = "cashedOut";
          }
        }
      }

      // Add items to the daily report
      const addedToReport = await addToDailyReport(selectedItems);
      if (addedToReport) {
        // Remove the cashed out items from local data
        for (const order of getNestedOrderObjects(row)) {
          order.items = order.items.filter(item => item.status !== "cashedOut");
        }

        // Check if there are any active orders left
        const remainingOrders = getNestedOrderObjects(row).filter(order =>
          order.items.some(item => item.status !== "cashedOut" && item.status !== "completed")
        );

        // Clear the table if no active orders remain
        if (remainingOrders.length === 0) {
          await clearTable(tableNumber, row);
        }
      }
    }


    function completeOrderExists(row) {
      return getNestedOrderObjects(row).some(order => order?.status?.status === "completed");
    }

    async function resetNeedsAssistance(row) {
      const menuId = route.params.menuId;
      await MenuHandler.resetNeedsAssistance(menuId, row.tableNumber);
    }

    function hasSelectedItems(row) {
      return row.some(order => order.items?.some(item => item.selected));
    }

    function selectAll(row) {
      const completeOrders = getNestedOrderObjects(row).filter(order => order.status?.status === 'completed');
      const items = completeOrders.flatMap(order => order.items);
      const allSelected = items.every(item => item.selected);
      items.forEach(item => item.selected = !allSelected);
    }


    function getOldestOpenOrderTable(tableDetails) {
      let openOrders = [];
      getNestedOrderObjects(tableDetails).map((order) => {
        if (order?.status?.status !== "completed") {
          openOrders.push(order);
        }
      });
      return openOrders.length > 0 ? openOrders[0] : null;
    }

    const determineColor = (oldestOrder) => {
      return computed(() => {
        if (!oldestOrder) return "green";
        const orderAge = (currentTime.value - new Date(oldestOrder?.createdAt).getTime()) / 60000;
        if (orderAge < 5) return "green";
        if (orderAge < 15) return "yellow";
        return "red";
      });
    };

    function getOldestOpenOrder(row) {
      return getNestedOrderObjects(row).find(order => order?.status?.status !== "completed");
    }

    function hasOpenOrders(row) {
      return getNestedOrderObjects(row).some(order => order?.status?.status !== "completed" && order);
    }

    async function completeOrder(row, rowIndex) {
      const orderId = getKeyForOrderIndex(row, rowIndex);
      const tableNumber = row.tableNumber;
      const menuId = route.params.menuId;
      const statusRef = firebaseRef(
        database,
        `orders/${menuId}/${tableNumber}/${orderId}/status`
      );
      await update(statusRef, { status: 'completed' });
      await updateColorStatus(row);
    }

    async function confirmDeleteOrder() {
      const { row, rowIndex } = deleteTarget.value;
      if (row && rowIndex !== null) {
        await deleteOrder(row, rowIndex);
        isDeleteDialogOpen.value = false;
      }
    }

    async function confirmCashOutSelected() {
      if (cashOutTarget.value) {
        await cashOutSelected(cashOutTarget.value);
        isCashOutDialogOpen.value = false;
      }
    }

    async function deleteOrder(row, rowIndex) {
      const orderId = getKeyForOrderIndex(row, rowIndex);
      const tableNumber = row.tableNumber;
      const menuId = route.params.menuId;
      const orderRef = firebaseRef(
        database,
        `orders/${menuId}/${tableNumber}/${orderId}`
      );
      await remove(orderRef);
      await updateColorStatus(row);
    }

    async function clearTable(tableNumber, table) {
      const menuId = route.params.menuId;
      const tableRef = firebaseRef(database, `orders/${menuId}/${tableNumber}`);
      await remove(tableRef);
      await updateColorStatus(table);
    }

    async function updateColorStatus(row) {
      const oldestOpenOrder = getOldestOpenOrder(row);
      if (oldestOpenOrder) {
        const color = determineColor(oldestOpenOrder).value;
        const tableDiv = document.getElementById(`table-${row.tableNumber}`);
        if (tableDiv) tableDiv.className = `tableDetails ${color}`;
      }
    }

    async function addToDailyReport(items) {
      try {
        const response = await MenuHandler.addToDailyReport(menuId, items);
        return response.data.success;
      } catch (error) {
        console.error("Error adding to daily report:", error);
        return false;
      }
    }

    function getNestedOrderObjects(data) {
      let resultData = [];
      if (typeof data !== "object" || data === null) return null;
      for (const key in data) {
        if (key !== "tableNumber" && key !== "createdAt" && key !== "needsAssistance" && key !== "oldestOpenOrder") {
          resultData.push({
            ...data[key],
            orderId: key,
            items: data[key].items ? data[key].items.filter(item => item.status !== "cashedOut") : [],
          });
        }
      }
      return resultData;
    }

    function getSelectedItems(row) {
      return getNestedOrderObjects(row).flatMap(order =>
        order.items?.filter(item => item.selected)
          .map(item => ({ ...item, orderId: order.orderId })) // Add orderId to selected items
      );
    }

    function getKeyForOrderIndex(data, index) {
      if (typeof data !== "object" || data === null) return null;
      let currentIndex = 0;
      for (const key in data) {
        if (key !== "tableNumber" && key !== "createdAt" && key !== "needsAssistance") {
          if (currentIndex === index) {
            return key;
          }
          currentIndex++;
        }
      }
      return null;
    }

    function getTotalPrice(products) {
      let total = 0;
      for (const product of products) {
        total += parseFloat(product?.productPrice) * product?.quantity;
      }
      return total;
    }

    function openDeleteDialog(row, rowIndex) {
      deleteTarget.value = { row, rowIndex };
      isDeleteDialogOpen.value = true;
    }

    function openCashOutDialog(row) {
      cashOutTarget.value = row;
      isCashOutDialogOpen.value = true;
    }

    function groupedOrderItems(items) {
      const groupedItems = items.reduce((acc, item) => {
        const existingItem = acc.find(i => i.productTitle === item.productTitle);
        if (existingItem) {
          existingItem.quantity += item.quantity;
        } else {
          acc.push({ ...item });
        }
        return acc;
      }, []);
      return groupedItems;
    }

    return {
      tables,
      determineColor,
      deleteOrder,
      completeOrder,
      clearTable,
      getNestedOrderObjects,
      getKeyForOrderIndex,
      getTotalPrice,
      hasOpenOrders,
      getOldestOpenOrder,
      resetNeedsAssistance,
      hasSelectedItems,
      selectAll,
      getSelectedItems,
      cashOutSelected,
      completeOrderExists,
      openDeleteDialog,
      confirmDeleteOrder,
      isDeleteDialogOpen,
      isCashOutDialogOpen,
      openCashOutDialog,
      confirmCashOutSelected,
      hasCompletedNotCashedOutOrders,
      groupedOrderItems
    };
  },
};
</script>

<style scoped lang="scss">
@import "scss/colors.scss";

.dashboardWrapper {
  position: relative;
  padding: 0 5rem;
  text-align: start;

  @media (max-width: $tablet) {
    padding: 0 3rem;
  }

  @media (max-width: $mobile) {
    padding: 0 1rem;
  }

  @media (min-width: $default-desktop) {
    padding: 0 7rem;
  }

  @media (min-width: $big-desktop) {
    padding: 0 10rem;
  }

  .ordersHeadline {
    @media (max-width: $tablet) {
      font-size: 2rem;
      text-align: center;
    }

    @media (max-width: $mobile) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .dashboard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;

    @media (max-width: $tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $mobile) {
      grid-template-columns: repeat(1, 1fr);
    }

    .tableDetails {
      .topSection {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        border-bottom: 1px solid #ccc;
        font-size: 1rem;
        font-weight: bold;
      }

      .orders {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        border-top: none;

        .orderWrapper {
          display: flex;
          flex-direction: column;

          .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #000;

            .items {
              border-radius: 25px;
              margin: 0.5rem 0;

              .item {
                display: flex;
                align-items: center;
                justify-content: space-between;

              }

            }

          }
        }

        .completedOrders {
          text-align: start;
        }
      }
    }
  }

  .green {
    background-color: #ccffcc;
    border-radius: 25px;
    height: fit-content;
  }

  .yellow {
    background-color: #ffffcc;
    border-radius: 25px;
    height: fit-content;
  }

  .red {
    background-color: #ffcccc;
    border-radius: 25px;
    height: fit-content;
  }

  .noOrders {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .pulsateAnimation {
    animation: pulsate 1s infinite;
  }

  @keyframes pulsate {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  .backIcon {
    position: absolute;
    left: 2vw;
    top: 0.8rem;
    font-size: 2rem;
    color: $green-poison;
    cursor: pointer;

    @media (max-width: $mobile) {
      top: 0.6rem;
    }
  }

  .order-notes {
    margin-top: 0.5rem;
    font-style: italic;
    color: #666;
  }
}
</style>
