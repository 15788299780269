<template>
  <div v-if="show">
    <div class="productElementBody">
      <div>
        <div style="text-align: center">
          <MyProductTextEditor v-model="localEditElement.content.productTitle"
            :default-font-size="12" :generalSettings="generalSettings">
          </MyProductTextEditor>
          <MyProductTextEditor
            v-model="localEditElement.content.productSubTitle"
            :default-font-size="8" :generalSettings="generalSettings">
          </MyProductTextEditor>
          <MyProductTextEditor v-model="localEditElement.content.productPrice"
            :default-font-size="10" :generalSettings="generalSettings">
          </MyProductTextEditor>
        </div>
        <div style="display: flex; justify-content: space-between">
          <span style="margin-top: 20px; font-size: 1.25rem; font-weight: 500">
            Produktbild
          </span>
        </div>
        <div style="display: flex; flex-direction: column; margin-top: 10px">
          <div style="display: flex; align-items: center">
            <label>Bild: </label>
            <div class="imageUploadPlaceholder">
              <div class="optionIconUpload"
                v-if="!localEditElement.content.image && !isImageUploadLoading">
                <QIcon name="upload" style="position: fixed"></QIcon>
                <input class="fileInput" type="file" :multiple="false"
                  id="imageCategoryUpload" accept="image/*"
                  style="opacity: 0; height: 40px; width: 40px"
                  @change="handleImageUpload" />
              </div>
              <QSpinner v-else-if="isImageUploadLoading" color="light-green"
                size="1rem">
              </QSpinner>
              <img v-else :src="localEditElement.content.image" />
            </div>

            <div v-if="this.localEditElement.content.image"
              class="optionIconDelete" @click="deleteImage()">
              <QIcon name="delete"></QIcon>
            </div>
            <div style="margin-left: 30px">
              <label>Rundung: </label>

              <input type="number" min="0" max="100"
                v-model="localEditElement.elementStyleValues.borderRadius"
                @input="
    buildImageStylePropertiesString(
      'border-radius',
      localEditElement.elementStyleValues.borderRadius + 'px'
    )
    " />
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <h6>Titelabstand</h6>
        </div>
        <div>
          <label>Oben: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.textStyleValues.marginTop" @input="
    buildTextStylePropertiesString(
      'margin-top',
      localEditElement.textStyleValues.marginTop + 'px'
    )
    " />
          <label style="margin-left: 10px">Unten: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.textStyleValues.marginBottom" @input="
    buildTextStylePropertiesString(
      'margin-bottom',
      localEditElement.textStyleValues.marginBottom + 'px'
    )
    " />
          <label style="margin-left: 10px">Links: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.textStyleValues.marginLeft" @input="
    buildTextStylePropertiesString(
      'margin-left',
      localEditElement.textStyleValues.marginLeft + 'px'
    )
    " />
          <label style="margin-left: 10px">Rechts: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.textStyleValues.marginRight" @input="
    buildTextStylePropertiesString(
      'margin-right',
      localEditElement.textStyleValues.marginRight + 'px'
    )
    " />
        </div>
        <div style="margin-top: 20px">
          <h6>Subtitelabstand</h6>
        </div>
        <div>
          <label>Oben: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.subTextStyleValues.marginTop" @input="
    buildSubTextStylePropertiesString(
      'margin-top',
      localEditElement.subTextStyleValues.marginTop + 'px'
    )
    " />
          <label style="margin-left: 10px">Unten: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.subTextStyleValues.marginBottom" @input="
    buildSubTextStylePropertiesString(
      'margin-bottom',
      localEditElement.subTextStyleValues.marginBottom + 'px'
    )
    " />
          <label style="margin-left: 10px">Links: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.subTextStyleValues.marginLeft" @input="
    buildSubTextStylePropertiesString(
      'margin-left',
      localEditElement.subTextStyleValues.marginLeft + 'px'
    )
    " />
          <label style="margin-left: 10px">Rechts: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.subTextStyleValues.marginRight" @input="
    buildSubTextStylePropertiesString(
      'margin-right',
      localEditElement.subTextStyleValues.marginRight + 'px'
    )
    " />
        </div>
        <div style="margin-top: 20px">
          <h6>Preistextabstand</h6>
        </div>
        <div>
          <label>Oben: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.priceTextStyleValues.marginTop" @input="
    buildPriceTextStylePropertiesString(
      'margin-top',
      localEditElement.priceTextStyleValues.marginTop + 'px'
    )
    " />
          <label style="margin-left: 10px">Unten: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.priceTextStyleValues.marginBottom" @input="
    buildPriceTextStylePropertiesString(
      'margin-bottom',
      localEditElement.priceTextStyleValues.marginBottom + 'px'
    )
    " />
          <label style="margin-left: 10px">Links: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.priceTextStyleValues.marginLeft" @input="
    buildPriceTextStylePropertiesString(
      'margin-left',
      localEditElement.priceTextStyleValues.marginLeft + 'px'
    )
    " />
          <label style="margin-left: 10px">Rechts: </label>
          <input type="number" min="-500" max="500"
            v-model="localEditElement.priceTextStyleValues.marginRight" @input="
    buildPriceTextStylePropertiesString(
      'margin-right',
      localEditElement.priceTextStyleValues.marginRight + 'px'
    )
    " />
        </div>
        <div>
          <h6 style="margin-top: 20px">Elementabstand</h6>
        </div>
        <div>
          <label>Oben: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.elementStyleValues.marginTop" @input="
    buildElementStylePropertiesString(
      'margin-top',
      localEditElement.elementStyleValues.marginTop + 'px'
    )
    " />
          <label style="margin-left: 20px">Unten: </label>
          <input type="number" min="0" max="500"
            v-model="localEditElement.elementStyleValues.marginBottom" @input="
    buildElementStylePropertiesString(
      'margin-bottom',
      localEditElement.elementStyleValues.marginBottom + 'px'
    )
    " />
        </div>
        <div>
          <h6 style="margin-top: 20px">Elementgröße</h6>
        </div>
        <div>
          <label>Höhe: </label>
          <input type="number" min="20" max="500"
            v-model="localEditElement.elementStyleValues.height" @input="
    buildElementStylePropertiesString(
      'height',
      localEditElement.elementStyleValues.height + 'px'
    )
    " />
          <label style="margin-left: 20px">Breite: </label>
          <input type="number" min="50" max="350"
            v-model="localEditElement.elementStyleValues.width" @input="
    buildElementStylePropertiesString(
      'width',
      localEditElement.elementStyleValues.width + 'px'
    )
    " />
        </div>
      </div>
      <div style="margin-top: 20px">
        <h6>Dynamische Preise</h6>
        <div v-for="day in daysOfWeek" :key="day" style="margin-bottom: 10px">
          <label>{{ day }}</label>

          <div style="display: flex; gap: 1rem; margin-top: 0.25rem;"
            v-for="(range, index) in localEditElement.content.dynamicPricingRules[day]"
            :key="index">
            <div>
              <label>Von: </label>
              <input type="time" v-model="range.startTime" />
            </div>
            <div>
              <label>Bis: </label>
              <input type="time" v-model="range.endTime" />
            </div>
            <div>
              <label>Preis: </label>
              <input type="number" min="0" v-model="range.price" />
            </div>
            <!--             <button @click="resetDynamicPrice(day)">Reset</button>
 -->
          </div>
        </div>
      </div>
      <div class="saveProductElementBtn">
        <MyButton buttonStyle="btn btn-medium" @click="saveProductElement()">
          Speichern</MyButton>
      </div>
    </div>
  </div>
</template>
<script>
import MyButton from "../shared/MyButton.vue";
import MyProductTextEditor from "../shared/MyProductTextEditor.vue";
import { QIcon, QSpinner } from "quasar";
import buildStylePropertiesString from "../../stylePropertyStringBuilder";
import AssetHandler from "@/util/AssetHandler";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentEditElement: {
      type: Object,
      default: null,
    },
    generalSettings: {
      type: Object,
      default: null,
    },
    menuId: {
      type: String,
      default: null,
    },
  },

  components: {
    QIcon,
    QSpinner,
    MyButton,
    MyProductTextEditor,
  },

  data() {
    return {
      isImageUploadLoading: false,
      lastValidPrice: null,
      daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],

      localEditElement: {
        initialized: true,
        content: {
          productTitle: "Produktname",
          productSubTitle: "Produktbeschreibung",
          productPrice: "Preis", // Use dynamic pricing method
          image: "",
          dynamicPricingRules: {
            monday: {
              range: {
                startTime: "00:00",
                endTime: "23:59",
                price: null,
              }
            },
            tuesday: {
              range: {
                startTime: "00:00",
                endTime: "23:59",
                price: null,
              }
            },
            wednesday: {
              range: {
                startTime: "00:00",
                endTime: "23:59",
                price: null,
              }
            },
            thursday: {
              range: {
                startTime: "00:00",
                endTime: "23:59",
                price: null,
              }
            },
            friday: {
              range: {
                startTime: "00:00",
                endTime: "23:59",
                price: null,
              }
            },
            saturday: {
              range: {
                startTime: "00:00",
                endTime: "23:59",
                price: null,
              }
            },
            sunday: {
              range: {
                startTime: "00:00",
                endTime: "23:59",
                price: null,
              }
            },
          },
        },
        textStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        subTextStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        priceTextStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        elementStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          height: 0,
          width: 0,
          borderRadius: 0,
          secondaryColor: this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9",
        },
        textstyleProperties: "",
        subTextStyleProperties: "",
        priceTextStyleProperties: "",
        imageStyleProperties: "",
        elementStyleProperties: "",
      },
    };
  },
  watch: {
    /* dynamicPricingRules: {
      handler() {
        this.updateProductPrice();
      },
      deep: true,
    }, */
    localEditElement: {
      handler: function () {
        if (this.localEditElement) {
          this.$emit("update:currentEditElement", this.localEditElement);
        }
      },
      deep: true,
    },
    "currentEditElement.elementId": {
      handler: function (newValue, oldValue) {
        if (
          this.$store.state.currentElementMode == "product" &&
          oldValue !== newValue
        ) {
          this.localEditElement = {
            initialized: true,
            content: {
              productTitle: "Produktname",
              productSubTitle: "Produktbeschreibung",
              productPrice: "Preis",
              image: "",
              dynamicPricingRules: {
                monday: {
                  range: {
                    startTime: "00:00",
                    endTime: "23:59",
                    price: null,
                  }
                },
                tuesday: {
                  range: {
                    startTime: "00:00",
                    endTime: "23:59",
                    price: null,
                  }
                },
                wednesday: {
                  range: {
                    startTime: "00:00",
                    endTime: "23:59",
                    price: null,
                  }
                },
                thursday: {
                  range: {
                    startTime: "00:00",
                    endTime: "23:59",
                    price: null,
                  }
                },
                friday: {
                  range: {
                    startTime: "00:00",
                    endTime: "23:59",
                    price: null,
                  }
                },
                saturday: {
                  range: {
                    startTime: "00:00",
                    endTime: "23:59",
                    price: null,
                  }
                },
                sunday: {
                  range: {
                    startTime: "00:00",
                    endTime: "23:59",
                    price: null,
                  }
                },
              },
            },
            textStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
            },
            subTextStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
            },
            priceTextStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
            },
            elementStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              height: 0,
              width: 0,
              borderRadius: 0,
              secondaryColor: this.generalSettings.secondaryColor
                ? this.generalSettings.secondaryColor
                : "#edede9",
            },
            textstyleProperties: "",
            subTextStyleProperties: "",
            priceTextStyleProperties: "",
            imageStyleProperties: "",
            elementStyleProperties: "",
          };
          this.assignProperties();
        }
      },
      deep: true,
    },
    "localEditElement.content.productPrice": {
      handler: function (newValue) {
        const parsedValue = this.parsePriceHtml(newValue);
        if (parsedValue !== null) {
          this.lastValidPrice = parsedValue; // Store the last valid price
          this.localEditElement.content.productPrice = parsedValue;
        }
      },
      deep: true,
    },
  },
  methods: {
    parsePriceHtml(html) {
      // Use a DOM parser to parse the HTML and extract the text content
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      // Iterate through all text nodes in the document
      const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null, false);
      let node;
      // eslint-disable-next-line
      while (node = walker.nextNode()) {
        const text = node.nodeValue;

        // Match valid price format with an optional currency symbol after the number
        const match = text.match(/(\d+[,.]?\d{0,2})\s*([€$¥£₹]?)/);
        if (!match) {
          return this.generalSettings.pricesToFixedTwoDecimals ? "5.00€" : "5€";
        }
        if (match) {
          const valuePart = match[1];

          // Normalize the number by replacing ',' with '.'
          let value = parseFloat(valuePart.replace(",", "."));

          // If the number is valid, format to two decimal places
          if (!isNaN(value)) {
            value = value.toFixed(2);
            const formattedPrice = match[0];
            // Replace the original price in the text node with the formatted price
            node.nodeValue = formattedPrice;
          }
        }
      }
      // Serialize the updated document back to an HTML string
      return doc.body.innerHTML;
    },
    resetDynamicPrice(day) {
      this.localEditElement.content.dynamicPricingRules[day] = {
        range: {
          startTime: "00:00",
          endTime: "23:59",
          price: null,
        }
      };
    },
    assignProperties() {
      const productUserSettings = this.$store.state.productElementSettings;
      this.localEditElement.elementId = this.currentEditElement.elementId;
      this.localEditElement.elementType = this.currentEditElement.elementType;
      this.localEditElement.initialized = true;
      this.localEditElement.content.productTitle = this.currentEditElement
        .content?.productTitle
        ? this.currentEditElement.content.productTitle
        : "Produktname";
      this.localEditElement.content.productSubTitle = this.currentEditElement
        .content?.productSubTitle
        ? this.currentEditElement.content.productSubTitle
        : "Produktbeschreibung";
      this.localEditElement.content.productPrice = this.currentEditElement
        .content?.productPrice
        ? this.currentEditElement.content.productPrice
        : "Preis";
      this.localEditElement.content.image = this.currentEditElement.content
        ?.image
        ? this.currentEditElement.content.image
        : "";
      this.localEditElement.content.dynamicPricingRules = this.currentEditElement.content?.dynamicPricingRules
        ? this.currentEditElement.content?.dynamicPricingRules
        : {
          monday: {
            range: {
              startTime: "00:00",
              endTime: "23:59",
              price: null,
            }
          },
          tuesday: {
            range: {
              startTime: "00:00",
              endTime: "23:59",
              price: null,
            }
          },
          wednesday: {
            range: {
              startTime: "00:00",
              endTime: "23:59",
              price: null,
            }
          },
          thursday: {
            range: {
              startTime: "00:00",
              endTime: "23:59",
              price: null,
            }
          },
          friday: {
            range: {
              startTime: "00:00",
              endTime: "23:59",
              price: null,
            }
          },
          saturday: {
            range: {
              startTime: "00:00",
              endTime: "23:59",
              price: null,
            }
          },
          sunday: {
            range: {
              startTime: "00:00",
              endTime: "23:59",
              price: null,
            }
          },
        };

      if (JSON.stringify(productUserSettings) !== "{}") {
        this.localEditElement.textstyleProperties =
          productUserSettings.textStyle;
        this.localEditElement.subTextStyleProperties =
          productUserSettings.subTextStyle;
        this.localEditElement.priceTextStyleProperties =
          productUserSettings.priceTextStyle;
        this.localEditElement.elementStyleProperties =
          productUserSettings.elementStyle;
        this.localEditElement.imageStyleProperties =
          productUserSettings.imageStyle;

        this.localEditElement.textStyleValues.marginTop =
          productUserSettings.textStyleValues.marginTop;
        this.localEditElement.textStyleValues.marginBottom =
          productUserSettings.textStyleValues.marginBottom;
        this.localEditElement.textStyleValues.marginLeft =
          productUserSettings.textStyleValues.marginLeft;
        this.localEditElement.textStyleValues.marginRight =
          productUserSettings.textStyleValues.marginRight;

        this.localEditElement.subTextStyleValues.marginTop =
          productUserSettings.subTextStyleValues.marginTop;
        this.localEditElement.subTextStyleValues.marginBottom =
          productUserSettings.subTextStyleValues.marginBottom;
        this.localEditElement.subTextStyleValues.marginLeft =
          productUserSettings.subTextStyleValues.marginLeft;
        this.localEditElement.subTextStyleValues.marginRight =
          productUserSettings.subTextStyleValues.marginRight;

        this.localEditElement.priceTextStyleValues.marginTop =
          productUserSettings.priceTextStyleValues.marginTop;
        this.localEditElement.priceTextStyleValues.marginBottom =
          productUserSettings.priceTextStyleValues.marginBottom;
        this.localEditElement.priceTextStyleValues.marginLeft =
          productUserSettings.priceTextStyleValues.marginLeft;
        this.localEditElement.priceTextStyleValues.marginRight =
          productUserSettings.priceTextStyleValues.marginRight;

        this.localEditElement.elementStyleValues.marginTop =
          productUserSettings.elementStyleValues.marginTop;
        this.localEditElement.elementStyleValues.marginBottom =
          productUserSettings.elementStyleValues.marginBottom;
        this.localEditElement.elementStyleValues.marginLeft =
          productUserSettings.elementStyleValues.marginLeft;
        this.localEditElement.elementStyleValues.marginRight =
          productUserSettings.elementStyleValues.marginRight;
        this.localEditElement.elementStyleValues.height =
          productUserSettings.elementStyleValues.height;
        this.localEditElement.elementStyleValues.width =
          productUserSettings.elementStyleValues.width;
        this.localEditElement.elementStyleValues.borderRadius =
          productUserSettings.elementStyleValues.borderRadius;
        this.localEditElement.elementStyleValues.secondaryColor =
          productUserSettings.elementStyleValues.secondaryColor;


      } else {
        this.localEditElement.textStyleValues = {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        };
        this.localEditElement.subTextStyleValues = {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        };
        this.localEditElement.priceTextStyleValues = {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        };
        this.localEditElement.elementStyleValues = {
          marginTop: 20,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          height: 80,
          width: 340,
          borderRadius: 20,
          secondaryColor: this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9",
        };
        this.localEditElement.textstyleProperties =
          "font-weight: bold; font-size: 12pt; color: " +
          (this.generalSettings.primaryColor
            ? this.generalSettings.primaryColor
            : "#000000") +
          "; font-family: " +
          (this.generalSettings.currentFont
            ? this.generalSettings.currentFont
            : "Roboto") +
          "; width: max-content;";
        this.localEditElement.subTextStyleProperties =
          "font-weight: bold; font-size: 8pt; color: " +
          (this.generalSettings.primaryColor
            ? this.generalSettings.primaryColor
            : "#000000") +
          "; font-family: " +
          (this.generalSettings.currentFont
            ? this.generalSettings.currentFont
            : "Roboto") +
          "; ";
        this.localEditElement.priceTextStyleProperties =
          "font-weight: bold; font-size: 10pt; color: " +
          (this.generalSettings.primaryColor
            ? this.generalSettings.primaryColor
            : "#000000") +
          "; font-family: " +
          (this.generalSettings.currentFont
            ? this.generalSettings.currentFont
            : "Roboto") +
          "; ";
        this.localEditElement.imageStyleProperties =
          "height: 40px; width: 40px; border-radius: 10px;";
        this.localEditElement.elementStyleProperties =
          "display: flex; justify-content: center; margin-top: 20px; height: 80px; width: 340px; border-radius: 20px;";
      }
    },
    buildTextStylePropertiesString(styleProperty, value) {
      this.localEditElement.textstyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.textstyleProperties
      );
      this.$emit("updateTextStyle", this.localEditElement.textstyleProperties);
    },
    buildSubTextStylePropertiesString(styleProperty, value) {
      this.localEditElement.subTextStyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.subTextStyleProperties
      );
      this.$emit(
        "updateSubTextStyle",
        this.localEditElement.subTextStyleProperties
      );
    },
    buildPriceTextStylePropertiesString(styleProperty, value) {
      this.localEditElement.priceTextStyleProperties =
        buildStylePropertiesString(
          styleProperty,
          value,
          this.localEditElement.priceTextStyleProperties
        );
      this.$emit(
        "updatePriceTextStyle",
        this.localEditElement.priceTextStyleProperties
      );
    },
    buildElementStylePropertiesString(styleProperty, value) {
      this.localEditElement.elementStyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.elementStyleProperties
      );
      this.$emit(
        "updateElementStyle",
        this.localEditElement.elementStyleProperties
      );
    },
    buildImageStylePropertiesString(styleProperty, value) {
      this.localEditElement.imageStyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.imageStyleProperties
      );
      this.$emit(
        "updateImageStyle",
        this.localEditElement.imageStyleProperties
      );
    },

    handleImageUpload(event) {
      this.isImageUploadLoading = true;
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        // Create a JSON object with the base64 encoded string
        const imageData = {
          image: base64String,
        };

        // Assuming AssetHandler.uploadImage() can handle an object with base64 string
        AssetHandler.uploadImage(imageData, this.menuId)
          .then((response) => {
            this.localEditElement.content.image = response.data;
            this.isImageUploadLoading = false;
          })
          .catch((error) => {
            this.isImageUploadLoading = false;
            console.error("Error uploading image:", error);
          });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    },
    deleteImage() {
      if (!this.localEditElement.content.image) {
        return;
      }

      AssetHandler.deleteImage(this.localEditElement.content.image).then(() => {
        this.localEditElement.content.image = "";
      });
    },
    saveProductElement() {
      const productElementSettings = {
        textStyle: this.localEditElement.textstyleProperties,
        textStyleValues: this.localEditElement.textStyleValues,
        subTextStyle: this.localEditElement.subTextStyleProperties,
        subTextStyleValues: this.localEditElement.subTextStyleValues,
        priceTextStyle: this.localEditElement.priceTextStyleProperties,
        priceTextStyleValues: this.localEditElement.priceTextStyleValues,
        elementStyle: this.localEditElement.elementStyleProperties,
        elementStyleValues: this.localEditElement.elementStyleValues,
        imageStyle: this.localEditElement.imageStyleProperties,
        image: this.localEditElement.content.image,
      };

      this.$store.commit("setProductElementSettings", productElementSettings);
      /* 
      this.localEditElement.content.productTitle = "Produktname";
      this.localEditElement.content.productSubTitle = "Produktbeschreibung";
      this.localEditElement.content.productPrice = "Preis";
 */
      this.$emit("hideDialog");
    },
  },
};
</script>

<style lang="scss">
@import "scss/colors.scss";

.productElementBody {
  height: 100%;

  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .currentColor {
    height: 17px;
    width: 23px;
    margin-left: 11px;
    margin-top: 3px;
    border-radius: 5px;
    border: solid 1px black;

    &:hover {
      cursor: pointer;
    }
  }

  .imageUploadPlaceholder {
    border: solid 1px $grey-light;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;

    img {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      object-fit: contain;
    }
  }

  .optionIconUpload {
    height: 40px;
    width: 40px;

    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;

    transition: 0.3s ease;

    border: solid 1px $grey-light;
    border-radius: 10px;

    z-index: 100;

    .fileInput {
      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $grey-light;
    }
  }

  .optionIconDelete {
    font-size: large;
    margin-left: 80px;
    margin-top: -3px;
    position: fixed;

    &:hover {
      cursor: pointer;
    }
  }

  .saveProductElementBtn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
  }
}
</style>
