import { createApp } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
import router from "./router/index";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import ToastTypes from "./models/Types/ToastTypes";

//TODO: Fix double ordering bug (?)
//TODO: Fix category -124 bug
//TODO: Fix Pixabay loading spinner

//TODO: SECURITY
//TODO: Realtime DB Security solution
//TODO: enviroment variables dev and prod => GO LIVE !!!

//TODO: Logo Design
//TODO: Nuxt page design
//TODO: Build and deploy Nuxt page
//TODO: Nuxt page responsive design and implementation

//TODO: Auto delete unused images on element delete
//TODO: Menu Screenshot preview


import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL:
    process.env.VUE_APP_FIREBASE_DATABASE_URL,
};
firebase.initializeApp(firebaseConfig);

const store = createStore({
  state() {
    return {
      //Menu elements
      menuElements: [],
      displayedElements: [],
      screenHistory: [],

      currentElementMode: "text",

      //User settings
      textElementSettings: null,
      imageElementSettings: null,
      categoryElementSettings: null,
      productElementSettings: null,
      seperationElementSettings: null,
      googleReviewElementSettings: null,
      socialMediaElementSettings: null,

      //general Settings
      backgroundColorSettings: "#FFFFFF",
      mainBackgroundImage: null,
      mainBackgroundImageOverlayColor:
        "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)), ",
      menuBackground: null,
      primaryColorSettings: "#000000",
      secondaryColorSettings: "#edede9",
      currentFont: "Roboto",

      flyingCartButtonIconColor: "#000000",
      flyingCartButtonBackgroundColor: "#edede9",
      flyingCartButtonIcon: "shopping_cart",

      addToCartButtonIconColor: "#000000",
      addToCartButtonIcon: "add",

      cartItems: [],

      showCartComponent: false,
      showCallEmployeeConfirm: false,

      backButtonTextColor: "#000000",
      backButtonBackgroundColor: "#edede9",
      backButtonHeight: 70,
      backButtonWidth: 160,
      backButtonBorderRadius: 30,

      showToast: false,
      currentToastType: ToastTypes.ERROR,
      currentToastMessage: "",
    };
  },
  mutations: {
    //Menu elements
    // pushMenuElement(state, element) {
    //   state.menuElements.push(element);
    // },
    pushDisplayedElement(state, element) {
      state.displayedElements.push(element);
      if (state.screenHistory.length == 0) {
        state.menuElements.push(element);
      } else {
        for (var i = state.screenHistory.length - 1; i >= 0; i--) {
          let parentIndex = state.screenHistory[i].parentIndex;

          state.screenHistory[i].elements[parentIndex].content.child.push(
            element
          );
        }
      }
    },
    pushToScreenHistory(state, payload) {
      state.screenHistory.push({
        elements: payload.elements,
        parentId: payload.parentId,
      });
    },

    popScreenHistory(state) {
      state.screenHistory.pop();
    },

    setCurrentElementMode(state, mode) {
      state.currentElementMode = "temp";
      state.currentElementMode = mode;
    },

    //User settings
    setTextElementSettings(state, textElementSettings) {
      state.textElementSettings = textElementSettings;
    },
    setGoogleReviewElementSettings(state, googleReviewElementSettings) {
      state.googleReviewElementSettings = googleReviewElementSettings;
    },
    setImageElementSettings(state, imageElementSettings) {
      state.imageElementSettings = imageElementSettings;
    },
    setCategoryElementSettings(state, categoryElementSettings) {
      state.categoryElementSettings = categoryElementSettings;
    },
    setProductElementSettings(state, productElementSettings) {
      state.productElementSettings = productElementSettings;
    },
    setSeperationElementSettings(state, seperationElementSettings) {
      state.seperationElementSettings = seperationElementSettings;
    },
    setSocialMediaElementSettings(state, socialMediaElementSettings) {
      state.socialMediaElementSettings = socialMediaElementSettings;
    },

    //general Settings
    setBackgroundColorSettings(state, backgroundColorSettings) {
      state.backgroundColorSettings = backgroundColorSettings;
    },
    setMainBackgroundImage(state, mainBackgroundImage) {
      state.mainBackgroundImage = mainBackgroundImage;
    },
    setMainBackgroundImageOverlayColor(state, mainBackgroundImageOverlayColor) {
      state.mainBackgroundImageOverlayColor = mainBackgroundImageOverlayColor;
    },
    deleteMainBackgroundImageOverlayColor(state) {
      state.mainBackgroundImageOverlayColor = "";
    },
    deleteMainBackgroundImage(state) {
      state.mainBackgroundImage = null;
    },
    setMenuBackground(state, menuBackground) {
      state.menuBackground = menuBackground;
    },
    setPrimaryColorSettings(state, primaryColorSettings) {
      state.primaryColorSettings = primaryColorSettings;
    },
    setSecondaryColorSettings(state, secondaryColorSettings) {
      state.secondaryColorSettings = secondaryColorSettings;
    },
    setCurrentFont(state, currentFont) {
      state.currentFont = currentFont;
    },

    //flying cart button
    setflyingCartButtonIconColor(state, flyingCartButtonIconColor) {
      state.flyingCartButtonIconColor = flyingCartButtonIconColor;
    },

    setflyingCartButtonBackgroundColor(state, flyingCartButtonBackgroundColor) {
      state.flyingCartButtonBackgroundColor = flyingCartButtonBackgroundColor;
    },

    setflyingCartButtonIcon(state, flyingCartButtonIcon) {
      state.flyingCartButtonIcon = flyingCartButtonIcon;
    },

    setAddToCartButtonIconColor(state, addToCartButtonIconColor) {
      state.addToCartButtonIconColor = addToCartButtonIconColor;
    },

    setAddToCartButtonIcon(state, addToCartButtonIcon) {
      state.addToCartButtonIcon = addToCartButtonIcon;
    },

    //Back button
    setBackButtonTextColor(state, backButtonTextColor) {
      state.backButtonTextColor = backButtonTextColor;
    },
    setBackButtonBackgroundColor(state, backButtonBackgroundColor) {
      state.backButtonBackgroundColor = backButtonBackgroundColor;
    },
    setBackButtonHeight(state, backButtonHeight) {
      state.backButtonHeight = backButtonHeight;
    },
    setBackButtonWidth(state, backButtonWidth) {
      state.backButtonWidth = backButtonWidth;
    },
    setBackButtonBorderRadius(state, backButtonBorderRadius) {
      state.backButtonBorderRadius = backButtonBorderRadius;
    },

    //cart

    pushToCart(state, element) {
      state.cartItems.length === 0 ? state.cartItems.push(element) : null;
      for (const existingElement of state.cartItems) {
        if (existingElement.productTitle === element.productTitle) {
          const index = state.cartItems.findIndex(
            (element) => element.productTitle === existingElement.productTitle
          );
          if (isNaN(state.cartItems[index].quantity)) {
            state.cartItems[index].quantity = 0;
          }

          state.cartItems[index].quantity += 1;
          return;
        }

      }
      element.quantity = 1;
      state.cartItems.push(element);

    },
    toggleCart(state) {
      state.showCartComponent = !state.showCartComponent;
    },
    toggleCallEmployeeConfirm(state) {
      state.showCallEmployeeConfirm = !state.showCallEmployeeConfirm;
    },
    configureToast(state, payload) {
      state.showToast = payload.showToast;
      state.currentToastMessage = payload.message;
      state.currentToastType = payload.toastType;
    },
    resetToast(state) {
      state.showToast = false;
    },
  },
  getters: {
    showToast: (state) => {
      return state.showToast;
    },
    currentToastMessage: (state) => {
      return state.currentToastMessage;
    },
    currentToastType: (state) => {
      return state.currentToastType;
    },
  },
});

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .provide("$firebase", firebase)
  .mount("#app");
