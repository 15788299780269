import CommonAPIProvider from "@/common/CommonAPIProvider";

const baseUrl = process.env.VUE_APP_API_BASE;
class ReportsHandler {
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }

  async fetchReports(menuId) {
    const idToken = await CommonAPIProvider.getIdToken();

    return CommonAPIProvider.sendGetRequest(
      `${baseUrl}/reports?menuId=${menuId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      },
    )
  }
  async fetchReportDetails(menuId, docName) {
    const idToken = await CommonAPIProvider.getIdToken();

    return CommonAPIProvider.sendGetRequest(
      `${baseUrl}/reports/${menuId}/${docName}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      },
    )
  }
  async deleteReport(menuId, reportId) {
    const idToken = await CommonAPIProvider.getIdToken();

    return CommonAPIProvider.sendPostRequest(
      `${baseUrl}/reports/${menuId}/${reportId}/delete`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      },
    )
  }
}

export default new ReportsHandler();
