<template>
  <div class="cart" :style="'background: ' + generalSettings.secondaryColor"
    v-if="!orderSubmitted">
    <div class="top">
      <QIcon name="close" class="icon"
        :style="'color: ' + generalSettings.primaryColor + ';'"
        @click="this.$store.commit('toggleCart')"></QIcon>
    </div>
    <h4 :style="'margin-top: -10px;margin-bottom: 0; font-family: ' +
      generalSettings.currentFont +
      '; color: ' +
      generalSettings.primaryColor +
      ';'
      ">
      Warenkorb
    </h4>
    <div v-if="cartItems.length > 0">
      <div style="height: 40vh; margin-top: 1rem; overflow-y: scroll;">
        <div v-for="(cartItem, index) in cartItems" :key="index"
          class="content">
          <div class="product">
            <div class="product-image" v-if="cartItem.image">
              <img :src="cartItem.image" alt="product" />
            </div>
            <div class="product-details">
              <span :style="'color: ' + generalSettings.primaryColor + ';'">{{
      parseHtml(cartItem.productTitle)
    }}</span>
              <span :style="'color: ' + generalSettings.primaryColor + ';'">{{
      parsePriceHtml(cartItem.productPrice) }}
              </span>
            </div>
          </div>
          <div class="quantity">
            <QIcon name="remove" class="quantityIcon"
              @click="decreaseQuantity(index)"
              :style="'color: ' + generalSettings.primaryColor + ';'"></QIcon>
            <span :style="'color: ' + generalSettings.primaryColor + ';'">{{
      cartItem.quantity ? cartItem.quantity + "x" : "1x"
    }}</span>
            <QIcon :style="'color: ' + generalSettings.primaryColor + ';'"
              name="add" class="quantityIcon" @click="increaseQuantity(index)">
            </QIcon>
          </div>
        </div>
      </div>
      <div class="notes-section">

        <textarea v-model="orderNotes" placeholder="Anmerkungen"
          style="color: #000;"></textarea>
      </div>
      <div class="checkout"
        :style="'color: ' + generalSettings.primaryColor + ';'">
        <div class="totalPrice">
          Gesamt: {{ totalPrice.toString().replace(".", ",") }}€
        </div>
        <button class="checkoutButton" :style="'background: ' +
      generalSettings.primaryColor +
      '; color: ' +
      generalSettings.secondaryColor +
      ';'
      " @click="submitOrder">
          <span v-if="!isOrderLoading">Jetzt kostenpflichtig bestellen</span>
          <div v-else>
            <QSpinner :style="'color: ' + generalSettings.secondaryColor + ';'"
              size="2rem"></QSpinner>
          </div>
        </button>
      </div>
    </div>
    <div v-else
      :style="'height: 40vh; display: flex;align-items: center;justify-content: center; color: ' + generalSettings.primaryColor + ';'">
      Warenkorb leer
    </div>
  </div>
  <div v-else class="order-confirmation"
    :style="'background: ' + generalSettings.secondaryColor">
    <span class="thanks"
      :style="'color: ' + generalSettings.primaryColor + ';'"> Vielen Dank für
      deine Bestellung!</span>
    <span :style="'color: ' + generalSettings.primaryColor + ';'"> Wir bringen
      sie schnellstmöglich an deinen Tisch.</span>
  </div>
</template>
<script>
import { QIcon, QSpinner } from "quasar";
import ToastTypes from "@/models/Types/ToastTypes";
import MenuHandler from "@/util/MenuHandler";
//import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

export default {
  props: {
    generalSettings: {
      type: Object,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
    tableNumber: {
      type: Number,
      required: true,
    },
  },
  components: { QIcon, QSpinner },
  data() {
    return {
      isOrderLoading: false,
      orderSubmitted: false,
      orderNotes: "", // New data property for order notes
    };
  },
  computed: {
    cartItems() {
      return this.$store.state.cartItems;
    },
    purifiedCartitems() {
      return this.cartItems.map((item) => {
        return {
          productTitle: this.parseHtml(item.productTitle),
          productPrice: this.parseHtml(item.productPrice),
          quantity: item.quantity ? item.quantity : 1,
          image: item.image,
        };
      });
    },
    totalPrice() {
      let totalPrice = 0;

      for (const item of this.cartItems) {
        totalPrice +=
          parseFloat(this.parsePriceHtml(item.productPrice)) *
          (item.quantity ? item.quantity : 1);
      }

      return totalPrice.toFixed(2);
    },
  },
  methods: {
    async submitOrder() {
      if (!this.isOrderLoading) {
        this.isOrderLoading = true;
        const itemsWithIds = this.purifiedCartitems.map((item) => ({
          ...item
        }));

        try {

          const response = await MenuHandler.submitOrder(this.menuId, this.tableNumber, itemsWithIds, this.orderNotes);

          if (!response.status === 200) {
            this.showToastMessage('Fehler beim Erstellen der Bestellung', ToastTypes.ERROR);
            throw new Error('Error creating order');
          }

          this.showConfirmation();
          this.$store.state.cartItems = this.$store.state.cartItems.map(item => {
            item.quantity = undefined;
          });
          this.$store.state.cartItems = [];
          this.orderNotes = '';
          this.isOrderLoading = false;
        } catch (error) {
          console.error('Error submitting order:', error);
          this.isOrderLoading = false;
        }
      }
    },
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === ToastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    showConfirmation() {
      this.orderSubmitted = true;
      setTimeout(() => {
        this.$store.commit("toggleCart");
        this.orderSubmitted = false;
      }, 5000);
    },

    /* parseProductPrice(priceStr) {
      const match = priceStr.match(/(\d+[,.]?\d*)/);
      if (match) {
        let normalizedPrice = match[0].replace(",", ".");
        return parseFloat(normalizedPrice);
      }
      return 0;
    },
 */
    parseHtml(html) {
      return html.replace(/<\/?[^>]+(>|$)/g, "");
    },
    parsePriceHtml(html) {
      // Strip HTML tags
      const text = html.replace(/<\/?[^>]+(>|$)/g, "");

      // Match valid price format with an optional currency symbol after the number
      // Note: The regex now handles ',' and '.' as decimal separators and an optional currency symbol at the end
      const match = text.match(/(\d+[,.]?\d{0,2})\s*([€$¥£₹]?)/);
      if (match) {
        const valuePart = match[1];
        const currency = match[2];

        // Normalize the number by replacing ',' with '.'
        let value = parseFloat(valuePart.replace(",", "."));

        // If the number is valid, format to two decimal places
        if (!isNaN(value)) {
          value = value.toFixed(2);
          return value + currency; // Append the currency symbol (if any) to the formatted number
        }
      }

      // Return null if no valid price found
      return null;
    },
    increaseQuantity(index) {
      isNaN(this.$store.state.cartItems[index].quantity)
        ? (this.$store.state.cartItems[index].quantity = 2)
        : (this.$store.state.cartItems[index].quantity += 1);
    },
    decreaseQuantity(index) {
      if (isNaN(this.$store.state.cartItems[index].quantity)) {
        this.$store.state.cartItems[index].quantity = 1;
      } else if (this.$store.state.cartItems[index].quantity > 0) {
        this.$store.state.cartItems[index].quantity -= 1;
        if (this.$store.state.cartItems[index].quantity === 0) {
          this.$store.state.cartItems.splice(index, 1);
        }
      }
    },
  },
};

</script>

<style lang="scss">
.overlay {
  height: 600px;
  width: 350px;
  background: black;
  opacity: 0.3;
}

.cart {
  position: absolute;
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%);
  width: 350px;
  border-radius: 30px;
  background: white;
  top: 15%;
  box-sizing: border-box;

  .top {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .icon {
      margin-top: 20px;
      margin-right: 20px;

      font-size: large;
      font-weight: bold;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    padding: 0 1rem;
    box-sizing: border-box;
    overflow-y: scroll;
    font-size: 1rem;

    .product {
      display: flex;
      margin-top: 1rem;
      align-items: center;
      font-size: 1rem;
      justify-content: space-between;

      .product-image {
        img {
          width: 2rem;
        }
      }

      .product-details {
        margin-left: 1rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: bold;
      }
    }

    .quantity {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .quantityIcon {
        font-weight: bold;
        font-size: 1.25rem;
      }
    }
  }

  .notes-section {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.5rem;
    }

    textarea {
      width: 100%;
      height: 4rem;
      border-radius: 5px;
      padding: 0.5rem;
      resize: none;
      border: 1px solid #ccc;
    }
  }

  .checkout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    font-weight: bold;

    .totalPrice {
      font-size: 1rem;
    }

    .checkoutButton {
      padding: 0.5rem;
      border-radius: 25px;
      border: none;
    }
  }
}

.order-confirmation {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%);
  height: 400px;
  width: 350px;
  border-radius: 30px;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-weight: bold;
  flex-direction: column;
  gap: 2rem;
  margin-top: -20%;

  .thanks {
    font-size: 1.25rem;
  }
}
</style>
