import CommonAPIProvider from "@/common/CommonAPIProvider";

const baseUrl = process.env.VUE_APP_API_BASE;
class MenuHandler {
    constructor() {
        if (!this.instance) {
            this.instance = this;
        }
        return this.instance;
    }

    async fetchPublicMenu(menuId) {

        return CommonAPIProvider.sendGetRequest(
            `${baseUrl}/menus/${menuId}/public`,
            {},
        )
    }
    async getMenusForUser(userId, email) {
        const idToken = await CommonAPIProvider.getIdToken();
        return CommonAPIProvider.sendGetRequest(
            `${baseUrl}/menus?userId=${userId}&userEmail=${email}`,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }

    async createNewMenu(userId, title) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus`,
            { userId: userId, newMenuTitle: title },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async fetchInvited(menuId) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendGetRequest(
            `${baseUrl}/menus/${menuId}/invited`,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async changeRole(menuId, email, role) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/updateRole`,
            { menuId, email, role },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async removeEmployee(menuId, email) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/removeEmployee`,
            { menuId, email },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async deleteMenu(menuId) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/${menuId}/delete`, {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async inviteEmployee(menuId, email, role) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/inviteEmployee`,
            { menuId, email, role },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async fetchMenu(menuId) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendGetRequest(
            `${baseUrl}/menus/${menuId}`,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async syncMenu(menuId, elements) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/${menuId}/sync`,
            { displayedElements: elements },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async setupEditMenuBackup(elements, userId, menuId) {
        const idToken = await CommonAPIProvider.getIdToken();
        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/setupEditMenuBackup`,
            { displayedElements: elements, userId, menuId },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async backupPublicMenu(elements, userId, menuId) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/backupPublicMenu`,
            { displayedElements: elements, userId, menuId },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async publishMenu(menuId, elements, userId) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/${menuId}/publish`,
            { displayedElements: elements, userId },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }

    async fetchVersions(menuId, type) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendGetRequest(
            `${baseUrl}/menus/${menuId}/versions/${type}`,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async restoreVersion(menuId, type, versionId) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/${menuId}/versions/${type}/${versionId}/restore`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async addToDailyReport(menuId, items) {
        const idToken = await CommonAPIProvider.getIdToken();

        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/${menuId}/dailyReport`,
            { items },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        )
    }
    async submitOrder(menuId, tableNumber, items, orderNotes) {
        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/${menuId}/order`,
            { tableNumber, items, orderNotes },
        );
    }
    async callEmployee(menuId, tableNumber) {
        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/${menuId}/${tableNumber}/callEmployee`, {}
        );
    }
    async resetNeedsAssistance(menuId, tableNumber) {
        const idToken = await CommonAPIProvider.getIdToken();
        return CommonAPIProvider.sendPostRequest(
            `${baseUrl}/menus/${menuId}/${tableNumber}/resetNeedsAssistance`, {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        );
    }
}

export default new MenuHandler();
