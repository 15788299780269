<template>
  <div class="menuDashboardWrapper">
    <div class="headline">
      <h3>Meine Speisekarten</h3>
      <button @click="signOut" class="signOutButton">
        <QIcon name="logout" />
        Abmelden
      </button>
    </div>
    <div class="dashboardWrapper" v-if="!isLoading">
      <div class="menuGrid">
        <div v-for="(menu, index) in displayMenus" :key="index">
          <div v-if="!menu.isPlaceholder">
            <div class="menu"
              @click="isEditor(menu) ? router.push(`/configure/${menu.title}`) : null"
              :class="{ disabled: !isEditor(menu) }">
              {{ menu.title.toUpperCase() }}
            </div>
            <div class="dashboardButtonWrapper">
              <div class="dashboardButton"
                @click="goToOrderDashboard(menu.title)">
                <span> Dashboard </span>
                <QIcon name="dashboard"></QIcon>
              </div>
            </div>
            <div class="dashboardButtonWrapper">
              <div class="dashboardButton"
                @click="isEditor(menu) ? goToReports(menu.title) : null"
                :class="{ disabled: !isEditor(menu) }">
                <span> Reports </span>
                <QIcon name="analytics"></QIcon>
              </div>
            </div>
          </div>
          <div v-else class="newMenu" @click="initNewMenu">
            <div style="font-size: 100px">
              <QIcon name="add_box"></QIcon>
            </div>
            <div>
              <h6>Neue Speisekarte</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spinnerWrapper" v-else>
      <QSpinner color="light-green" size="3rem" />
    </div>
    <q-dialog v-model="isDialogOpen">
      <q-card>
        <q-card-section>
          <div class="text-h6">Speisekarten Name / ID</div>
          <div>Die Speisekarten ID ist einmalig und kann nicht mehr geändert
            werden.
          </div>
          <div>Sie ist zudem Bestandteil des links der Speisekarte, z.B
            ordersnake.com/menu/{speisekartenID}</div>
        </q-card-section>

        <q-card-section>
          <input type="text" v-model="newMenuTitle"
            placeholder="Speisekarten ID" class="confirmDeleteText"
            id="menu-title-input">
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="ABBRECHEN" color="negative" v-close-popup />
          <q-btn flat label="WEITER" color="light-green"
            @click="createNewMenu" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { QIcon, QSpinner, QDialog, QCard, QCardSection, QCardActions, QBtn } from "quasar";
import ToastTypes from "@/models/Types/ToastTypes";
import { getAuth, signOut } from "firebase/auth";
import MenuHandler from "@/util/MenuHandler";
export default {
  mounted() {
    this.getMenus();
  },
  components: {
    QSpinner,
    QIcon,
    QDialog,
    QCard,
    QCardSection,
    QCardActions,
    QBtn,
  },
  computed: {
    displayMenus() {
      if (this.menus.length === 0) return [{ isPlaceholder: true }];

      let sortedMenus = [...this.menus];

      sortedMenus.sort((a, b) => {
        if (!a.createdAt && !b.createdAt) return 0;
        if (!a.createdAt) return 1;
        if (!b.createdAt) return -1;
        return new Date(a.createdAt) - new Date(b.createdAt);
      });

      sortedMenus.push({ isPlaceholder: true });

      return sortedMenus;
    },
  },
  data() {
    return {
      menus: [],
      isLoading: true,
      isDialogOpen: false,
      newMenuTitle: '',
      isInputInvalid: false,
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    async getMenus() {
      const userId = getAuth().currentUser.uid;
      const userEmail = getAuth().currentUser.email;

      try {
        const response = await MenuHandler.getMenusForUser(userId, userEmail);
        this.menus = response.data;
      } catch (error) {
        console.error("Error fetching menus: ", error);
      } finally {
        this.isLoading = false;
      }
    },
    initNewMenu() {
      this.newMenuTitle = ``;
      this.isDialogOpen = true;
    },
    async createNewMenu() {
      if (this.newMenuTitle.trim() === '') {
        this.showToastMessage("Der Speisekartenname darf nicht leer sein.", ToastTypes.ERROR, "menu-title-input");
        this.isInputInvalid = true;
        return;
      }

      const userId = getAuth().currentUser.uid;
      try {
        const response = await MenuHandler.createNewMenu(userId, this.newMenuTitle);

        this.$router.push(`/configure/${response.data.title}`);
        this.isDialogOpen = false;
        this.isInputInvalid = false; // Reset the input invalid state
      } catch (error) {
        console.error("Error adding new menu: ", error);
        this.showToastMessage("Error adding new menu", ToastTypes.ERROR, "menu-title-input");
      }
    },
    goToOrderDashboard(menuId) {
      this.$router.push(`/orders/${menuId}`);
    },
    goToReports(menuId) {
      this.$router.push(`/reports/${menuId}`);
    },
    isEditor(menu) {
      const userEmail = getAuth().currentUser.email;
      const userId = getAuth().currentUser.uid;
      if (menu.userId === userId) return true; // Replace with the logic to get the current user's ID
      const invitedUser = menu.invited.find(invitee => invitee.email === userEmail);
      return invitedUser && invitedUser.role === 'editor';
    },
    signOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.push('/login');
      }).catch((error) => {
        console.error("Error signing out: ", error);
      });
    },
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === ToastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
  },
};

</script>

<style lang="scss">
@import "scss/colors.scss";

.headline {
  text-align: start;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $tablet) {
    h3 {
      font-size: 2rem;
    }

    ;
  }

  @media (max-width: $mobile) {
    h3 {
      font-size: 1.5rem;
    }

    ;
  }

  .signOutButton {
    color: $green-poison;
    background: none;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($green-poison, 10%);
      color: white;
    }
  }
}

.dashboardWrapper {
  .menuGrid {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: $tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $mobile) {
      grid-template-columns: repeat(1, 1fr);
    }

    gap: 4rem;

    .menu {
      box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%);
      max-width: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20rem;
      flex-direction: column;
      transition: 0.3s ease;
      border-radius: 25px;
      font-weight: bold;
      font-size: 1.5rem;
      color: $green-poison;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
        pointer-events: none;
      }

      &:hover {
        background-color: $grey-lighter;
      }

      .menuPreview {
        transform: scale(0.3);
      }
    }

    .dashboardButtonWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .dashboardButton {
        width: fit-content;
        gap: 1rem;
        font-size: 1.25rem;
        color: $green-poison;
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        transition: 0.3s ease;
        border-radius: 25px;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.6;
          pointer-events: none;
        }

        &:hover {
          background-color: $grey-lighter;
        }
      }

      .spinner {
        position: absolute;
        right: 20%;
      }
    }
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;

  .spinner {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.newMenu {
  color: $green-poison;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.3s ease;
  border-radius: 25px;
  max-width: 30vw;

  @media (max-width: $tablet) {
    max-width: 40vw;
  }

  @media (max-width: $mobile) {
    max-width: none;
  }

  cursor: pointer;

  &:hover {
    background-color: $grey-lighter;
  }
}

.menuDashboardWrapper {
  padding: 0 5rem;



  @media (max-width: $tablet) {
    padding: 0 3rem;
  }

  @media (max-width: $mobile) {
    padding: 0 1rem;
  }

  @media (min-width: $default-desktop) {
    padding: 0 7rem;
  }

  @media (min-width: $big-desktop) {
    padding: 0 10rem;
  }

}

.invalid {
  border-color: red;
}
</style>
