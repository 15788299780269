<template>
    <div v-show="show" class="insert-options q-ma-sm q-gutter-sm">
        <div style="margin: 0;">
            <q-btn flat round icon="text_fields"
                @click="insertElement('text', 'Text', 'text_fields')" />
            <q-btn flat round icon="image"
                @click="insertElement('image', 'Bild', 'image')" />
            <q-btn flat round icon="category"
                @click="insertElement('category', 'Kategorie', 'category')" />
        </div>
        <div style="margin: 0;">
            <q-btn flat round icon="sell"
                @click="insertElement('product', 'Produkt', 'sell')" />
            <q-btn flat round icon="horizontal_rule"
                @click="insertElement('seperation', 'Abtrennung', 'horizontal_rule')" />
            <q-btn flat round icon="hotel_class"
                @click="insertElement('googleReview', 'Google', 'hotel_class')" />
            <q-btn flat round icon="groups"
                @click="insertElement('socialMedia', 'Socials', 'groups')" />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: {
        index: {
            type: Number,
            required: true
        },
        elements: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        insertElement(elementType, text, icon) {

            //this.elements.splice(this.index + 1, 0, newElement);
            this.$emit('element-inserted', { elementType: elementType, index: this.index, label: text, icon: icon });
        }
    }
}
</script>

<style scoped lang="scss">
.insert-options {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding: 5px 13px;
    background: #eee;
    border-radius: 25px;
    width: 55%;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%);
    z-index: 100000;
}
</style>