<template>
  <div class="textEditor">
    <div class="toolbar">
      <div class="alignments">
        <button class="toolbarButton" style="margin-left: 5px"
          @click="editor.chain().focus().setTextAlign('left').run()">
          <QIcon name="format_align_left" class="icon"></QIcon>
        </button>
        <button class="toolbarButton"
          @click="editor.chain().focus().setTextAlign('center').run()">
          <QIcon name="format_align_center" class="icon"></QIcon>
        </button>
        <button class="toolbarButton"
          @click="editor.chain().focus().setTextAlign('right').run()">
          <QIcon name="format_align_right" class="icon"></QIcon>
        </button>
        <button class="toolbarButton"
          @click="editor.chain().focus().setTextAlign('justify').run()">
          <QIcon name="format_align_justify" class="icon"></QIcon>
        </button>
      </div>
      <div class="textStyle">
        <button class="toolbarButton" style="margin-left: 5px; margin-top: 3px"
          @click="editor.chain().focus().toggleBold().run()">
          <QIcon name="format_bold" class="icon"></QIcon>
        </button>
        <button class="toolbarButton" style="margin-top: 3px"
          @click="editor.chain().focus().toggleItalic().run()">
          <QIcon name="format_italic" class="icon"></QIcon>
        </button>
      </div>
      <div class="moreProperties">
        <div class="currentEditorColor"
          :style="{ 'background': currentPickedColor }">
          <QPopupProxy cover transition-show="scale" transition-hide="scale"
            style="margin-left: 10px">
            <QColor v-model="currentPickedColor" @update:model-value="editor.chain().focus().setColor(currentPickedColor).run();
          editor.commands.setColor(currentPickedColor);" />
          </QPopupProxy>
        </div>

        <QBtnDropdown dropdown-icon="expand_more" icon="format_size"
          :label="fontSize" :auto-close="true" size="10px" :flat="true"
          style="width: 80px">
          <QList v-for="option in fontSizeOptions" :key="option">
            <QItem clickable v-close-popup
              @click="editor.chain().focus().setFontSize(option + 'pt').run(); fontSize = option;">
              <QItemSection>
                <QItemLabel>
                  {{ option }}
                </QItemLabel>
              </QItemSection>
            </QItem>
          </QList>
        </QBtnDropdown>

        <QBtnDropdown dropdown-icon="expand_more" :label="currentFont"
          :auto-close="true" size="10px" :flat="true"
          style="width: 80px; margin-left: 5px">
          <QList v-for="option in fontFamilyOptions" :key="option">
            <QItem clickable v-close-popup
              @click="editor.chain().focus().setFontFamily(option).run(); currentFont = option;">
              <QItemSection>
                <QItemLabel :style="'font-family: ' + option">
                  {{ option }}
                </QItemLabel>
              </QItemSection>
            </QItem>
          </QList>
        </QBtnDropdown>
      </div>
    </div>
    <editor-content :editor="editor" class="editors-content"
      :style="'font-size:' + defaultFontSize + 'pt; color: ' + currentPickedColor + '; font-family: ' + currentFont + '; '" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import { QIcon, QBtnDropdown, QList, QItemSection, QItemLabel, QItem, QColor, QPopupProxy } from "quasar";
import TextAlign from "@tiptap/extension-text-align";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";
import Strike from "@tiptap/extension-strike";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import FontSize from "tiptap-extension-font-size";

export default {
  components: {
    EditorContent,
    QIcon,
    QBtnDropdown,
    QList,
    QItemSection,
    QItemLabel,
    QItem,
    QPopupProxy,
    QColor,
  },

  props: {
    modelValue: {
      type: String,
      default: "",
    },
    defaultFontSize: {
      type: Number,
      default: 0,
    },
    generalSettings: {
      type: Object,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      editor: null,
      fontSize: this.defaultFontSize,
      fontSizeOptions: [
        6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50,
      ],
      currentFont: this.generalSettings?.currentFont ? this.generalSettings?.currentFont : "Roboto",
      fontFamilyOptions: [
        "Roboto", "Inter", "Sans-serif", "Arial", "Verdana", "Times New Roman", "Courier New", "Georgia", "Palatino", "Garamond", "Bookman", "Comic Sans MS", "Trebuchet MS", "Arial Black", "Impact"
      ],
      currentPickedColor: this.generalSettings?.primaryColor ? this.generalSettings?.primaryColor : "#000000",
    };
  },

  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
    "generalSettings.primaryColor": function () {
      this.currentPickedColor = this.generalSettings?.primaryColor;
      this.editor.chain().focus().setColor(this.currentPickedColor).run();

    },
    "generalSettings.currentFont": function () {
      this.currentFont = this.generalSettings?.currentFont;
      this.editor.chain().focus().setFontFamily(this.currentFont).run();
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Heading,
        TextAlign.configure({
          types: ["heading", "paragraph"],
          defaultAlignment: "center",
        }),
        Bold,
        Italic,
        Underline,
        Strike,
        TextStyle,
        Color,
        FontFamily,
        FontSize,
      ],
      content: this.modelValue,
      onUpdate: () => {
        // HTML
        this.$emit("update:modelValue", this.editor.getHTML());

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    });
  },

  beforeUnmount() {
    this.editor?.destroy();
  },
};
</script>

<style lang="scss">
@import "scss/colors.scss";

.textEditor {
  border-radius: 10px;
  background-color: $grey-light;
  margin-top: 25px;

  .toolbar {
    display: flex;
    justify-content: flex-start;

    .alignments {
      // border: solid 1px $grey-medium;
      border-top-left-radius: 10px;
      border-bottom: none;
    }

    .textStyle {
      // border: solid 1px $grey-medium;
      width: 80px;
      border-bottom: none;
      border-left: none;
      display: flex;
      justify-content: space-around;
    }

    .moreProperties {
      border-top-right-radius: 10px;
      // border: solid 1px $grey-medium;
      border-bottom: none;
      border-left: none;
      width: 240px;

      display: flex;
      justify-content: space-around;

      input {
        height: 25px;
        width: 25px;
      }

      .dropdownButton {
        height: 25px;
        width: 75px;
        background-color: white;
        border: none;
        border-radius: 3px;
        padding: 1px;
        margin-left: 2px;
        margin-right: 2px;
        margin-top: 2px;
        margin-bottom: 2px;

        transition: 0.3s;

        .icon {
          font-size: 18px;
          margin-bottom: 4px;
        }

        &:hover {
          background-color: $grey-medium;

          cursor: pointer;
        }
      }
    }
  }

  .editors-content {
    // border: solid 1px $grey-medium;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow-y: scroll;

    height: 50px;

    padding: 10px;

    p {
      margin: 0px;
    }

    .ProseMirror:focus {
      outline: none;
    }
  }
}

.toolbarButton {
  height: 25px;
  width: 25px;
  background-color: $grey-light;
  border: none;
  border-radius: 3px;
  padding: 1px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 2px;

  transition: 0.3s;

  .icon {
    font-size: 18px;
    margin-bottom: 4px;
  }

  &:hover {
    background-color: $grey-medium;

    cursor: pointer;
  }
}

.currentEditorColor {
  height: 17px;
  width: 23px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 6px;
  border-radius: 5px;
  border: solid 1px black;

  &:hover {
    cursor: pointer;
  }
}
</style>
