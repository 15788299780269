<template>
  <div v-if="show">
    <div class="textElementBody">
      <div>
        <div style="text-align: center">
          <MyTextEditor v-model="localEditElement.content.text" :generalSettings="generalSettings"></MyTextEditor>
        </div>
        <div style="margin-top: 20px">
          <h6>Textabstand</h6>
        </div>
        <div>
          <label>Oben: </label>
          <input
            type="number"
            min="0"
            max="500"
            v-model="localEditElement.textStyleValues.marginTop"
            @input="
              buildTextStylePropertiesString(
                'margin-top',
                localEditElement.textStyleValues.marginTop + 'px'
              )
            "
          />
          <label style="margin-left: 10px">Unten: </label>
          <input
            type="number"
            min="0"
            max="500"
            v-model="localEditElement.textStyleValues.marginBottom"
            @input="
              buildTextStylePropertiesString(
                'margin-bottom',
                localEditElement.textStyleValues.marginBottom + 'px'
              )
            "
          />
          <label style="margin-left: 10px">Links: </label>
          <input
            type="number"
            min="0"
            max="500"
            v-model="localEditElement.textStyleValues.marginLeft"
            @input="
              buildTextStylePropertiesString(
                'margin-left',
                localEditElement.textStyleValues.marginLeft + 'px'
              )
            "
          />
          <label style="margin-left: 10px">Rechts: </label>
          <input
            type="number"
            min="0"
            max="500"
            v-model="localEditElement.textStyleValues.marginRight"
            @input="
              buildTextStylePropertiesString(
                'margin-right',
                localEditElement.textStyleValues.marginRight + 'px'
              )
            "
          />
        </div>
      </div>
      <div class="saveTextElementBtn">
        <MyButton buttonStyle="btn btn-medium" @click="saveTextElement()">
          Speichern</MyButton
        >
      </div>
    </div>
  </div>
</template>
<script>
import MyButton from "../shared/MyButton.vue";
import MyTextEditor from "../shared/MyTextEditor.vue";
import buildStylePropertiesString from "../../stylePropertyStringBuilder";
//import { v4 as uuidv4 } from "uuid";
//import cloneDeep from "lodash/cloneDeep";
export default {
  components: {
    MyButton,
    MyTextEditor,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    currentEditElement: {
      type: Object,
      default: null,
    },
    generalSettings: {
      type: Object,
      default: null,
    },
  },

  created() {
    if (this.$store.state.currentElementMode == "text") {
      this.assignProperties();
    }
  },

  data() {
    return {
      localEditElement: {
        initialized: true,
        content: {
          text: "Text",
        },
        textStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        textstyleProperties: "",
      },
    };
  },

  watch: {
    "currentEditElement.elementId": {
      handler: function (newValue, oldValue) {
        if (
          this.$store.state.currentElementMode == "text" &&
          oldValue !== newValue
        ) {
          this.localEditElement = {
            initialized: true,
            content: {
              text: "Text",
            },
            textStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
            },
            textstyleProperties: "",
          };
          this.assignProperties();
        }
      },
      deep: true,
    },
    localEditElement: {
      handler: function () {
        if (this.localEditElement) {
          this.$emit("update:currentEditElement", this.localEditElement);
        }
      },
      deep: true,
    },

    "generalSettings.primaryColor": function () {
      this.buildTextStylePropertiesString(
        "color",
        this.generalSettings.primaryColor
      );
    },
    "generalSettings.currentFont": function () {
      this.buildTextStylePropertiesString(
        "font-family",
        this.generalSettings.currentFont
      );
    },
  },

  methods: {
    assignProperties() {
      var textUserSettings = this.$store.state.textElementSettings;
      this.localEditElement.elementId = this.currentEditElement.elementId;
      this.localEditElement.elementType = this.currentEditElement.elementType;
      this.localEditElement.initialized = true;
      this.localEditElement.content.text = this.currentEditElement.content?.text
        ? this.currentEditElement.content.text
        : "Text";
      if (JSON.stringify(textUserSettings) !== "{}") {
        this.localEditElement.textstyleProperties = textUserSettings.textStyle;
        this.localEditElement.textStyleValues.marginTop =
          textUserSettings.textStyleValues.marginTop;
        this.localEditElement.textStyleValues.marginBottom =
          textUserSettings.textStyleValues.marginBottom;
        this.localEditElement.textStyleValues.marginLeft =
          textUserSettings.textStyleValues.marginLeft;
        this.localEditElement.textStyleValues.marginRight =
          textUserSettings.textStyleValues.marginRight;
      } else {
        this.localEditElement.textstyleProperties =
          "color: " +
          (this.generalSettings.primaryColor
            ? this.generalSettings.primaryColor
            : "#000000") +
          "; font-family: " +
          (this.generalSettings.currentFont
            ? this.generalSettings.currentFont
            : "Roboto") +
          "; font-size: 20pt;";
          this.buildTextStylePropertiesString("margin-top", 20 + "px");
          this.buildTextStylePropertiesString("margin-bottom", 0 + "px");
          this.buildTextStylePropertiesString("margin-right", 0 + "px");
          this.buildTextStylePropertiesString("margin-left", 0 + "px");
          this.localEditElement.textStyleValues.marginTop = 20;
          this.localEditElement.textStyleValues.marginBottom = 0;
          this.localEditElement.textStyleValues.marginLeft = 0;
          this.localEditElement.textStyleValues.marginRight = 0;
          //this.$emit("updateTextStyle", this.currentTextStyleProperties);
      }
    },
    buildTextStylePropertiesString(styleProperty, value) {
      this.localEditElement.textstyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.textstyleProperties
      );

      this.$emit("update:currentEditElement", this.localEditElement);
    },
    saveTextElement() {
      const textElementSettings = {
        textStyle: this.localEditElement.textstyleProperties,
        textStyleValues: {
          marginTop: this.localEditElement.textStyleValues.marginTop,
          marginBottom: this.localEditElement.textStyleValues.marginBottom,
          marginLeft: this.localEditElement.textStyleValues.marginLeft,
          marginRight: this.localEditElement.textStyleValues.marginRight,
        },
      }; /*
      this.$store.commit("pushDisplayedElement", {
        elementType: "text",
        //elementId: uuidv4(),
        content: {
          text: this.localEditElement?.content?.text,
          subtext: null,
          pricetext: null,
          image: null,
          child: null,
        },
        textstyleProperties: this.localEditElement?.textstyleProperties,
        subTextStyleProperties: null,
        priceTextStyleProperties: null,
        elementStyleProperties: null,
        imageStyleProperties: null,
        settings: textElementSettings,
      });

      this.textEditorValue = "Text"; */
      this.$store.commit("setTextElementSettings", textElementSettings);
      this.$emit("hideDialog");
    },
  },
};
</script>
<style lang="scss">
.textElementBody {
  text-align: left;

  //   padding-top: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .saveTextElementBtn {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
