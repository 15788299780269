<template>
  <RouterView />
  <MyToast :show="this.$store.getters.showToast"
    :toastType="this.$store.getters.currentToastType"
    :toastMessage="this.$store.getters.currentToastMessage"></MyToast>
</template>

<script>
import MyToast from "./components/shared/MyToast.vue";
export default {
  name: "App",
  components: {
    MyToast,
  },
};
</script>

<style lang="scss">
@import "scss/colors.scss";

#app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  color: black;

  overflow-x: hidden;
/* 
  p {
    margin: 0 !important;
    max-height: 30pt !important;
  } */

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $grey-medium;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $grey-medium;
  }
}
</style>
