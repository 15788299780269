<template>
  <div>
    <button :class="buttonStyle" type="button" :disabled="isDisabled"><slot></slot></button>
  </div>
</template>
<script>
export default {
  props: ["buttonStyle", "isDisabled"],
  components: {},
};
</script>
<style lang="scss">
@import "scss/colors.scss";

.btn {
  transition: 0.3s;
  margin: 20px 5px 5px 5px;
  background-color: $green-poison;
  border: none;
  border-radius: 10px;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%);
  &-medium {
    font-size: medium;
    padding: 10px;
    color: white;
    font-weight: 400;
  }
  &-large {
    font-size: larger;
  }
  &-menuCreation {
    border-radius: 25px;
    background-color: $grey-light;
    font-size: 60px;
    width: 170px;
    height: 130px;
    margin: 0;
  }

  &-backButton {
    border-radius: 30px;
    background-color: $grey-light;
    font-size: 20px;
    margin: 0;
    font-weight: bold;
    padding: 20px;
    position: absolute;
    top: 87%;
    right: 29%;

  }

  span {
    margin-top: 3px;
  }
  .icon {
    margin-left: 30px;
    margin-bottom: 3px;
  }

  &:hover {
    cursor: pointer;
    transform: translate(0px, 3px);
  }
}
</style>
