<template>
  <div v-if="show">
    <div class="categoryElementBody">
      <div>
        <div style="text-align: center">
          <MyTextEditor v-model="localEditElement.content.text"
            :generalSettings="generalSettings" :defaultFontSize="30">
          </MyTextEditor>
        </div>
        <div style="margin-bottom: 25px">
          <div style="margin-top: 20px">
            <h6>Textabstand</h6>
          </div>
          <div>
            <label>Oben: </label>
            <input type="number" min="-500" max="500"
              v-model="localEditElement.textStyleValues.marginTop" @input="
    buildTextStylePropertiesString(
      'margin-top',
      localEditElement.textStyleValues.marginTop -
      (localEditElement.content.image ? 194 : 0) +
      'px'
    )
    " />
            <label style="margin-left: 10px">Unten: </label>
            <input type="number" min="0" max="500"
              v-model="localEditElement.textStyleValues.marginBottom" @input="
    buildTextStylePropertiesString(
      'margin-bottom',
      localEditElement.textStyleValues.marginBottom -
      (localEditElement.content.image ? 194 : 0) +
      'px'
    )
    " />
            <label style="margin-left: 10px">Links: </label>
            <input type="number" min="0" max="500"
              v-model="localEditElement.textStyleValues.marginLeft" @input="
    buildTextStylePropertiesString(
      'margin-left',
      localEditElement.textStyleValues.marginLeft -
      (localEditElement.content.image ? 194 : 0) +
      'px'
    )
    " />
            <label style="margin-left: 10px">Rechts: </label>
            <input type="number" min="0" max="500"
              v-model="localEditElement.textStyleValues.marginRight" @input="
    buildTextStylePropertiesString(
      'margin-right',
      localEditElement.textStyleValues.marginRight -
      (localEditElement.content.image ? 194 : 0) +
      'px'
    )
    " />
          </div>
          <div>
            <h6 style="margin-top: 20px">Elementabstand</h6>
          </div>
          <div>
            <label>Oben: </label>
            <input type="number" min="0" max="500"
              v-model="localEditElement.elementStyleValues.marginTop" @input="
    buildElementStylePropertiesString(
      'margin-top',
      localEditElement.elementStyleValues.marginTop + 'px'
    )
    " />
            <label style="margin-left: 20px">Unten: </label>
            <input type="number" min="0" max="500"
              v-model="localEditElement.elementStyleValues.marginBottom" @input="
    buildElementStylePropertiesString(
      'margin-bottom',
      localEditElement.elementStyleValues.marginBottom + 'px'
    )
    " />
          </div>
          <div>
            <h6 style="margin-top: 20px">Größe</h6>
          </div>
          <div>
            <label>Höhe: </label>
            <input type="number" min="20" max="500"
              v-model="localEditElement.elementStyleValues.height" @input="
    buildElementStylePropertiesString(
      'height',
      localEditElement.elementStyleValues.height + 'px'
    )
    " />
            <label style="margin-left: 20px">Breite: </label>
            <input type="number" min="50" max="350"
              v-model="localEditElement.elementStyleValues.width" @input="
    buildElementStylePropertiesString(
      'width',
      localEditElement.elementStyleValues.width + 'px'
    )
    " />
          </div>
          <div style="
              margin-top: 20px;
              margin-bottom: 15px;
              display: flex;
              justify-content: space-between;
            ">
            <span
              style="margin-right: 20px; font-size: 1.25rem; font-weight: 500">
              Hintergrund
            </span>
            <QBtnDropdown size="11px" dropdown-icon="expand_more" :icon="localEditElement.elementStyleValues
    .selectedCategoryBackgroundDropdownElement.icon
    " :label="localEditElement.elementStyleValues
    .selectedCategoryBackgroundDropdownElement.label
    " :auto-close="true" style="border-radius: 10px; margin-right: 5px">
              <QList>
                <QItem clickable v-close-popup @click="
    selectCategoryBackgroundDropdownOption('image', 'Bild')
    ">
                  <QItemSection>
                    <QItemLabel>
                      <QIcon name="image"></QIcon>
                      Bild
                    </QItemLabel>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup @click="
    selectCategoryBackgroundDropdownOption('palette', 'Farbe');
  localEditElement.elementStyleValues.background =
    generalSettings.secondaryColor;
  ">
                  <QItemSection>
                    <QItemLabel>
                      <QIcon name="palette"></QIcon>
                      Farbe
                    </QItemLabel>
                  </QItemSection>
                </QItem>
                <QItem clickable v-close-popup @click="
    selectCategoryBackgroundDropdownOption(
      'gradient',
      'Gradient'
    );
  localEditElement.elementStyleValues.showOverlayColorProperty = false;
  ">
                  <QItemSection>
                    <QItemLabel>
                      <QIcon name="gradient"></QIcon>
                      Gradient
                    </QItemLabel>
                  </QItemSection>
                </QItem>
              </QList>
            </QBtnDropdown>
          </div>
          <div style="display: flex" v-if="localEditElement.elementStyleValues
    .selectedCategoryBackgroundDropdownElement.icon == 'palette'
    ">
            <label>Farbe: </label>
            <div class="currentColor" :style="{
    background: localEditElement.elementStyleValues.background,
  }">
              <QPopupProxy cover transition-show="scale"
                transition-hide="scale">
                <QColor v-model="localEditElement.elementStyleValues.background"
                  @update:model-value="
    buildElementStylePropertiesString(
      'background',
      localEditElement.elementStyleValues.background
    )
    " />
              </QPopupProxy>
            </div>
          </div>
          <div style="display: flex" v-if="localEditElement.elementStyleValues
    .selectedCategoryBackgroundDropdownElement.icon == 'gradient'
    ">
            <label>Farbe 1: </label>
            <div class="currentColor" :style="{
    background: localEditElement.elementStyleValues.gradientColor1,
  }">
              <QPopupProxy cover transition-show="scale"
                transition-hide="scale">
                <QColor
                  v-model="localEditElement.elementStyleValues.gradientColor1"
                  @update:model-value="setGradientBackground()" />
              </QPopupProxy>
            </div>
            <label style="margin-left: 20px">Farbe 2: </label>
            <div class="currentColor" :style="{
    background: localEditElement.elementStyleValues.gradientColor2,
  }">
              <QPopupProxy cover transition-show="scale"
                transition-hide="scale">
                <QColor
                  v-model="localEditElement.elementStyleValues.gradientColor2"
                  @update:model-value="setGradientBackground()" />
              </QPopupProxy>
            </div>
            <label style="margin-left: 20px">Winkel: </label>
            <input type="number" min="0" max="500"
              v-model="localEditElement.elementStyleValues.gradientAngle"
              @input="setGradientBackground()" />
          </div>
          <div style="display: flex; flex-direction: column" v-if="localEditElement.elementStyleValues
    .selectedCategoryBackgroundDropdownElement.icon == 'image'
    ">
            <div style="display: flex; align-items: center">
              <label>Bild: </label>
              <div class="imageUploadPlaceholder">
                <label v-if="!localEditElement.content.image && !isImageUploadLoading
    " style="font-size: x-small">Kein Bild ausgewählt</label>
                <QSpinner v-else-if="isImageUploadLoading" color="light-green"
                  size="1rem">
                </QSpinner>
                <img v-else :src="this.localEditElement.content.image" />
              </div>
              <div v-if="this.localEditElement.content.image"
                class="optionIconDelete" @click="deleteImage()">
                <QIcon name="delete"></QIcon>
              </div>

              <div class="optionIconSearch">
                <QIcon name="search" style="position: fixed">
                  <QPopupProxy cover transition-show="scale"
                    transition-hide="scale" style="border-radius: 20px">
                    <PixabaySearch :isImageUploadLoading="isImageUploadLoading"
                      @imageSelected="(img) => setPixabayImage(img)">
                    </PixabaySearch>
                  </QPopupProxy>
                </QIcon>
              </div>

              <div class="optionIconUpload">
                <QIcon name="upload" style="position: fixed"></QIcon>
                <input class="fileInput" type="file" :multiple="false"
                  id="imageCategoryUpload" accept="image/*"
                  style="opacity: 0; position: fixed; height: 24px; width: 29px"
                  @change="handleImageUpload" />
              </div>
            </div>
            <div style="margin-top: 20px">
              <label>Rundung: </label>

              <input type="number" min="0" max="100"
                v-model="localEditElement.elementStyleValues.borderRadius"
                @input="
    buildElementStylePropertiesString(
      'border-radius',
      localEditElement.elementStyleValues.borderRadius + 'px'
    );
  buildImageStylePropertiesString(
    'border-radius',
    localEditElement.elementStyleValues.borderRadius + 'px'
  );
  " />
            </div>
            <div>
              <div style="
                  display: flex;
                  align-items: flex-start;
                  gap: 20px;
                  margin-top: 20px;
                " v-if="localEditElement.elementStyleValues.showOverlayColorProperty
    ">
                <h6>Deckfarbe</h6>
                <QBtnDropdown size="11px" dropdown-icon="expand_more" :icon="localEditElement.elementStyleValues
    .selectedOverlayTypeDropdownElement.icon
    " :label="localEditElement.elementStyleValues
    .selectedOverlayTypeDropdownElement.label
    " :auto-close="true" style="border-radius: 10px; margin-right: 5px">
                  <QList>
                    <QItem clickable v-close-popup @click="
    selectOverlayTypeDropdownOption('palette', 'Farbe');
  localEditElement.elementStyleValues.background =
    generalSettings.secondaryColor;
  ">
                      <QItemSection>
                        <QItemLabel>
                          <QIcon name="palette"></QIcon>
                          Farbe
                        </QItemLabel>
                      </QItemSection>
                    </QItem>
                    <QItem clickable v-close-popup @click="
    selectOverlayTypeDropdownOption('gradient', 'Gradient')
    ">
                      <QItemSection>
                        <QItemLabel>
                          <QIcon name="gradient"></QIcon>
                          Gradient
                        </QItemLabel>
                      </QItemSection>
                    </QItem>
                  </QList>
                </QBtnDropdown>
              </div>
              <div style="display: flex" v-if="localEditElement.elementStyleValues
    .showOverlayColorProperty &&
    localEditElement.elementStyleValues
      .selectedOverlayTypeDropdownElement.icon == 'palette'
    ">
                <label>Farbe:</label>
                <div class="currentColor" :style="{
    background: localEditElement.elementStyleValues.background,
  }">
                  <QPopupProxy cover transition-show="scale"
                    transition-hide="scale" style="margin-left: 10px">
                    <QColor
                      v-model="localEditElement.elementStyleValues.background"
                      @update:model-value="
    buildElementStylePropertiesString(
      'background',
      localEditElement.elementStyleValues.background
    )
    " />
                  </QPopupProxy>
                </div>

                <label style="margin-left: 10px">Tranzparenz: </label>

                <input style="margin-left: 10px" type="number" min="0" max="100"
                  v-model="localEditElement.elementStyleValues.opacity" @input="
    buildImageStylePropertiesString(
      'opacity',
      localEditElement.elementStyleValues.opacity + '%'
    )
    " />
              </div>
              <div style="display: flex; flex-direction: column" v-else-if="localEditElement.elementStyleValues
    .showOverlayColorProperty &&
    localEditElement.elementStyleValues
      .selectedOverlayTypeDropdownElement.icon == 'gradient'
    ">
                <div style="display: flex">
                  <label>Farbe 1: </label>
                  <div class="currentColor" :style="{
    background:
      localEditElement.elementStyleValues.gradientColor1,
  }">
                    <QPopupProxy cover transition-show="scale"
                      transition-hide="scale">
                      <QColor v-model="localEditElement.elementStyleValues.gradientColor1
    " @update:model-value="setGradientBackground()" />
                    </QPopupProxy>
                  </div>
                  <label style="margin-left: 20px">Farbe 2: </label>
                  <div class="currentColor" :style="{
    background:
      localEditElement.elementStyleValues.gradientColor2,
  }">
                    <QPopupProxy cover transition-show="scale"
                      transition-hide="scale">
                      <QColor v-model="localEditElement.elementStyleValues.gradientColor2
    " @update:model-value="setGradientBackground()" />
                    </QPopupProxy>
                  </div>
                  <label style="margin-left: 20px">Winkel: </label>
                  <input type="number" min="0" max="500"
                    v-model="localEditElement.elementStyleValues.gradientAngle"
                    @input="setGradientBackground()" />
                </div>
                <div style="margin-top: 20px">
                  <label>Tranzparenz: </label>

                  <input style="margin-left: 10px" type="number" min="0"
                    max="100"
                    v-model="localEditElement.elementStyleValues.opacity"
                    @input="
    buildImageStylePropertiesString(
      'opacity',
      localEditElement.elementStyleValues.opacity + '%'
    )
    " />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="saveCategoryElementBtn">
          <MyButton buttonStyle="btn btn-medium" @click="saveCategoryElement()">
            Speichern</MyButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyButton from "../shared/MyButton.vue";
import MyTextEditor from "../shared/MyTextEditor.vue";
import {
  QBtnDropdown,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QIcon,
  QPopupProxy,
  QSpinner,
  QColor,
} from "quasar";
import PixabaySearch from "./components/CategoryPixabaySearch.vue";
import buildStylePropertiesString from "../../stylePropertyStringBuilder";
import AssetHandler from "@/util/AssetHandler";
import cloneDeep from "lodash/cloneDeep";
export default {
  components: {
    MyButton,
    QBtnDropdown,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QIcon,
    QPopupProxy,
    QColor,
    QSpinner,
    PixabaySearch,
    MyTextEditor,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentEditElement: {
      type: Object,
      default: null,
    },
    generalSettings: {
      type: Object,
      default: null,
    },
    menuId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isImageUploadLoading: false,
      localEditElement: {
        initialized: true,
        content: {
          text: "Text",
          image: "",
          child: [],
        },
        textStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        elementStyleValues: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          height: 0,
          width: 0,
          opacity: 0,
          borderRadius: 0,
          background: this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9",
          gradientColor1: this.generalSettings.primaryColor
            ? this.generalSettings.primaryColor
            : "#000000",
          gradientColor2: this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9",
          gradientAngle: 135,

          gradientBackground:
            "linear-gradient(180deg, rgba(0,0,0,1), rgba(255,255,255,1)",

          showOverlayColorProperty: false,
          selectedCategoryBackgroundDropdownElement: {
            icon: "image",
            label: "Bild",
          },
          selectedOverlayTypeDropdownElement: {
            icon: "palette",
            label: "Farbe",
          },
          primaryColor: this.generalSettings.primaryColor
            ? this.generalSettings.primaryColor
            : "#000000",
        },
        textstyleProperties: "",
        imageStyleProperties: "",
        elementStyleProperties: "",
      },
    };
  },
  watch: {
    "currentEditElement.elementId": {
      handler: function (newValue, oldValue) {
        if (
          this.$store.state.currentElementMode == "category" &&
          oldValue !== newValue
        ) {
          (this.localEditElement = {
            initialized: true,
            content: {
              text: "Text",
              image: "",
              child: [],
            },
            textStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
            },
            elementStyleValues: {
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              height: 0,
              width: 0,
              opacity: 90,
              borderRadius: 0,
              background: this.generalSettings.secondaryColor
                ? this.generalSettings.secondaryColor
                : "#edede9",
              gradientColor1: this.generalSettings.primaryColor
                ? this.generalSettings.primaryColor
                : "#000000",
              gradientColor2: this.generalSettings.secondaryColor
                ? this.generalSettings.secondaryColor
                : "#edede9",
              gradientAngle: 135,
              gradientBackground:
                "linear-gradient(180deg, rgba(0,0,0,1), rgba(255,255,255,1)",

              showOverlayColorProperty: false,
              selectedCategoryBackgroundDropdownElement: {
                icon: "image",
                label: "Bild",
              },
              selectedOverlayTypeDropdownElement: {
                icon: "palette",
                label: "Farbe",
              },
              primaryColor: this.generalSettings.primaryColor
                ? this.generalSettings.primaryColor
                : "#000000",
            },
            textstyleProperties: "",
            imageStyleProperties: "",
            elementStyleProperties: "",
          }),
            this.assignProperties();
        }
      },
      deep: true,
    },
    localEditElement: {
      handler: function () {
        if (this.localEditElement) {
          this.$emit("update:currentEditElement", this.localEditElement);
        }
      },
      deep: true,
    },
    "localEditElement.content.image": function () {
      this.setOverlay();
    },
    "generalSettings.secondaryColor": function () {
      this.localEditElement.elementStyleValues.gradientColor2 =
        this.generalSettings.secondaryColor;
      this.setGradientBackground();
    },
    "generalSettings.primaryColor": function () {
      this.localEditElement.elementStyleValues.gradientColor1 =
        this.generalSettings.primaryColor;
      this.setGradientBackground();
    },
  },
  methods: {
    assignProperties() {
      var categoryUserSettings = this.$store.state.categoryElementSettings;
      this.localEditElement.elementId = this.currentEditElement.elementId;
      this.localEditElement.elementType = this.currentEditElement.elementType;
      this.localEditElement.initialized = true;
      this.localEditElement.content.text = this.currentEditElement.content?.text
        ? this.currentEditElement.content.text
        : "Text";
      this.localEditElement.content.image = this.currentEditElement.content
        ?.image
        ? this.currentEditElement.content.image
        : "";
      this.localEditElement.content.child = this.currentEditElement.content
        ?.child
        ? cloneDeep(this.currentEditElement.content.child)
        : [];
      if (JSON.stringify(categoryUserSettings) !== "{}") {
        this.localEditElement.textstyleProperties =
          categoryUserSettings.textStyle;
        this.localEditElement.textStyleValues.marginTop = this.currentEditElement.content?.image ? -144 : 50;
        this.buildTextStylePropertiesString(
          "margin-top",
          this.localEditElement.textStyleValues.marginTop + "px"
        );
        this.localEditElement.textStyleValues.marginBottom =
          categoryUserSettings.textStyleValues.marginBottom;
        this.localEditElement.textStyleValues.marginLeft =
          categoryUserSettings.textStyleValues.marginLeft;
        this.localEditElement.textStyleValues.marginRight =
          categoryUserSettings.textStyleValues.marginRight;

        this.localEditElement.elementStyleProperties =
          categoryUserSettings.elementStyle;
        this.localEditElement.elementStyleValues.marginTop =
          categoryUserSettings.elementStyleValues.marginTop;
        this.localEditElement.elementStyleValues.marginBottom =
          categoryUserSettings.elementStyleValues.marginBottom;
        this.localEditElement.elementStyleValues.marginLeft =
          categoryUserSettings.elementStyleValues.marginLeft;
        this.localEditElement.elementStyleValues.marginRight =
          categoryUserSettings.elementStyleValues.marginRight;
        this.localEditElement.elementStyleValues.height =
          categoryUserSettings.elementStyleValues.height;
        this.localEditElement.elementStyleValues.width =
          categoryUserSettings.elementStyleValues.width;
        this.localEditElement.elementStyleValues.opacity =
          categoryUserSettings.elementStyleValues.opacity;
        this.localEditElement.elementStyleValues.borderRadius =
          categoryUserSettings.elementStyleValues.borderRadius;
        this.localEditElement.elementStyleValues.background =
          this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9",
          this.buildElementStylePropertiesString(
            "background",
            this.localEditElement.elementStyleValues.background
          );


        this.localEditElement.elementStyleValues.gradientColor1 =
          categoryUserSettings.elementStyleValues.gradientColor1;
        this.localEditElement.elementStyleValues.gradientColor2 =
          categoryUserSettings.elementStyleValues.gradientColor2;
        this.localEditElement.elementStyleValues.gradientAngle =
          categoryUserSettings.elementStyleValues.gradientAngle;
        this.localEditElement.elementStyleValues.showOverlayColorProperty =
          false;
        this.localEditElement.elementStyleValues.selectedCategoryBackgroundDropdownElement =
          categoryUserSettings.elementStyleValues.selectedCategoryBackgroundDropdownElement;
        this.localEditElement.elementStyleValues.selectedOverlayTypeDropdownElement =
          categoryUserSettings.elementStyleValues.selectedOverlayTypeDropdownElement;

        this.localEditElement.imageStyleProperties =
          categoryUserSettings.imageStyle;

      } else {
        this.localEditElement.elementStyleProperties =
          "background: " +
          (this.generalSettings.secondaryColor
            ? this.generalSettings.secondaryColor
            : "#edede9") +
          "; margin-top: 20px; height: 180px; width: 340px; border-radius: 30px;";

        this.localEditElement.imageStyleProperties =
          "height: 180px; width: 340px; border-radius: 30px;";

        this.localEditElement.textstyleProperties =
          "color: " +
          (this.generalSettings.primaryColor
            ? this.generalSettings.primaryColor
            : "#000000") +
          "; font-family: " +
          (this.generalSettings.currentFont
            ? this.generalSettings.currentFont
            : "Roboto") +
          "; font-size: 30pt; position: relative; z-index: 100;";

        this.localEditElement.elementStyleValues.marginTop = 20;
        this.localEditElement.elementStyleValues.height = 180;
        this.localEditElement.elementStyleValues.width = 340;
        this.localEditElement.elementStyleValues.borderRadius = 30;

        this.buildElementStylePropertiesString(
          "margin-top",
          this.localEditElement.elementStyleValues.marginTop + "px"
        );
        this.buildElementStylePropertiesString(
          "height",
          this.localEditElement.elementStyleValues.height + "px"
        );
        this.buildElementStylePropertiesString(
          "width",
          this.localEditElement.elementStyleValues.width + "px"
        );
        this.buildElementStylePropertiesString(
          "border-radius",
          this.localEditElement.elementStyleValues.borderRadius + "px"
        );

        this.localEditElement.textStyleValues.marginTop = this
          .currentEditElement.content?.image
          ? -144
          : 50;
        this.buildTextStylePropertiesString(
          "margin-top",
          this.localEditElement.textStyleValues.marginTop + "px"
        );
      }
    },
    selectCategoryBackgroundDropdownOption(icon, label) {
      this.localEditElement.elementStyleValues.selectedCategoryBackgroundDropdownElement =
      {
        icon: icon,
        label: label,
      };
      if (icon == "palette") {
        this.buildElementStylePropertiesString(
          "background",
          this.generalSettings.secondaryColor
        );
      } else if (icon == "gradient") {
        this.setGradientBackground();
      }
    },
    selectOverlayTypeDropdownOption(icon, label) {
      this.localEditElement.elementStyleValues.selectedOverlayTypeDropdownElement =
      {
        icon: icon,
        label: label,
      };
      if (icon == "gradient") {
        this.localEditElement.elementStyleValues.opacity = 40;
        this.buildImageStylePropertiesString(
          "opacity",
          this.localEditElement.elementStyleValues.opacity + "%"
        );
        this.setGradientBackground();
      } else if (icon == "palette") {
        this.setOverlay();
      }
    },
    setOverlay() {
      if (this.localEditElement.content.image) {
        this.buildElementStylePropertiesString(
          "background",
          this.localEditElement.elementStyleValues.background
        );
        this.buildImageStylePropertiesString(
          "opacity",
          this.localEditElement.elementStyleValues.opacity + "%"
        );

        this.localEditElement.elementStyleValues.showOverlayColorProperty = true;
      } else {
        this.localEditElement.elementStyleValues.background =
          this.generalSettings.secondaryColor;
        this.buildElementStylePropertiesString(
          "background",
          this.localEditElement.elementStyleValues.background
        );
        this.localEditElement.elementStyleValues.opacity = 100;
        this.buildImageStylePropertiesString(
          "opacity",
          this.localEditElement.elementStyleValues.opacity + "%"
        );
        this.localEditElement.elementStyleValues.showOverlayColorProperty = false;
      }
    },
    buildTextStylePropertiesString(styleProperty, value) {
      this.localEditElement.textstyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.textstyleProperties
      );
    },
    buildElementStylePropertiesString(styleProperty, value) {
      this.localEditElement.elementStyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.elementStyleProperties
      );
    },
    buildImageStylePropertiesString(styleProperty, value) {
      this.localEditElement.imageStyleProperties = buildStylePropertiesString(
        styleProperty,
        value,
        this.localEditElement.imageStyleProperties
      );
    },
    setGradientBackground() {
      this.gradientBackground =
        "linear-gradient(" +
        this.localEditElement.elementStyleValues.gradientAngle +
        "deg," +
        this.hexToRGB(
          this.localEditElement.elementStyleValues.gradientColor1,
          100
        ) +
        ", " +
        this.hexToRGB(
          this.localEditElement.elementStyleValues.gradientColor1,
          100
        ) +
        ")";
      if (this.show) {
        this.buildElementStylePropertiesString(
          "background",
          this.gradientBackground
        );
      }
    },
    hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

      if (alpha && alpha == 100) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else if (alpha && alpha < 100 && alpha > 9) {
        return "rgba(" + r + ", " + g + ", " + b + ", 0." + alpha + ")";
      } else if (alpha && alpha < 10 && alpha > 0) {
        return "rgba(" + r + ", " + g + ", " + b + ", 0.0" + alpha + ")";
      } else {
        return "rgb(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      }
    },
    handleImageUpload(event) {
      this.isImageUploadLoading = true;
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        // Create a JSON object with the base64 encoded string
        const imageData = {
          image: base64String,
        };

        // Assuming AssetHandler.uploadImage() can handle an object with base64 string
        AssetHandler.uploadImage(imageData, this.menuId)
          .then((response) => {
            this.localEditElement.content.image = response.data;
            this.buildTextStylePropertiesString("margin-top", -144 + "px");
            this.isImageUploadLoading = false;
          })
          .catch((error) => {
            this.isImageUploadLoading = false;
            console.error("Error uploading image:", error);
          });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    },
    deleteImage() {
      if (!this.localEditElement.content.image) {
        return;
      }

      AssetHandler.deleteImage(this.localEditElement.content.image).then(() => {
        this.localEditElement.content.image = "";
        this.buildTextStylePropertiesString("margin-top", 50 + "px");
      });
    },
    setPixabayImage(file) {
      this.isImageUploadLoading = true;
      if (!file) {
        return;
      }
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        // Create a JSON object with the base64 encoded string
        const imageData = {
          image: base64String,
        };

        // Assuming AssetHandler.uploadImage() can handle an object with base64 string
        AssetHandler.uploadImage(imageData, this.menuId)
          .then((response) => {
            this.localEditElement.content.image = response.data;
            this.buildTextStylePropertiesString("margin-top", -144 + "px");
            this.isImageUploadLoading = false;
          })
          .catch((error) => {
            this.isImageUploadLoading = false;
            console.error("Error uploading image:", error);
          });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    },

    saveCategoryElement() {
      const categoryElementSettings = {
        textStyle: this.localEditElement.textstyleProperties,
        textStyleValues: {
          marginTop: this.localEditElement.textStyleValues.marginTop,
          marginBottom: this.localEditElement.textStyleValues.marginBottom,
          marginLeft: this.localEditElement.textStyleValues.marginLeft,
          marginRight: this.localEditElement.textStyleValues.marginRight,
        },
        elementStyle: this.localEditElement.elementStyleProperties,
        elementStyleValues: {
          marginTop: this.localEditElement.elementStyleValues.marginTop,
          marginBottom: this.localEditElement.elementStyleValues.marginBottom,
          marginLeft: this.localEditElement.elementStyleValues.marginLeft,
          marginRight: this.localEditElement.elementStyleValues.marginRight,
          height: this.localEditElement.elementStyleValues.height,
          width: this.localEditElement.elementStyleValues.width,
          opacity: this.localEditElement.elementStyleValues.opacity,
          borderRadius: this.localEditElement.elementStyleValues.borderRadius,
          background: this.localEditElement.elementStyleValues.background,
          gradientColor1:
            this.localEditElement.elementStyleValues.gradientColor1,
          gradientColor2:
            this.localEditElement.elementStyleValues.gradientColor2,
          gradientAngle: this.localEditElement.elementStyleValues.gradientAngle,
          showOverlayColorProperty:
            this.localEditElement.elementStyleValues.showOverlayColorProperty,
          selectedCategoryBackgroundDropdownElement:
            this.localEditElement.elementStyleValues
              .selectedCategoryBackgroundDropdownElement,
          selectedOverlayTypeDropdownElement:
            this.localEditElement.elementStyleValues
              .selectedOverlayTypeDropdownElement,
        },
        imageStyle: this.localEditElement.imageStyleProperties,
      };

      this.$store.commit("setCategoryElementSettings", categoryElementSettings);

      //this.textEditorValue = "Text";

      this.$emit("hideDialog");
    },
  },
};
</script>
<style lang="scss">
@import "scss/colors.scss";

.categoryElementBody {
  height: 100%;

  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // overflow-y: scroll;

  .currentColor {
    height: 17px;
    width: 23px;
    margin-left: 11px;
    margin-top: 3px;
    border-radius: 5px;
    border: solid 1px black;

    &:hover {
      cursor: pointer;
    }
  }

  .imageUploadPlaceholder {
    border: solid 1px $grey-light;
    height: 40px;
    width: 103px;
    border-radius: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;

    img {
      height: 40px;
      width: 103px;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .optionIconSearch {
    height: 25px;
    width: 30px;
    margin-top: 2px;
    margin-left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;

    transition: 0.3s ease;

    border: solid 1px $grey-light;
    border-radius: 5px;
    padding: 13px;

    &:hover {
      cursor: pointer;
      background-color: $grey-light;
    }
  }

  .optionIconUpload {
    height: 28px;
    width: 30px;

    margin-left: 25px;
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;

    transition: 0.3s ease;

    border: solid 1px $grey-light;
    border-radius: 5px;

    z-index: 100;

    .fileInput {
      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $grey-light;
    }
  }

  .optionIconDelete {
    font-size: large;
    margin-left: 145px;
    margin-top: -3px;
    position: fixed;

    &:hover {
      cursor: pointer;
    }
  }

  .saveCategoryElementBtn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
</style>
