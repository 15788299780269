<template>
    <div v-show="show" class="element-options q-ma-sm q-gutter-sm">
        <div class="base-options" v-if="!showDeleteConfirm">
            <q-btn flat round icon="delete" @click="showDeleteConfirm = true" />
            <q-btn flat round icon="edit" @click="editElement" />
            <q-btn flat round icon="content_copy" @click="copyElement" />
        </div>
        
        <div class="delete-confirm" v-if="showDeleteConfirm">
            <q-btn flat round icon="close" @click="showDeleteConfirm = false" />
            <q-btn flat round icon="check" @click="deleteElement" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        elementId: {
            type: String,
            required: true
        },
        elements: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            show: false,
            showDeleteConfirm: false,
        };
    },
    methods: {
        deleteElement() {
            this.$emit('delete-element', this.elementId);
            this.showDeleteConfirm = false;
        },
        editElement() {
            this.$emit('edit-element', this.elementId);
        },
        copyElement() {
            this.$emit('copy-element', this.elementId);
        }
    }
}
</script>

<style scoped lang="scss">
.element-options {
    display: flex !important;
    justify-content: center;
    padding-bottom: 7px;
    padding-left: 5px;
    padding-right: 13px;
    background: #eee;
    border-radius: 25px;
    width: 50%;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%);
    z-index: 100000;
}
</style>