<template>
    <div class="versionHistoryBodyWrapper" id="versionHistoryBodyWrapper">
        <div class="closeIconWrapper">
            <QIcon class="closeIcon" name="close"
                @click="hideVersionHistoryDialog()" />
        </div>
        <div class="versionHistory">
            <div class="versionHistoryContainer">
                <div class="versionHistorySection">
                    <h6>Editor</h6>
                    <ul v-if="!isEditVersionsLoading">
                        <li v-for="(version, index) in editMenuVersions"
                            :key="version.id">
                            <span>{{ formatDate(version.timestamp) }}</span>
                            <button v-if="index !== 0"
                                @click="restoreVersion(version, 'editMenu')">Wiederherstellen</button>
                            <button v-else disabled>Aktuell</button>
                        </li>
                    </ul>
                    <div class="spinner" v-else>
                        <q-spinner color="light-green"></q-spinner>
                    </div>
                </div>
                <div class="versionHistorySection">
                    <h6>Veröffentlicht</h6>
                    <ul v-if="!isPublicVersionsLoading">
                        <li v-for="(version, index) in publicMenuVersions"
                            :key="version.id">
                            <span>{{ formatDate(version.timestamp) }}</span>
                            <button v-if="index !== 0"
                                @click="restoreVersion(version, 'publicMenu')">Wiederherstellen</button>
                            <button v-else disabled>Aktuell</button>
                        </li>
                    </ul>
                    <div class="spinner" v-else>
                        <q-spinner color="light-green"></q-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ToastTypes from "@/models/Types/ToastTypes";
import { QIcon } from "quasar";
import MenuHandler from "@/util/MenuHandler";

export default {
    components: {
        QIcon,
    },
    props: {
        uid: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            editMenuVersions: [],
            publicMenuVersions: [],

            isEditVersionsLoading: false,
            isPublicVersionsLoading: false,
        };
    },
    async mounted() {
        await this.fetchVersions('editMenu');
        await this.fetchVersions('publicMenu');
    },
    methods: {
        async fetchVersions(type) {
            if (type === 'editMenu') { this.isEditVersionsLoading = true; }
            if (type === 'publicMenu') { this.isPublicVersionsLoading = true; }
            try {
                const response = await MenuHandler.fetchVersions(this.uid, type);
                // Sort versions by timestamp in descending order
                this[`${type}Versions`] = response.data.sort((a, b) => b.timestamp._seconds - a.timestamp._seconds);
            } catch (error) {
                console.error(`Fehler beim Laden der ${type} Versionen: `, error);
            } finally {
                if (type === 'editMenu') { this.isEditVersionsLoading = false; }
                if (type === 'publicMenu') {
                    this.isPublicVersionsLoading = false;
                }
            }
        },
        formatDate(timestamp) {
            const date = new Date(timestamp._seconds * 1000);
            return date.toLocaleString();
        },
        async restoreVersion(version, type) {
            try {
                await MenuHandler.restoreVersion(this.uid, type, version.id);
                if (type === 'publicMenu') {
                    this.showToastMessage(`Wiederhergestellt ${type} zu Version von ${this.formatDate(version.timestamp)}`, 'success');
                } else if (type === 'editMenu') {
                    this.$emit('restoreEditMenuVersion', version.data);
                    this.showToastMessage(`Wiederhergestellt ${type} zu Version von ${this.formatDate(version.timestamp)}`, 'success');
                }
            } catch (error) {
                console.error(`Fehler beim Wiederherstellen der ${type} Version: `, error);
                this.showToastMessage(`Fehler beim Wiederherstellen der ${type} Version`, 'error');
            }
        },
        hideVersionHistoryDialog() {
            this.$emit('close');
        },
        showToastMessage(toastMessage, type, inputId) {
            let payload = {
                showToast: true,
                toastType: type,
                message: toastMessage,
            };
            this.$store.commit("configureToast", payload);
            if (type === ToastTypes.ERROR && inputId) {
                document.getElementById(inputId).classList.add("invalid");
                setTimeout(() => {
                    document.getElementById(inputId).classList.remove("invalid");
                }, 3000);
            }
            this.showToast = true;
            setTimeout(() => {
                this.showToast = false;
            }, 5000);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "scss/colors.scss";

.versionHistoryBodyWrapper {
    width: 560px;
    height: 844px;
    max-height: 90vh;
    border: solid 1px $grey-medium;
    border-radius: 20px;
    overflow-y: scroll;
    padding: 20px;
    padding-right: 0px;

    @media (max-width: $mobile) {
        transform: scale(0.7);
        margin-top: -5rem;
    }

    box-shadow: 0 1px 5px rgb(0 0 0 / 20%),
    0 2px 2px rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%);

    background-color: white;

    display: flex;
    justify-content: flex-start;

    label {
        margin: 10px;
        font-weight: bold;
    }

    input {
        height: 22px;
        width: 35px;
    }
}

.closeIconWrapper {
    position: absolute;
    top: 20px;
    right: 20px;
}

.versionHistory {
    width: 100%;
}

.versionHistory h2 {
    margin-bottom: 20px;
}

.versionHistoryContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.versionHistorySection {
    flex: 1;
    //border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.versionHistorySection h3 {
    margin-bottom: 20px;
}

.versionHistorySection ul {
    list-style-type: none;
    padding: 0;
}

.versionHistorySection li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    //border-bottom: 1px solid #e0e0e0;
}

.versionHistorySection li:last-child {
    border-bottom: none;
}

.versionHistorySection button {
    background-color: $green-poison;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}



.versionHistorySection button:disabled {
    background-color: transparent;
    color: $green-poison;
    cursor: not-allowed;
    font-weight: bold
}

h6 {
    margin: 1rem;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}
</style>
