<template>
  <div class="loginScreen">
    <div class="logoWrapper">
      <img src="../../assets/ordersnake_logo.svg" />
    </div>
    <div class="loginWrapper">
      <div class="inputs">
        <MyInput placeholder="Email" class="inp login" v-model="email" />
        <MyInput placeholder="Passwort" type="password" class="inp login"
          v-model="password" />
      </div>
      <div class="signInButtonWrapper">
        <MyButton buttonStyle="btn btn-medium" @click="signInEmail"
          :isDisabled="!isFormValid">
          <span v-if="!isLoginLoading">Anmelden</span> <q-spinner color="white"
            v-else></q-spinner>
        </MyButton>
      </div>
      <div class="noAccount">
        Kein Konto?
        <router-link to="/register"
          class="registerLink">Registrieren</router-link>
      </div>
      <div class="or">oder</div>
      <div class="google-sso" @click="signInGoogle">
        <img src="../../assets/google.png" />
        mit Google fortfahren
      </div>
    </div>
  </div>
</template>

<script>
import MyButton from "../shared/MyButton.vue";
import MyInput from "../shared/MyInput.vue";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import UserHandler from "@/util/UserHandler";

export default {
  components: {
    MyInput,
    MyButton,
  },
  data() {
    return {
      email: "",
      password: "",

      isLoginLoading: false,
    };
  },

  computed: {
    isFormValid() {
      return this.isEmailValid && this.isPasswordValid && !this.isLoginLoading;
    },
    isEmailValid() {
      return this.email.includes("@");
    },
    isPasswordValid() {
      return this.password.length >= 6;
    },
  },

  methods: {
    async signInGoogle() {
      this.isLoginLoading = true;
      const provider = new GoogleAuthProvider();
      const auth = getAuth();

      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        // Check or create user document
        await UserHandler.checkOrCreate(user.uid, user.email)


        this.$router.push("/");
      } catch (error) {
        console.error(error);
      }
      finally {
        this.isLoginLoading = false;
      }
    },

    async signInWithEmail() {
      this.isLoginLoading = true;

      const auth = getAuth();

      try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        // Check or create user document
        await UserHandler.checkOrCreate(user.uid, user.email)

        this.$router.push("/");
      } catch (error) {
        console.error(error);
      }
      finally {
        this.isLoginLoading = false;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import "scss/colors.scss";

.loginScreen {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loginWrapper {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    .signInButtonWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .registerLink {
      color: $green-poison;
    }

    .or {
      opacity: 0.4;
    }

    .google-sso {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      img {
        width: 2rem;
      }

      padding: 0.5rem;
      border: 1px solid $grey-medium;
      border-radius: 10px;
      transition: 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
</style>
