export default function buildStylePropertiesString(styleProperty, value, string) {
  if (string.includes(styleProperty)) {
    let start = string.lastIndexOf(styleProperty) + styleProperty.length + 1;

    let end;
    for (let i = start; i < string.length; i++) {
      if (string[i] == ";") {
        end = i;
        break;
      }
    }

    let firstHalf = string.substring(0, start);
    let secondHalf = string.substring(end, string.length);

    string = firstHalf + value + secondHalf;
  } else {
    string += " " + styleProperty + ": " + value + ";";
  }

  return string;
}
